/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useCallback, useEffect, useState } from 'react'
import {
    Checkbox,
    Container,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    styled,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead as MuiTableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Check as CheckIcon,
} from '@mui/icons-material'

// components
import AdminTableToolbar from '../shared/AdminTableToolbar'
import QAndAAdminTableRow from './QAndAAdminTableRow'
import AdminHeading from '../shared/AdminHeading'
import NoSearchResults from '../shared/NoSearchResults'

// interfaces
import { IInstructorQuestion, IInstructorAnswer } from '../../../models/InstructorQuestion'
import { ApiRes } from '../../../models/Api'
import { IRowCountMenuProps, ISearchFilterMenuProps, ISelectColumnsMenuProps } from '../shared/shared'

// services
import StudentCoursesService from '../../../services/StudentCoursesService'

// mui
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main
}))
const Table = styled(MuiTable)(({theme}) => ({
    backgroundColor: 'white',
}))
const TableHead = styled(MuiTableHead)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white'
}))
const TableHeadTyp = styled(Typography)(({theme}) => ({
    color: 'white'
}))

const QAndAAdminTable = () => {

    // ===== selectSearchFilterMenu =====
    let selectSearchFilterMenuLabels = Array<string>()
    let selectSearchFilterMenuStates = Array<boolean>()
    let selectSearchFilterMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isSearchId, setIsSearchId] = useState(false)
    selectSearchFilterMenuLabels.push('Question ID')
    selectSearchFilterMenuStates.push(isSearchId)
    selectSearchFilterMenuSetStates.push(setIsSearchId)
    const [isSearchUserId, setIsSearchUserId] = useState(false)
    selectSearchFilterMenuLabels.push('Student ID')
    selectSearchFilterMenuStates.push(isSearchUserId)
    selectSearchFilterMenuSetStates.push(setIsSearchUserId)
    const [isSearchStudentCourseId, setIsSearchStudentCourseId] = useState(false)
    selectSearchFilterMenuLabels.push('Student Course ID')
    selectSearchFilterMenuStates.push(isSearchStudentCourseId)
    selectSearchFilterMenuSetStates.push(setIsSearchStudentCourseId)
    const [isSearchStudentCourseSectionId, setIsSearchStudentCourseSectionId] = useState(false)
    selectSearchFilterMenuLabels.push('Student Section ID')
    selectSearchFilterMenuStates.push(isSearchStudentCourseSectionId)
    selectSearchFilterMenuSetStates.push(setIsSearchStudentCourseSectionId)
    const [isSearchQuestion, setIsSearchQuestion] = useState(true)
    selectSearchFilterMenuLabels.push('Question')
    selectSearchFilterMenuStates.push(isSearchQuestion)
    selectSearchFilterMenuSetStates.push(setIsSearchQuestion)
    const [isSearchAnswer, setIsSearchAnswer] = useState(true)
    selectSearchFilterMenuLabels.push('Answer')
    selectSearchFilterMenuStates.push(isSearchAnswer)
    selectSearchFilterMenuSetStates.push(setIsSearchAnswer)
    const [isSearchCourseTitle, setIsSearchCourseTitle] = useState(true)
    selectSearchFilterMenuLabels.push('Course Title')
    selectSearchFilterMenuStates.push(isSearchCourseTitle)
    selectSearchFilterMenuSetStates.push(setIsSearchCourseTitle)
    // const [isSearchCourseSectionNum, setIsSearchCourseSectionNum] = useState(false)
    // selectSearchFilterMenuLabels.push('Section #')
    // selectSearchFilterMenuStates.push(isSearchCourseSectionNum)
    // selectSearchFilterMenuSetStates.push(setIsSearchCourseSectionNum)
    const [isSearchUserFirstName, setIsSearchUserFirstName] = useState(false)
    selectSearchFilterMenuLabels.push('Student First Name')
    selectSearchFilterMenuStates.push(isSearchUserFirstName)
    selectSearchFilterMenuSetStates.push(setIsSearchUserFirstName)
    const [isSearchUserLastName, setIsSearchUserLastName] = useState(true)
    selectSearchFilterMenuLabels.push('Student Last Name')
    selectSearchFilterMenuStates.push(isSearchUserLastName)
    selectSearchFilterMenuSetStates.push(setIsSearchUserLastName)
    const [isSearchUserEmailAddress, setIsSearchUserEmailAddress] = useState(false)
    selectSearchFilterMenuLabels.push('Student Email Address')
    selectSearchFilterMenuStates.push(isSearchUserEmailAddress)
    selectSearchFilterMenuSetStates.push(setIsSearchUserEmailAddress)
    // populate props
    const searchFilterMenuProps: ISearchFilterMenuProps = {
        labels: selectSearchFilterMenuLabels,
        states: selectSearchFilterMenuStates,
        setStates: selectSearchFilterMenuSetStates,
    }

    // questions
    const [questions, setQuestions] = useState<Array<IInstructorQuestion>>()
    const refreshQuestions = useCallback(async (setIsLoadingQuestionsFalseOnRefresh: boolean = false) => {
        setIsLoadingQuestions(true)
        const res: any = await StudentCoursesService.adminGetAllQuestions()
        if (res.data !== undefined) {
            var newQuestionIndexesFilteredDefault: Array<number> = []
            for (let i=0; i<Object.keys(res.data).length; ++i) {
                newQuestionIndexesFilteredDefault.push(i)
            }
            setQuestionIndexesFilteredDefault(newQuestionIndexesFilteredDefault)
            setQuestionIndexesFiltered(newQuestionIndexesFilteredDefault)
            filterQuestions()
            setQuestions(res.data)
            setTotalNum(res.data.length)
            if (setIsLoadingQuestionsFalseOnRefresh) {
                setIsLoadingQuestions(false)
            }
        }
    }, [])
    useEffect(() => {
        refreshQuestions()
    }, [refreshQuestions])

    // q&a search bar - to implement still

    const [isLoadingQuestions, setIsLoadingQuestions] = useState(true)
    const [questionIndexesFilteredDefault, setQuestionIndexesFilteredDefault] = useState([0, 1, 2])

    // ===== rowCountMenuProps =====
    const [rowCount, setRowCount] = useState(25)
    const rowCountMenuProps: IRowCountMenuProps = {
        rowCount: rowCount,
        setRowCount: setRowCount
    }

    // === search filter ===
    const [questionIndexesFiltered, setQuestionIndexesFiltered] = useState(questionIndexesFilteredDefault)
    const [currentNumFiltered, setCurrentNumFiltered] = useState(0)
    const [totalNum, setTotalNum] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const filterQuestions = useCallback(() => {
        if (questions === undefined) {
            return
        }
        // set isLoadingQuestions
        setIsLoadingQuestions(true)
        // if nothing to filter, return slice
        if (searchValue.length === 0) {
            setQuestionIndexesFiltered(questionIndexesFilteredDefault.slice(0, rowCount))
            setCurrentNumFiltered(totalNum > rowCount ? rowCount : totalNum)
            setIsLoadingQuestions(false)
            return
        }
        // lowercase search value
        const currSearchValueLower = searchValue.toLowerCase()
        // filter
        var newQuestionIndexesFiltered: Array<number> = []
        let currRowCount = 0
        for (let i=0; i<questions?.length; ++i) {
            const question = questions[i]
            if ((isSearchQuestion && question.question && question.question.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchUserId && question.userId && question.userId.toString().includes(currSearchValueLower)) ||
                (isSearchStudentCourseId && question.studentCourseId && question.studentCourseId.toString().includes(currSearchValueLower)) ||
                (isSearchStudentCourseSectionId && question.studentCourseSectionId && question.studentCourseSectionId.toString().includes(currSearchValueLower)) ||
                (isSearchId && question.instructorQuestionId && question.instructorQuestionId.toString().includes(currSearchValueLower)) ||
                (isSearchAnswer && question.instructorAnswer && question.instructorAnswer.answer.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchCourseTitle && question.courseTitle && question.courseTitle.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchUserFirstName && question.userProfile && question.userProfile.firstName.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchUserLastName && question.userProfile && question.userProfile.lastName.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchUserEmailAddress && question.userProfile && question.userProfile.emailAddress.toLowerCase().includes(currSearchValueLower))) {
                newQuestionIndexesFiltered.push(i)
                currRowCount++
            }
            // if max row count met, break
            if (currRowCount >= rowCount) {
                break
            }
        }
        // set courseIndexesFiltered
        setQuestionIndexesFiltered(newQuestionIndexesFiltered)
        setCurrentNumFiltered(currRowCount)
        // set isLoadingQuestions
        setIsLoadingQuestions(false)
    }, [totalNum, searchValue, questions, isSearchQuestion, isSearchUserId, isSearchStudentCourseId, isSearchStudentCourseSectionId, isSearchId, isSearchAnswer, isSearchCourseTitle,
        isSearchUserFirstName, isSearchUserLastName, isSearchUserEmailAddress, rowCount, questionIndexesFilteredDefault])
    // filter questions whenever searchValue changes
    useEffect(() => {
        // filter
        filterQuestions()
    }, [searchValue, filterQuestions])

    // ===== selectColumnsMenu =====
    let selectColumnsMenuLabels = Array<string>()
    let selectColumnsMenuStates = Array<boolean>()
    let selectColumnsMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isTableId, setIsTableId] = useState(false)
    selectColumnsMenuLabels.push('Question ID')
    selectColumnsMenuStates.push(isTableId)
    selectColumnsMenuSetStates.push(setIsTableId)
    const [isTableUserId, setIsTableUserId] = useState(false)
    selectColumnsMenuLabels.push('Student ID')
    selectColumnsMenuStates.push(isTableUserId)
    selectColumnsMenuSetStates.push(setIsTableUserId)
    const [isTableStudentCourseId, setIsTableStudentCourseId] = useState(false)
    selectColumnsMenuLabels.push('Student Course ID')
    selectColumnsMenuStates.push(isTableStudentCourseId)
    selectColumnsMenuSetStates.push(setIsTableStudentCourseId)
    const [isTableStudentCourseSectionId, setIsTableStudentCourseSectionId] = useState(false)
    selectColumnsMenuLabels.push('Student Section ID')
    selectColumnsMenuStates.push(isTableStudentCourseSectionId)
    selectColumnsMenuSetStates.push(setIsTableStudentCourseSectionId)
    const [isTableQuestion, setIsTableQuestion] = useState(true)
    selectColumnsMenuLabels.push('Question')
    selectColumnsMenuStates.push(isTableQuestion)
    selectColumnsMenuSetStates.push(setIsTableQuestion)
    const [isTableAnswer, setIsTableAnswer] = useState(true)
    selectColumnsMenuLabels.push('Answer')
    selectColumnsMenuStates.push(isTableAnswer)
    selectColumnsMenuSetStates.push(setIsTableAnswer)
    const [isTableCourseTitle, setIsTableCourseTitle] = useState(true)
    selectColumnsMenuLabels.push('Course Title')
    selectColumnsMenuStates.push(isTableCourseTitle)
    selectColumnsMenuSetStates.push(setIsTableCourseTitle)
    const [isTableCourseSectionNum, setIsTableCourseSectionNum] = useState(false)
    // selectColumnsMenuLabels.push('Section #')
    // selectColumnsMenuStates.push(isTableCourseSectionNum)
    // selectColumnsMenuSetStates.push(setIsTableCourseSectionNum)
    const [isTableUserFirstName, setIsTableUserFirstName] = useState(false)
    selectColumnsMenuLabels.push('Student First Name')
    selectColumnsMenuStates.push(isTableUserFirstName)
    selectColumnsMenuSetStates.push(setIsTableUserFirstName)
    const [isTableUserLastName, setIsTableUserLastName] = useState(true)
    selectColumnsMenuLabels.push('Student Last Name')
    selectColumnsMenuStates.push(isTableUserLastName)
    selectColumnsMenuSetStates.push(setIsTableUserLastName)
    const [isTableUserEmailAddress, setIsTableUserEmailAddress] = useState(false)
    selectColumnsMenuLabels.push('Student Email Address')
    selectColumnsMenuStates.push(isTableUserEmailAddress)
    selectColumnsMenuSetStates.push(setIsTableUserEmailAddress)
    // populate props
    const selectColumnsMenuProps: ISelectColumnsMenuProps = {
        labels: selectColumnsMenuLabels,
        states: selectColumnsMenuStates,
        setStates: selectColumnsMenuSetStates,
    }

    return (
        <div style={{width: '100%'}}>
            <Container maxWidth='lg'>

                {/* Admin Heading */}
                <AdminHeading
                    title='Q&A'
                />

                {/* Toolbar */}
                <AdminTableToolbar
                    refreshTable={refreshQuestions} entityName='Question'
                    setSearchValue={setSearchValue} setIsLoadingTable={setIsLoadingQuestions}
                    currentNumFiltered={currentNumFiltered} totalNum={totalNum}
                    selectColumnsMenuProps={selectColumnsMenuProps}
                    searchFilterMenuProps={searchFilterMenuProps}
                    rowCountMenuProps={rowCountMenuProps}
                />

                {/* Table */}
                <TableContainer style={{marginTop: '20px', boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', borderRadius: 5}}>
                    <Table size='small' style={{width: '100%', boxSizing: 'border-box'}}>

                        {/* Table Head */}
                        <TableHead style={{width: '100%'}}>
                            <TableRow>
                                {isTableId &&
                                <TableCell padding='none' align='center' style={{padding: '5px'}}>
                                    <TableHeadTyp>ID</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableCourseTitle &&
                                <TableCell>
                                    <TableHeadTyp>Course Title</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableCourseSectionNum &&
                                <TableCell>
                                    <TableHeadTyp>Section #</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableStudentCourseId &&
                                <TableCell>
                                    <TableHeadTyp>Student Course ID</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableStudentCourseSectionId &&
                                <TableCell>
                                    <TableHeadTyp>Student Section ID</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableUserId &&
                                <TableCell>
                                    <TableHeadTyp>Student User ID</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableUserLastName &&
                                <TableCell>
                                    <TableHeadTyp>Student Last</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableUserFirstName &&
                                <TableCell>
                                    <TableHeadTyp>Student First</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableUserEmailAddress &&
                                <TableCell>
                                    <TableHeadTyp>Student Email</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableQuestion &&
                                <TableCell>
                                    <TableHeadTyp>Question</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableAnswer &&
                                <TableCell>
                                    <TableHeadTyp>Answer</TableHeadTyp>
                                </TableCell>
                                }
                                <TableCell>
                                    <TableHeadTyp></TableHeadTyp>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        {/* {!isLoadingQuestions &&
                        <TableBody>
                            {questions && questionIndexesFiltered.map((index) =>
                                <QAndAAdminTableRow question={questions[index]} key={index} refreshQuestions={refreshQuestions} />
                            )}
                        </TableBody>
                        } */}

                        {/* Table Body */}
                        {!isLoadingQuestions &&
                        <TableBody style={{width: '100%'}}>
                            {questions && questionIndexesFiltered.map((questionIndex) =>
                            <QAndAAdminTableRow question={questions[questionIndex]} refreshQuestions={refreshQuestions} key={questionIndex}
                                isTableId={isTableId} isTableCourseTitle={isTableCourseTitle} isTableCourseSectionNum={isTableCourseSectionNum}
                                isTableStudentCourseId={isTableStudentCourseId} isTableStudentCourseSectionId={isTableStudentCourseSectionId}
                                isTableUserId={isTableUserId} isTableUserLastName={isTableUserLastName} isTableUserFirstName={isTableUserFirstName}
                                isTableUserEmailAddress={isTableUserEmailAddress} isTableQuestion={isTableQuestion} isTableAnswer={isTableAnswer}
                            />
                            )}
                        </TableBody>
                        }

                    </Table>
                    {isLoadingQuestions &&
                    <LinearProgress />
                    }
                </TableContainer>

                {currentNumFiltered === 0 && searchValue !== '' &&
                <NoSearchResults entityNamePlural='Questions' searchQuery={searchValue} />
                }

            </Container>
        </div>
    )
}

export default QAndAAdminTable