/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {
    Card,
    IconButton,
    styled,
    TextField,
    Tooltip,
} from '@mui/material'
import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material'

// interfaces
import { ICourseSectionModule, ICourseSectionModuleComponent } from '../../../../models/Course'
import {
    CourseSectionModulesEditsActionType, ICourseSectionModulesEditsAction
} from './shared'

// mui
const ModuleCard = styled(Card)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: '20px',
    marginTop: '10px',
    marginBottom: '5px',
    flexGrow: 1,
    display: 'flex',
}))

interface IEditCourseSectionModuleProps {
    setCourseSectionModulesEdits: (action: ICourseSectionModulesEditsAction) => void
    swapModuleWithBelow: (e: React.MouseEvent, courseSectionModuleId: number) => void
    swapModuleWithAbove: (e: React.MouseEvent, courseSectionModuleId: number) => void
    module: ICourseSectionModule
    isFirst: boolean
    isLast: boolean
    handleCourseSectionModuleDelete: (courseSectionModuleId: number) => void
}
const EditCourseSectionModule = (props: IEditCourseSectionModuleProps) => {

    // module edits
    const [moduleTitleEdits, setModuleTitleEdits] = useState(props.module.courseSectionModuleTitle ?? '')
    const [areModuleTitleEditsPending, setAreModuleTitleEditsPending] = useState(false)
    const [moduleDescriptionEdits, setModuleDescriptionEdits] = useState(props.module.courseSectionModuleDescription ?? '')
    const [areModuleDescriptionEditsPending, setAreModuleDescriptionEditsPending] = useState(false)
    const [moduleEmbedUrlEdits, setModuleEmbedUrlEdits] = useState(
        props.module.courseSectionModuleComponents?.[0].courseSectionModuleComponentEmbed?.embedUrl ?? '')
    const [areModuleEmbedUrlEditsPending, setAreModuleEmbedUrlEditsPending] = useState(false)
    const handleModuleTitleEdits = (newValue: string) => {
        if (!areModuleTitleEditsPending) {
            setAreModuleTitleEditsPending(true)
        }
        setModuleTitleEdits(newValue)
    }
    const handleModuleDescriptionEdits = (newValue: string) => {
        if (!areModuleDescriptionEditsPending) {
            setAreModuleDescriptionEditsPending(true)
        }
        setModuleDescriptionEdits(newValue)
    }
    const handleModuleEmbedUrlEdits = (newValue: string) => {
        if (!areModuleEmbedUrlEditsPending) {
            setAreModuleEmbedUrlEditsPending(true)
        }
        setModuleEmbedUrlEdits(newValue)
    }

    // ===== send edits to parent =====
    // sends edits to parent - automatically called after user stops typing for 500 ms
    // === moduleTitleEdits ===
    useEffect(() => {
        // if no edits pending, return
        if (!areModuleTitleEditsPending) {
            return
        }
        const sendEditsToParent = setTimeout(() => {
            props.setCourseSectionModulesEdits({
                type: CourseSectionModulesEditsActionType.changeCourseSectionModuleTitle,
                courseSectionModuleId: props.module.courseSectionModuleId,
                newCourseSectionModuleTitle: moduleTitleEdits
          })
        }, 500)
        return () => clearTimeout(sendEditsToParent)
      }, [moduleTitleEdits, areModuleTitleEditsPending, props])
      // === moduleDescriptionEdits ===
    useEffect(() => {
        // if no edits pending, return
        if (!areModuleDescriptionEditsPending) {
            return
        }
        const sendEditsToParent = setTimeout(() => {
            props.setCourseSectionModulesEdits({
                type: CourseSectionModulesEditsActionType.changeCourseSectionModuleDescription,
                courseSectionModuleId: props.module.courseSectionModuleId,
                newCourseSectionModuleDescription: moduleDescriptionEdits
            })
        }, 500)
        return () => clearTimeout(sendEditsToParent)
    }, [moduleDescriptionEdits, areModuleDescriptionEditsPending, props])
      // === moduleEmbedUrlEdits ===
    useEffect(() => {
        // if no edits pending, return
        if (!areModuleEmbedUrlEditsPending) {
            return
        }
        const sendEditsToParent = setTimeout(() => {
            props.setCourseSectionModulesEdits({
                type: CourseSectionModulesEditsActionType.changeCourseSectionModuleEmbedUrl,
                courseSectionModuleId: props.module.courseSectionModuleId,
                newCourseSectionModuleEmbedUrl: moduleEmbedUrlEdits
            })
        }, 500)
        return () => clearTimeout(sendEditsToParent)
    }, [moduleEmbedUrlEdits, areModuleEmbedUrlEditsPending, props])

    // ===== isHovering =====
    const [isHovering, setIsHovering] = useState(false)

    return (
        <div style={{display: 'flex'}}>
            <ModuleCard elevation={isHovering ? 4 : 1} onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
                <div style={{flexGrow: 1, marginRight: '20px'}}>
                    <TextField variant='standard' label='Module Title' style={{marginBottom: '20px'}} fullWidth
                        value={moduleTitleEdits} onChange={(e) => handleModuleTitleEdits(e.target.value)}
                    />
                    <TextField variant='standard' multiline label='Module Description' style={{marginBottom: '20px'}} fullWidth
                        value={moduleDescriptionEdits} onChange={(e) => handleModuleDescriptionEdits(e.target.value)}
                    />
                    <TextField variant='standard' label='Embed URL' style={{marginBottom: '20px'}} fullWidth
                        value={moduleEmbedUrlEdits} onChange={(e) => handleModuleEmbedUrlEdits(e.target.value)}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', boxSizing: 'border-box', width: '30%'}}>
                    <ReactPlayer width='100%' height='200px' controls
                        url={'//fast.wistia.net/embed/iframe/' + moduleEmbedUrlEdits} config={{wistia: {options: {doNotTrack: true}}}}
                    />
                </div>
                <div style={{width: '40px'}}>
                    {isHovering &&
                    <div style={{width: '40px', marginLeft: '10px', display: 'flex', flexDirection: 'column'}}>
                        {!props.isFirst &&
                        <Tooltip title={'Move Up'} placement='right'>
                            <IconButton onClick={(e) => props.swapModuleWithAbove(e, props.module.courseSectionModuleId)}>
                                <ArrowUpwardIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        }
                        {!props.isLast &&
                        <Tooltip title={'Move Down'} placement='right'>
                            <IconButton onClick={(e) => props.swapModuleWithBelow(e, props.module.courseSectionModuleId)}>
                                <ArrowDownwardIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        }
                        <Tooltip title='Delete Module' placement='right'>
                            <IconButton onClick={() => props.handleCourseSectionModuleDelete(props.module.courseSectionModuleId)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                </div>
            </ModuleCard>
        </div>
    )
}

export default EditCourseSectionModule