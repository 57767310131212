/* eslint-disable @typescript-eslint/no-unused-vars */
import { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Checkbox,
    Container,
    Divider,
    Fab,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    styled,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead as MuiTableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Check as CheckIcon,
} from '@mui/icons-material'

// components
import AdminTableToolbar from '../../shared/AdminTableToolbar'
import CoursesAdminTableRow from './CoursesAdminTableRow'
import NewCourseDialog from './NewCourseDialog'
import AdminHeading from '../../shared/AdminHeading'
import NoSearchResults from '../../shared/NoSearchResults'

// interfaces
import { ICourse } from '../../../../models/Course'
import { ApiRes } from '../../../../models/Api'
import { IRowCountMenuProps, ISearchFilterMenuProps, ISelectColumnsMenuProps } from '../../shared/shared'

// services
import CoursesService from '../../../../services/CoursesService'

const Table = styled(MuiTable)(({theme}) => ({
    backgroundColor: 'white',
}))
const TableHead = styled(MuiTableHead)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white'
}))
const TableHeadTyp = styled(Typography)(({theme}) => ({
    color: 'white'
}))

const CoursesAdminTable = () => {

    // navigate
    const navigate = useNavigate()

    // ===== searchFilterMenu =====
    let selectSearchFilterMenuLabels = Array<string>()
    let selectSearchFilterMenuStates = Array<boolean>()
    let selectSearchFilterMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isSearchId, setIsSearchId] = useState(false)
    selectSearchFilterMenuLabels.push('ID')
    selectSearchFilterMenuStates.push(isSearchId)
    selectSearchFilterMenuSetStates.push(setIsSearchId)
    const [isSearchTitle, setIsSearchTitle] = useState(true)
    selectSearchFilterMenuLabels.push('Title')
    selectSearchFilterMenuStates.push(isSearchTitle)
    selectSearchFilterMenuSetStates.push(setIsSearchTitle)
    const [isSearchDescription, setIsSearchDescription] = useState(false)
    selectSearchFilterMenuLabels.push('Description')
    selectSearchFilterMenuStates.push(isSearchDescription)
    selectSearchFilterMenuSetStates.push(setIsSearchDescription)
    // populate props
    const searchFilterMenuProps: ISearchFilterMenuProps = {
        labels: selectSearchFilterMenuLabels,
        states: selectSearchFilterMenuStates,
        setStates: selectSearchFilterMenuSetStates,
    }

    // courses
    const [courses, setCourses] = useState(Array<ICourse>)
    const [isLoadingCourses, setIsLoadingCourses] = useState(true)
    const [courseIndexesFilteredDefault, setCourseIndexesFilteredDefault] = useState<Array<number>>()
    const refreshCourses = useCallback(async () => {
        const res: ApiRes<Array<ICourse>> = await CoursesService.adminGetAllCourses()
        if (!res.error && res.data !== undefined) {
            // populate courseIndexesFilteredDefault
            var newCourseIndexesFilteredDefault: Array<number> = []
            for (let i=0; i<Object.keys(res.data).length; ++i) {
                newCourseIndexesFilteredDefault.push(i)
            }
            setCourseIndexesFilteredDefault(newCourseIndexesFilteredDefault)
            setCourseIndexesFiltered(newCourseIndexesFilteredDefault)
            setCourses(res.data)
            setTotalNum(res.data.length)
            setIsLoadingCourses(false)
        }
    }, [])
    useEffect(() => {
        refreshCourses()
    }, [refreshCourses])

    // search
    const [searchValue, setSearchValue] = useState('')
    const handleChangeSearchValue = (newValue: string) => {

        // if newValue == '', set searchValue to '' and courseIndexesFiltered to default
        if (newValue === '') {
            setSearchValue('')
            setCourseIndexesFiltered(courseIndexesFilteredDefault)
        }

        // else, set searchValue to newValue and filter courseIndexes
        else {
            setSearchValue(newValue)
            filterCourses()
        }
    }

    // ===== rowCountMenuProps =====
    const [rowCount, setRowCount] = useState(25)
    const rowCountMenuProps: IRowCountMenuProps = {
        rowCount: rowCount,
        setRowCount: setRowCount
    }

    // search filter
    const [courseIndexesFiltered, setCourseIndexesFiltered] = useState(courseIndexesFilteredDefault)
    const [currentNumFiltered, setCurrentNumFiltered] = useState(0)
    const [totalNum, setTotalNum] = useState(0)
    const filterCourses = useCallback(() => {
        // if nothing to filter, return all
        if (searchValue.length === 0) {
            setCourseIndexesFiltered(courseIndexesFilteredDefault?.slice(0, rowCount))
            setCurrentNumFiltered(totalNum > rowCount ? rowCount : totalNum)
            setIsLoadingCourses(false)
            return
        }
        // set isLoadingCourses
        setIsLoadingCourses(true)
        // filter
        var newCourseIndexesFiltered: Array<number> = []
        const searchValueLower: string = searchValue.toLowerCase()
        let currRowCount = 0
        for (let i=0; i<courses.length; ++i) {
            const course = courses[i]
            // if course title includes the current search value, 
            if ((isSearchId && course.courseId.toString().includes(searchValueLower)) ||
                (isSearchTitle && course.courseTitle.toLowerCase().includes(searchValueLower)) ||
                (isSearchDescription && course.courseDescription.toLowerCase().includes(searchValueLower))) {
                newCourseIndexesFiltered.push(i)
                currRowCount++
            }
            // if max row count met, break
            if (currRowCount >= rowCount) {
                break
            }
        }
        // set courseIndexesFiltered
        setCourseIndexesFiltered(newCourseIndexesFiltered)
        setCurrentNumFiltered(currRowCount)
        // set isLoadingCourses
        setIsLoadingCourses(false)
    }, [totalNum, searchValue, courses, isSearchId, isSearchTitle, isSearchDescription, courseIndexesFilteredDefault, rowCount])

    // filter courses whenever searchValue changes
    useEffect(() => {
        // filter
        filterCourses()
    }, [searchValue, filterCourses])

    // ===== newCourseDialog =====
    const [isNewCourseDialogOpen, setIsNewCourseDialogOpen] = useState(false)

    // ===== selectColumnsMenu =====
    let selectColumnsMenuLabels = Array<string>()
    let selectColumnsMenuStates = Array<boolean>()
    let selectColumnsMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isTableId, setIsTableId] = useState(false)
    selectColumnsMenuLabels.push('ID')
    selectColumnsMenuStates.push(isTableId)
    selectColumnsMenuSetStates.push(setIsTableId)
    const [isTableTitle, setIsTableTitle] = useState(true)
    selectColumnsMenuLabels.push('Title')
    selectColumnsMenuStates.push(isTableTitle)
    selectColumnsMenuSetStates.push(setIsTableTitle)
    const [isTableDescription, setIsTableDescription] = useState(true)
    selectColumnsMenuLabels.push('Description')
    selectColumnsMenuStates.push(isTableDescription)
    selectColumnsMenuSetStates.push(setIsTableDescription)
    // populate props
    const selectColumnsMenuProps: ISelectColumnsMenuProps = {
        labels: selectColumnsMenuLabels,
        states: selectColumnsMenuStates,
        setStates: selectColumnsMenuSetStates,
    }

    return (
        <div>
            <Container maxWidth='lg'>

                {/* Admin Heading */}
                <AdminHeading
                    title='Courses'
                />

                {/* Toolbar */}
                <AdminTableToolbar
                    isNewDialogOpen={isNewCourseDialogOpen} refreshTable={refreshCourses} entityName='Course' newEntryTooltip='New Course'
                    setSearchValue={setSearchValue} setIsLoadingTable={setIsLoadingCourses} setIsNewDialogOpen={setIsNewCourseDialogOpen}
                    newDialog={<NewCourseDialog isOpen={isNewCourseDialogOpen} setIsNewCourseDialogOpen={setIsNewCourseDialogOpen} refreshCourses={refreshCourses} />}
                    currentNumFiltered={currentNumFiltered} totalNum={totalNum}
                    selectColumnsMenuProps={selectColumnsMenuProps}
                    searchFilterMenuProps={searchFilterMenuProps}
                    rowCountMenuProps={rowCountMenuProps}
                />

                <TableContainer style={{marginTop: '20px', boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', borderRadius: 5}}>
                    <Table size='small'>

                        {/* Table Head */}
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>
                                    <Checkbox style={{color: 'white'}} />
                                </TableCell> */}

                                {isTableId &&
                                <TableCell padding='none' align='center' style={{padding: '5px'}}>
                                    <div style={{display: 'flex'}}>
                                        <TableHeadTyp>ID</TableHeadTyp>
                                    </div>
                                </TableCell>
                                }

                                {isTableTitle &&
                                <TableCell>
                                    <TableHeadTyp>Course</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableDescription &&
                                <TableCell>
                                    <TableHeadTyp>Description</TableHeadTyp>
                                </TableCell>
                                }
                                <TableCell>
                                    <TableHeadTyp style={{width: '70px'}}></TableHeadTyp>
                                </TableCell>

                                {/* add additional cells */}

                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        {!isLoadingCourses &&
                        <TableBody>
                            {courseIndexesFiltered && courseIndexesFiltered.map((index) =>
                                <CoursesAdminTableRow course={courses[index]} refreshCourses={refreshCourses} key={index}
                                    isId={isTableId} isTitle={isTableTitle} isDescription={isTableDescription}
                                />
                            )}
                        </TableBody>
                        }

                    </Table>
                    {isLoadingCourses &&
                    <LinearProgress />
                    }
                </TableContainer>

                {currentNumFiltered === 0 && searchValue !== '' &&
                <NoSearchResults entityNamePlural='Courses' searchQuery={searchValue} />
                }

            </Container>

        </div>
    )
}

export default CoursesAdminTable