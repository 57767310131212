/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import {
    Container,
    Fade,
    IconButton,
    LinearProgress,
    Skeleton,
    styled,
    Tooltip,
    Typography as MuiTypography,
} from '@mui/material'
import {
    Add as AddIcon,
} from '@mui/icons-material'

// components
import NavBar from '../../../shared/NavBar'
import Header from '../../../shared/Header'
import Footer from '../../../shared/Footer'
import EditCourseCourse from './EditCourseCourse'
import EditCourseSection from './EditCourseSection'
import EditCourseToolbar from './EditCourseToolbar'

// interfaces
import { ICourse, ICourseSection, ICourseSectionModule } from '../../../../models/Course'
import {
    CourseSectionsArrActionType, ICourseSectionsArrAction,
    CourseSectionsMapActionType, ICourseSectionsMapAction,
    CourseSectionsEditsActionType, ICourseSectionsEditsAction,
    CourseSectionModulesEditsActionType, ICourseSectionModulesEditsAction
} from './shared'

// services
import CoursesService from '../../../../services/CoursesService'

// Mui
const Typography = styled(MuiTypography)(({theme}) => ({
    color: theme.palette.primary.main,
}))

const EditCourse = () => {

    // ===== params =====
    const { courseId } = useParams()

    // ===== course =====
    const [course, setCourse] = useState<ICourse | null>(null)
    const [isCourseLoading, setIsCourseLoading] = useState(true)
    const refreshCourse = useCallback(async () => {
        setIsCourseLoading(true)
        const res: any = await CoursesService.adminGetCourse(parseInt(courseId as string))
        if (res.data !== undefined) {
            // reset edits
            setCourseSectionsEdits({
                type: CourseSectionsEditsActionType.reset
            })
            setCourseSectionModulesEdits({
                type: CourseSectionModulesEditsActionType.reset
            })
            // reset deletes
            setCourseSectionsDeletes(new Set<number>())
            setCourseSectionModulesDeletes(new Set<number>())
            // set course
            setCourse(res.data)
            setIsCourseLoading(false)
        }
    }, [courseId])
    // fetch course on mount
    useEffect(() => {
        refreshCourse()
    }, [courseId, refreshCourse])

    // ===== general course edits to toolbar =====
    const [courseTitleEditsToToolbar, setCourseTitleEditsToToolbar] = useState<string | null>(null)
    const [courseDescriptionEditsToToolbar, setCourseDescriptionEditsToToolbar] = useState<string | null>(null)

    // ===== courseSectionsArr ======
    // Array<courseSectionId: number> - holds all courseSectionIds, in the correct order with respect to
    // their corresponding courseSection
    const courseSectionsArrReducer = (state: Array<number> | undefined, action: ICourseSectionsArrAction): Array<number> | undefined => {
        if (action.type === CourseSectionsArrActionType.populate) {
            return action.populateWith!
        }
        if (state === undefined) {
            return undefined
        }
        let currState: Array<number> = [...state as Array<number>]
        switch (action.type) {
            // === swap with below ===
            case CourseSectionsArrActionType.swapWithBelow:
                if (action.currPosition === undefined) {
                    return currState as Array<number>
                }
                [currState![action.currPosition!], currState![action.currPosition!+1]] = [currState![action.currPosition!+1], currState![action.currPosition!]]
                return currState as Array<number>
            // === swap with above ===
            case CourseSectionsArrActionType.swapWithAbove:
                if (!action.currPosition || action.currPosition === undefined) {
                    return currState as Array<number>
                }
                [currState![action.currPosition!-1], currState![action.currPosition!]] = [currState![action.currPosition!], currState![action.currPosition!-1]]
                return currState as Array<number>
            // === add ===
            case CourseSectionsArrActionType.add:
                if (action.newCourseSectionId === null || action.newCourseSectionId === undefined) {
                    return currState
                }
                currState.push(action.newCourseSectionId)
                return currState
            // === delete ===
            case CourseSectionsArrActionType.delete:
                if (action.courseSectionId === null || action.courseSectionId === undefined) {
                    return currState
                }
                let indexToDelete: number = currState.indexOf(action.courseSectionId)
                currState.splice(indexToDelete, 1)
                return currState
        }
    }
    const [courseSectionsArr, setCourseSectionsArr] = useReducer(courseSectionsArrReducer, undefined)

    // ===== courseSectionsMap =====
    // Map<courseSectionId: number, courseSection: ICourseSection> - holds all initial courseSection data
    // (before any edits)
    const courseSectionsMapReducer = (state: Map<number, ICourseSection> | undefined, action: ICourseSectionsMapAction): Map<number, ICourseSection> | undefined => {
        switch (action.type) {
            case CourseSectionsMapActionType.populate:
                return action.populateWith as Map<number, ICourseSection>
        }
    }
    const [courseSectionsMap, setCourseSectionsMap] = useReducer(courseSectionsMapReducer, undefined)

    // ===== courseSectionsEdits =====
    // Map<courseSectionId: number, courseSection: ICourseSection> - holds all courseSection edits data
    const courseSectionsEditsReducer = (state: Map<number, ICourseSection>, action: ICourseSectionsEditsAction): Map<number, ICourseSection> => {
        // if courseSectionsArr or courseSectionsMap has not been initialized, return
        if (!courseSectionsArr || !courseSectionsMap) {
            return state
        }
        // get courseSection
        let thisSection = state.get(action.courseSectionId!)
        if (!thisSection) {
            thisSection = cloneDeep(courseSectionsMap?.get(action.courseSectionId!))
        }
        // if undefined and not adding or resetting, return
        if ((!thisSection || thisSection === undefined) && (action.type !== CourseSectionsEditsActionType.add && action.type !== CourseSectionsEditsActionType.reset)) {
            return state
        }
        // get currState
        let currState: Map<number, ICourseSection>
        if (!state || state === undefined) {
            currState = new Map<number, ICourseSection>()
        } else {
            currState = new Map<number, ICourseSection>(state)
        }
        switch (action.type) {
            // === change section title ===
            case CourseSectionsEditsActionType.changeCourseSectionTitle:
                // edit section title
                thisSection!.courseSectionTitle = action.newCourseSectionTitle as string
                // save section
                currState.set(action.courseSectionId!, thisSection!)
                return currState
            // === moven down ===
            case CourseSectionsEditsActionType.swapWithBelow:
                // find section with sequenceId +1
                let sectionToDecrement: ICourseSection | null = null
                // first, check edits
                currState.forEach((section) => {
                    if (section.sequenceId === thisSection!.sequenceId+1) {
                        sectionToDecrement = cloneDeep(section)
                    }
                })
                // if not found in edits, need to get from courseSectionsMap
                if (sectionToDecrement === null) {
                    courseSectionsMap.forEach((section) => {
                        if (section.sequenceId === thisSection!.sequenceId+1) {
                            sectionToDecrement = cloneDeep(section)
                        }
                    })
                }
                // if no section found, return
                if (sectionToDecrement === null) {
                    return state
                }
                // adjust sequenceIds
                (sectionToDecrement as ICourseSection).sequenceId -= 1
                thisSection!.sequenceId += 1
                // save state
                currState.set((sectionToDecrement as ICourseSection).courseSectionId, sectionToDecrement)
                currState.set(thisSection!.courseSectionId, thisSection!)
                return currState
            // === move up ===
            case CourseSectionsEditsActionType.swapWithAbove:
                // find section with sequenceId -1
                let sectionToIncrement: ICourseSection | null = null
                // first, check edits
                state.forEach((section) => {
                    if (section.sequenceId === thisSection!.sequenceId-1) {
                        sectionToIncrement = cloneDeep(section)
                    }
                })
                // if not found in edits, need to get from courseSectionsMap
                if (sectionToIncrement === null) {
                    courseSectionsMap.forEach((section) => {
                        if (section.sequenceId === thisSection!.sequenceId-1) {
                            sectionToIncrement = cloneDeep(section)
                        }
                    })
                }
                // if no section found, return
                if (!sectionToIncrement) {
                    return state
                }
                // adjust sequenceIds
                (sectionToIncrement as ICourseSection).sequenceId += 1
                thisSection!.sequenceId -= 1
                // save state
                currState.set((sectionToIncrement as ICourseSection).courseSectionId, sectionToIncrement)
                currState.set(thisSection!.courseSectionId, thisSection!)
                console.log(currState)
                return currState
            // === add ===
            case CourseSectionsEditsActionType.add:
                if (action.newCourseSection === null || action.newCourseSection === undefined) {
                    return state
                }
                currState.set(action.newCourseSection!.courseSectionId, action.newCourseSection!)
                return currState
            // === delete ===
            case CourseSectionsEditsActionType.delete:
                if (action.courseSectionId === null || action.courseSectionId === undefined) {
                    return state
                }
                // first look in edits for current position
                let positionOfThisSection = currState.get(action.courseSectionId)?.sequenceId
                // if not in edits, get from map
                if (positionOfThisSection === null || positionOfThisSection === undefined) {
                    positionOfThisSection = courseSectionsMap.get(action.courseSectionId)?.sequenceId
                }
                if (positionOfThisSection === null || positionOfThisSection === undefined) {
                    positionOfThisSection = currState.get(action.courseSectionId)!.sequenceId
                }
                if (positionOfThisSection === null || positionOfThisSection === undefined) {
                    return state
                }
                for (let i=positionOfThisSection; i<courseSectionsArr.length; ++i) {
                    // get copy of courseSectionsArr
                    let currCourseSectionsArr = [...courseSectionsArr]
                    // if this section, skip
                    if (currCourseSectionsArr[i] === action.courseSectionId) {
                        continue
                    }
                    // else, try to find in courseSectionsEdits
                    let thisSection = cloneDeep(currState.get(currCourseSectionsArr[i]))
                    // if not in courseSectionsEdits, get from courseSectionsMap
                    if (thisSection === null || thisSection === undefined) {
                        thisSection = cloneDeep(courseSectionsMap.get(currCourseSectionsArr[i]))
                    }
                    // if still null or undefined, continue
                    if (thisSection === null || thisSection === undefined) {
                        continue
                    }
                    // adjust position
                    thisSection.sequenceId -= 1
                    // save to edits
                    currState.set(thisSection.courseSectionId, thisSection)
                }
                // delete the section being deleted
                currState.delete(action.courseSectionId)
                return currState
            // === reset ===
            case CourseSectionsEditsActionType.reset:
                return new Map<number, ICourseSection>()
        }
    }
    const [courseSectionsEdits, setCourseSectionsEdits] = useReducer(courseSectionsEditsReducer, new Map<number, ICourseSection>())

    // ===== courseSectionsDeletes =====
    // Array<courseSectionId: number> courseSectionIds to delete
    const [courseSectionsDeletes, setCourseSectionsDeletes] = useState(new Set<number>())

    // ===== handleCourseSectionDelete =====
    // handles deleting sections
    const handleCourseSectionDelete = (e: React.MouseEvent, courseSectionId: number) => {
        // prevent accordion from opening
        e.stopPropagation()
        if (courseSectionsDeletes.has(courseSectionId)) {
            // already deleted, return
            return
        }
        // if id >= 0, add to courseSectionsDeletes (if < 0, this means that it is a new section,
        // so it should not be added to courseSectionsDeletes because there is really nothing
        // to delete)
        if (courseSectionId >= 0) {
            let currCourseSectionsDeletes = cloneDeep(courseSectionsDeletes)
            currCourseSectionsDeletes.add(courseSectionId)
            setCourseSectionsDeletes(currCourseSectionsDeletes)
        }
        // get section
        // first, check in edits
        let thisSection: ICourseSection | undefined = cloneDeep(courseSectionsEdits.get(courseSectionId))
        // if not in edits, get from map
        if (thisSection === undefined) {
            thisSection = cloneDeep(courseSectionsMap?.get(courseSectionId))
        }
        // remove from courseSectionsEdits
        setCourseSectionsEdits({
            type: CourseSectionsEditsActionType.delete,
            courseSectionId: courseSectionId
        })
        // remove from courseSectionsArr
        setCourseSectionsArr({
            type: CourseSectionsArrActionType.delete,
            courseSectionId: courseSectionId
        })
    }

    // ===== swap section handlers =====
    // (click event: React.MouseEvent, courseSectionId: number)
    const swapSectionWithBelow = (e: React.MouseEvent, courseSectionId: number): void => {
        // stop accordion from opening
        e.stopPropagation()
        // if courseSectionsMap or courseSectionsArr is undefined, error
        if (!courseSectionsMap || !courseSectionsArr) {
            return
        }
        // get sectionPositions
        let thisSectionPosition = courseSectionsEdits.get(courseSectionId)?.sequenceId
        if (thisSectionPosition === undefined) {
            thisSectionPosition = courseSectionsMap.get(courseSectionId)?.sequenceId
        }
        if (thisSectionPosition === undefined) {
            return
        }
        // if already last in sequence, return
        if (thisSectionPosition === courseSectionsArr.length-1) {
            return
        }
        // swap
        setCourseSectionsEdits({
            type: CourseSectionsEditsActionType.swapWithBelow,
            courseSectionId: courseSectionId
        })
        setCourseSectionsArr({
            type: CourseSectionsArrActionType.swapWithBelow,
            currPosition: thisSectionPosition
        })
    }
    const swapSectionWithAbove = (e: React.MouseEvent, courseSectionId: number): void => {
        // stop accordion from opening
        e.stopPropagation()
        // if courseSectionsMap or courseSectionsArr is undefined, error
        if (!courseSectionsMap || !courseSectionsArr) {
            return
        }
        // get sectionPositions
        // get sectionPositions
        let thisSectionPosition = courseSectionsEdits.get(courseSectionId)?.sequenceId
        if (thisSectionPosition === undefined) {
            thisSectionPosition = courseSectionsMap.get(courseSectionId)?.sequenceId
        }
        if (thisSectionPosition === undefined) {
            return
        }
        // if already first in sequence, return
        if (thisSectionPosition === 0) {
            return
        }
        // swap
        setCourseSectionsEdits({
            type: CourseSectionsEditsActionType.swapWithAbove,
            courseSectionId: courseSectionId
        })
        setCourseSectionsArr({
            type: CourseSectionsArrActionType.swapWithAbove,
            currPosition: thisSectionPosition
        })
    }

    // ===== courseSectionModulesMap =====
    // Map<courseSectionModuleId: number, courseSectionModule: ICourseSectionModule> - holds all initial
    // courseSectionModule data (before any edits)
    const [courseSectionModulesMap, setCourseSectionModulesMap] = useState<Map<number, ICourseSectionModule> | undefined>(undefined)

    // ===== courseSectionModulesEdits =====
    // Map<courseSectionId: number, courseSectionModule: ICourseSectionModule> - holds all courseSectionModule edits
    const courseSectionModulesEditsReducer = useCallback((state: Map<number, ICourseSectionModule>, action: ICourseSectionModulesEditsAction): Map<number, ICourseSectionModule> => {
        // if courseSectionModulesMap has not been initialized, return
        if (courseSectionModulesMap === undefined) {
            return state
        }
        // get courseSectionModule
        let thisModule = state?.get(action.courseSectionModuleId!)
        if (!thisModule) {
            thisModule = cloneDeep(courseSectionModulesMap?.get(action.courseSectionModuleId!))
        }
        // if undefined, return
        if ((!thisModule || thisModule === undefined) && (action.type !== CourseSectionModulesEditsActionType.add && action.type !== CourseSectionModulesEditsActionType.reset)) {
            return state
        }
        // get currState
        let currState: Map<number, ICourseSectionModule>
        if (!state || state === undefined) {
            currState = new Map<number, ICourseSectionModule>()
        } else {
            currState = new Map<number, ICourseSectionModule>(state)
        }
        switch (action.type) {
            // === change module title ===
            case CourseSectionModulesEditsActionType.changeCourseSectionModuleTitle:
                // edit module title
                thisModule!.courseSectionModuleTitle = action.newCourseSectionModuleTitle as string
                // save section
                currState.set(action.courseSectionModuleId!, thisModule!)
                return currState
            // === change module description ===
            case CourseSectionModulesEditsActionType.changeCourseSectionModuleDescription:
                // edit module description
                thisModule!.courseSectionModuleDescription = action.newCourseSectionModuleDescription as string
                // save section
                currState.set(action.courseSectionModuleId!, thisModule!)
                return currState
            // === change module embedUrl ===
            case CourseSectionModulesEditsActionType.changeCourseSectionModuleEmbedUrl:
                // edit module description
                thisModule!.embedUrl = action.newCourseSectionModuleEmbedUrl as string
                // save section
                currState.set(action.courseSectionModuleId!, thisModule!)
                return currState
            // === move section down ===
            case CourseSectionModulesEditsActionType.swapWithBelow:
                // find section with sequenceId +1
                let moduleToDecrement: ICourseSectionModule | null = null
                // first, check edits
                currState.forEach((module) => {
                    if (module.courseSection_CourseSectionId === action.courseSectionId && module.sequenceId === thisModule!.sequenceId+1) {
                        moduleToDecrement = cloneDeep(module)
                    }
                })
                // if not found in edits, need to get from courseSectionModulesMap
                if (moduleToDecrement === null) {
                    courseSectionModulesMap.forEach((module) => {
                        if (module.courseSection_CourseSectionId === action.courseSectionId && module.sequenceId === thisModule!.sequenceId+1) {
                            moduleToDecrement = cloneDeep(module)
                        }
                    })
                }
                // if no section found, return
                if (moduleToDecrement === null) {
                    return state
                }
                // adjust sequenceIds
                (moduleToDecrement as ICourseSectionModule).sequenceId -= 1
                thisModule!.sequenceId += 1
                // save state
                currState.set((moduleToDecrement as ICourseSectionModule).courseSectionModuleId, moduleToDecrement)
                currState.set(thisModule!.courseSectionModuleId, thisModule!)
                return currState
            // === move module up ===
            case CourseSectionModulesEditsActionType.swapWithAbove:
                // find section with sequenceId -1
                let moduleToIncrement: ICourseSectionModule | null = null
                // first, check edits
                currState.forEach((module) => {
                    if (module.courseSection_CourseSectionId === action.courseSectionId && module.sequenceId === thisModule!.sequenceId-1) {
                        moduleToIncrement = cloneDeep(module)
                    }
                })
                // if not found in edits, need to get from courseSectionModulesMap
                if (moduleToIncrement === null) {
                    courseSectionModulesMap.forEach((module) => {
                        if (module.courseSection_CourseSectionId === action.courseSectionId && module.sequenceId === thisModule!.sequenceId-1) {
                            moduleToIncrement = cloneDeep(module)
                        }
                    })
                }
                // if no section found, return
                if (moduleToIncrement === null) {
                    return state
                }
                // adjust sequenceIds
                (moduleToIncrement as ICourseSectionModule).sequenceId += 1
                thisModule!.sequenceId -= 1
                // save state
                console.log(currState)
                currState.set((moduleToIncrement as ICourseSectionModule).courseSectionModuleId, moduleToIncrement)
                currState.set(thisModule!.courseSectionModuleId, thisModule!)
                return currState
            // === add new module ===
            case CourseSectionModulesEditsActionType.add:
                if (action.newCourseSectionModule === null || action.newCourseSectionModule === undefined) {
                    return state
                }
                currState.set(action.newCourseSectionModule!.courseSectionModuleId, action.newCourseSectionModule!)
                return currState
            // === delete ===
            case CourseSectionModulesEditsActionType.delete:
                if (action.courseSectionModuleId === null || action.courseSectionModuleId === undefined) {
                    return state
                }
                // first look in edits for current position
                let positionOfThisModule = currState.get(action.courseSectionModuleId)?.sequenceId
                // if not in edits, get from map
                if (positionOfThisModule === null || positionOfThisModule === undefined) {
                    positionOfThisModule = courseSectionModulesMap.get(action.courseSectionModuleId)?.sequenceId
                }
                if (positionOfThisModule === null || positionOfThisModule === undefined) {
                    positionOfThisModule = currState.get(action.courseSectionModuleId)!.sequenceId
                }
                if (positionOfThisModule === null || positionOfThisModule === undefined) {
                    return state
                }
                if (action.modulesToShiftFromDelete === null || action.modulesToShiftFromDelete === undefined) {
                    return state
                }
                for (let i=positionOfThisModule; i<action.modulesToShiftFromDelete.length; ++i) {
                    // get copy of courseSectionsArr
                    let currCourseSectionModulesArr = [...action.modulesToShiftFromDelete]
                    // if this section, skip
                    if (currCourseSectionModulesArr[i].courseSectionModuleId === action.courseSectionModuleId) {
                        continue
                    }
                    // else, try to find in courseSectionsEdits
                    let thisModule = cloneDeep(currState.get(currCourseSectionModulesArr[i].courseSectionModuleId))
                    // if not in courseSectionsEdits, get from courseSectionsMap
                    if (thisModule === null || thisModule === undefined) {
                        thisModule = cloneDeep(courseSectionModulesMap.get(currCourseSectionModulesArr[i].courseSectionModuleId))
                    }
                    // if still null or undefined, continue
                    if (thisModule === null || thisModule === undefined) {
                        continue
                    }
                    // adjust position
                    thisModule.sequenceId -= 1
                    // save to edits
                    currState.set(thisModule.courseSectionModuleId, thisModule)
                }
                // delete the section being deleted
                currState.delete(action.courseSectionModuleId)
                return currState
            // === reset ===
            case CourseSectionModulesEditsActionType.reset:
                return new Map<number, ICourseSectionModule>()
        }
    }, [courseSectionModulesMap])
    const [courseSectionModulesEdits, setCourseSectionModulesEdits] = useReducer(courseSectionModulesEditsReducer, new Map<number, ICourseSectionModule>())

    // ===== courseSectionModulesDeletes =====
    // Array<courseSectionModuleId: number> courseSectionModuleIds to delete
    const [courseSectionModulesDeletes, setCourseSectionModulesDeletes] = useState(new Set<number>())

    // ===== handleCourseSectionModuleDelete =====
    // handles deleting modules
    const handleCourseSectionModuleDelete = (courseSectionModuleId: number, modulesToShiftFromDelete: Array<ICourseSectionModule>) => {
        if (courseSectionModulesDeletes.has(courseSectionModuleId)) {
            // already deleted, return
            return
        }
        // if id >= 0, add to courseSectionModulesDeletes (if < 0, this means that it is a new
        // section, so it should not be added to courseSectionModulesDeletes because there
        // is really nothing to delete)
        if (courseSectionModuleId >= 0) {
            let currCourseSectionModulesDeletes = cloneDeep(courseSectionModulesDeletes)
            currCourseSectionModulesDeletes.add(courseSectionModuleId)
            setCourseSectionModulesDeletes(currCourseSectionModulesDeletes)
        }
        // remove from courseSectionModulesEdits
        setCourseSectionModulesEdits({
            type: CourseSectionModulesEditsActionType.delete,
            modulesToShiftFromDelete: modulesToShiftFromDelete,
            courseSectionModuleId: courseSectionModuleId
        })
    }

    // ===== handleAddCourseSectionModule =====
    // adds new courseSectionModule, returns the new module object for editCourseSection to use
    const [currTempCourseSectionModuleId, setCurrTempCourseSectionModuleId] = useState<number>(-1)
    const handleAddCourseSectionModule = (courseSectionId: number, newModulePosition: number): ICourseSectionModule | null => {
        if (course === null || course === undefined) {
            return null
        }
        const newCourseSectionModule: ICourseSectionModule = {
            courseSectionModuleId: currTempCourseSectionModuleId,
            courseSectionModuleTitle: '',
            courseSection_CourseSectionId: courseSectionId,
            sequenceId: newModulePosition!,
            courseSectionModuleDescription: '',
            embedUrl: ''
        }
        setCourseSectionModulesEdits({
            type: CourseSectionModulesEditsActionType.add,
            newCourseSectionModule: newCourseSectionModule,
            courseSectionModuleId: currTempCourseSectionId
        })
        setCurrTempCourseSectionModuleId(currTempCourseSectionModuleId-1)
        return newCourseSectionModule
    }

    // ===== handleAddCourseSection ===== 
    // adds new courseSection
    const [currTempCourseSectionId, setCurrTempCourseSectionId] = useState<number>(-1)
    const handleAddCourseSection = () => {
        if (course === null || course === undefined || courseSectionsArr === undefined) {
            return
        }
        const newCourseSection: ICourseSection = {
            courseSectionId: currTempCourseSectionId,
            courseSectionTitle: '',
            courseSectionModules: new Array<ICourseSectionModule>(),
            course_CourseId: course!.courseId,
            sequenceId: courseSectionsArr!.length
        }
        setCourseSectionsEdits({
            type: CourseSectionsEditsActionType.add,
            newCourseSection: newCourseSection,
            courseSectionId: currTempCourseSectionId
        })
        setCourseSectionsArr({
            type: CourseSectionsArrActionType.add,
            newCourseSectionId: currTempCourseSectionId
        })
        setCurrTempCourseSectionId(currTempCourseSectionId-1)
    }

    // ===== populate data =====
    useEffect(() => {
        if (course === null) {
            // error / course not loaded yet
            return
        }
        // courseSectionsArr, courseSectionsMap, courseSectionModulesMap
        let newCourseSectionsArr = new Array<number>()
        let newCourseSectionsMap = new Map<number, ICourseSection>()
        let newCourseSectionModulesMap = new Map<number, ICourseSectionModule>()
        course.courseSections?.forEach(section => {
            newCourseSectionsArr.push(section.courseSectionId)
            newCourseSectionsMap.set(section.courseSectionId, section)
            section.courseSectionModules?.forEach(module => {
                // the module's embedUrl is deep into module >> component >> embed, so a new 'embedUrl' variable
                // has been created and attached to the module for simplification of the moduleEdit data structure
                let newModule: ICourseSectionModule = cloneDeep(module)
                newModule.embedUrl = newModule.courseSectionModuleComponents?.[0].courseSectionModuleComponentEmbed?.embedUrl ?? ''
                newCourseSectionModulesMap.set(module.courseSectionModuleId, newModule)
            })
        })
        setCourseSectionsArr({
            type: CourseSectionsArrActionType.populate,
            populateWith: newCourseSectionsArr
        })
        setCourseSectionsMap({
            type: CourseSectionsMapActionType.populate,
            populateWith: newCourseSectionsMap
        })
        setCourseSectionModulesMap(newCourseSectionModulesMap)
    }, [course])

    // ===== editCourseToolbarPropsMemoized =====
    const editCourseToolbarPropsMemoized = useMemo(() => {
        return {
            course, refreshCourse,
            courseTitleEditsToToolbar, courseDescriptionEditsToToolbar,
            courseSectionsEdits, courseSectionModulesEdits,
            courseSectionsMap, courseSectionModulesMap,
            courseSectionsDeletes, courseSectionModulesDeletes
        }
    }, [
        course, refreshCourse,
        courseTitleEditsToToolbar, courseDescriptionEditsToToolbar,
        courseSectionsEdits, courseSectionModulesEdits,
        courseSectionsMap, courseSectionModulesMap,
        courseSectionsDeletes, courseSectionModulesDeletes
    ])

    // ===== return =====
    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Admin Portal' isAdmin adminLocation='Manage Courses' />

            {/* Edit Course */}
            <Container maxWidth='lg'>

                <div style={{height: '20px'}} />

                {/* Toolbar */}
                <EditCourseToolbar {...editCourseToolbarPropsMemoized} />

                <div style={{height: '20px'}} />

                {/* Course Title & Description */}
                <EditCourseCourse courseTitle={course?.courseTitle} courseDescription={course?.courseDescription}
                    sendCourseTitleEditsToParent={setCourseTitleEditsToToolbar}
                    sendCourseDescriptionEditsToParent={setCourseDescriptionEditsToToolbar}
                />

                <div style={{height: '20px'}} />

                {/* Course Sections */}
                <Typography variant='h6'>Sections</Typography>
                <Fade in={isCourseLoading}>
                    <LinearProgress />
                </Fade>
                <Fade in={!isCourseLoading}>
                    <div>
                        {courseSectionsArr && courseSectionsArr.map((courseSectionId, index) =>
                        <EditCourseSection setCourseSectionsEdits={setCourseSectionsEdits} setCourseSectionModulesEdits={setCourseSectionModulesEdits}
                            isFirst={index===0} isLast={index===courseSectionsArr.length-1} courseSectionModulesEdits={courseSectionModulesEdits}
                            swapSectionWithBelow={swapSectionWithBelow} swapSectionWithAbove={swapSectionWithAbove}
                            section={courseSectionsMap?.has(courseSectionId) ? courseSectionsMap?.get(courseSectionId) as ICourseSection :
                                courseSectionsEdits.get(courseSectionId) as ICourseSection}
                            key={courseSectionId} index={index} handleAddCourseSectionModule={handleAddCourseSectionModule}
                            handleCourseSectionDelete={handleCourseSectionDelete} handleCourseSectionModuleDelete={handleCourseSectionModuleDelete}
                        />
                        )}
                    </div>
                </Fade>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{width: '100%'}}>
                        <Tooltip title='Add Section' style={{marginTop: '5px'}}>
                            <IconButton onClick={handleAddCourseSection}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

            </Container>

            <div style={{height: '40px'}} />
            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default EditCourse