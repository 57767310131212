/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Box,
    Chip,
    Container,
    Divider as MuiDivider,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    Skeleton,
    styled,
    Typography,
} from '@mui/material'
import {
    Check as CheckIcon,
    Stars as StarsIcon,
} from '@mui/icons-material'

// components
import NavBar from '../shared/NavBar'
import Header from '../shared/Header'
import CourseBreadcrumbs from './CourseBreadcrumbs'
import Footer from '../shared/Footer'

// interfaces
import { IStudentCourse } from '../../models/StudentCourse'
import { ICourse } from '../../models/Course'
import { ApiRes } from '../../models/Api'

// services
import StudentCoursesService from '../../services/StudentCoursesService'
import CoursesService from '../../services/CoursesService'

// imgs
import advancedDataModelingCourseImg from '../../assets/imgs/courses/advanced-data-modeling.png'
import agileBusinessAnalysisCourseImg from '../../assets/imgs/courses/agile-business-analysis.png'
import businessProcessModelingCourseImg from '../../assets/imgs/courses/business-process-modeling.png'
import businessProcessReengineeringCourseImg from '../../assets/imgs/courses/business-process-reengineering.png'
import businessSystemsAnalysisCourseImg from '../../assets/imgs/courses/business-systems-analysis.png'
import logicalDataModelingCourseImg from '../../assets/imgs/courses/logical-data-modeling.png'
import softSkillsCourseImg from '../../assets/imgs/courses/soft-skills.png'

const Accordion = styled(MuiAccordion)(({theme}) => ({
    backgroundColor: 'white',
    color: theme.palette.primary.main,
}))
const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    backgroundColor: 'white'
}))
const AccordionSummary = styled(MuiAccordionSummary)(({theme}) => ({
    display: 'flex',
}))
const DescriptionBox = styled(Box)(({theme}) => ({
    color: theme.palette.primary.main,
    marginTop: '7.5px',
    marginBottom: '20px'
}))
const Divider = styled(MuiDivider)(({theme}) => ({
}))
const SectionChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const SectionsBox = styled(Box)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const SectionsTitle = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    marginBottom: '10px'
}))

const Course = () => {

    // navigate, params
    const navigate = useNavigate()
    const { courseId } = useParams()

    // ===== studentCourse =====
    // const [studentCourse, setStudentCourse] = useState<IStudentCourse>()
    // const [isLoadingStudentCourse, setIsLoadingStudentCourse] = useState(true)
    // useEffect(() => {
    //     if (courseId === undefined) {
    //         return
    //     }
    //     const getStudentCourse = async () => {
    //         const res: ApiRes<IStudentCourse> = await StudentCoursesService.getStudentCourse(parseInt(courseId))
    //         if (!res.error) {
    //             console.log(courseId)
    //             setStudentCourse(res.data)
    //             setIsLoadingStudentCourse(false)
    //         }
    //     }
    //     getStudentCourse()
    // }, [courseId])

    // ===== course =====
    const [course, setCourse] = useState<ICourse>()
    useEffect(() => {
        if (courseId === undefined) {
            return
        }
        const getCourse = async () => {
            const res: ApiRes<ICourse> = await CoursesService.getCourse(parseInt(courseId as string))
            if (!res.error) {
                setCourse(res.data)
            }
        }
        getCourse()
    }, [courseId])

    // ===== courseImg =====
    const [courseImg, setCourseImg] = useState<any>(null)
    useEffect(() => {
        switch (parseInt(courseId as string)) {
            case 2:
                setCourseImg(businessSystemsAnalysisCourseImg)
                break
            case 8:
                setCourseImg(logicalDataModelingCourseImg)
                break
            case 9:
                setCourseImg(businessProcessModelingCourseImg)
                break
            case 10:
                setCourseImg(agileBusinessAnalysisCourseImg)
                break
            case 11:
                setCourseImg(softSkillsCourseImg)
                break
            case 12:
                setCourseImg(businessProcessReengineeringCourseImg)
                break
            case 13:
                setCourseImg(advancedDataModelingCourseImg)
                break
        }
    }, [courseId])

    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title={course?.courseTitle} />

            {/* Courses Breadcrumbs */}
            <CourseBreadcrumbs courseTitle={course?.courseTitle} courseId={course?.courseId} />

            {/* Course */}
            <Container maxWidth='lg' style={{marginBottom: '40px'}}>
                <Grid container rowSpacing={0} columnSpacing={5} style={{marginTop: 0}}>

                    {/* Description */}
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <DescriptionBox>
                            {!course ?
                            <Skeleton variant='rectangular' width='100%'>
                                <div style={{paddingTop: '57%'}} />
                            </Skeleton> :
                            <img src={courseImg} alt='' style={{width: '100%', marginBottom: '10px'}} />
                            }
                            <Typography variant='h5'>{!course ? <Skeleton /> : course?.courseTitle}</Typography>
                            <Divider />
                            {!course ?
                            <Skeleton variant='rectangular' width='100%'>
                                <div style={{paddingTop: '57%'}} />
                            </Skeleton> :
                            <Typography style={{marginTop: '10px'}}>
                                {course?.courseDescription}
                            </Typography>
                            }
                        </DescriptionBox>
                    </Grid>

                    {/* Sections */}
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <SectionsTitle variant='h5'>Sections</SectionsTitle>
                        {!course ?
                        <LinearProgress /> :
                        <SectionsBox>
                            {course!.courseSections!.map((section, sectionIndex) =>
                                <Accordion key={sectionIndex}>
                                    <AccordionSummary>
                                        <div style={{display: 'flex', height: '100%', justifyContent: 'left', width: '60%'}}>
                                            {(sectionIndex===0 ?
                                                <div style={{width: '33px'}} /> :
                                                <SectionChip size='small' label={sectionIndex} />
                                            )}
                                            <Typography>{section.courseSectionTitle}</Typography>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '40%'}}>
                                            {/* <LinearProgress variant='determinate' value={0} style={{width: '75%', marginRight: '10px'}} /> */}
                                            {/* <Typography>{0}%</Typography> */}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{padding: 0}}>
                                        <Divider sx={{backgroundColor: 'primary.light'}} />
                                        <List disablePadding>
                                            {section.courseSectionModules!.map((module, moduleIndex) => 
                                                (module.courseSectionModuleComponents![0].courseSectionModuleComponentEmbed.embedUrl ?
                                                <ListItem disableGutters disablePadding divider key={moduleIndex}>
                                                    <ListItemButton onClick={() => navigate(section.sequenceId + '/' + module.sequenceId)} style={{display: 'flex'}}>
                                                    <div style={{display: 'flex', height: '100%', justifyContent: 'left', width: '60%'}}>
                                                        <Typography style={{marginLeft: '33px'}}>{module.courseSectionModuleTitle}</Typography>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '40%'}}>
                                                        <div style={{width: '75%', marginRight: '10px'}} />
                                                        {/*(module.progress===100 ?
                                                            <CheckIcon /> :
                                                            null
                                                        )*/}
                                                    </div>
                                                    </ListItemButton>
                                                </ListItem> : null
                                                )
                                            )}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </SectionsBox>
                        }
                    </Grid>
                </Grid>
            </Container>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default Course