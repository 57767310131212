/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material'

// interfaces
import { IInstructorQuestion } from "../../../models/InstructorQuestion"
import { ApiRes } from '../../../models/Api'

// services
import StudentCoursesService from '../../../services/StudentCoursesService'

// mui
const ColorBoldTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold'
}))
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const IdCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
}))

interface QAndAAdminTableRowProps {
    question: IInstructorQuestion
    refreshQuestions: (setIsLoadingQuestionsFalseOnRefresh?: boolean) => void
    isTableId: boolean
    isTableCourseTitle: boolean
    isTableCourseSectionNum: boolean
    isTableStudentCourseId: boolean
    isTableStudentCourseSectionId: boolean
    isTableUserId: boolean
    isTableUserLastName: boolean
    isTableUserFirstName: boolean
    isTableUserEmailAddress: boolean
    isTableQuestion: boolean
    isTableAnswer: boolean
}
const QAndAAdminTableRow = (props: QAndAAdminTableRowProps) => {

    // ===== delete question =====
    const [isDeleteQuestionDialogOpen, setIsDeleteQuestionDialogOpen] = useState(false)
    const [isSendingDeleteQuestion, setIsSendingDeleteQuestion] = useState(false)
    const handleDeleteQuestion = async () => {
        setIsSendingDeleteQuestion(true)
        const res: ApiRes<void> = await StudentCoursesService.adminDeleteQuestion(props.question.instructorQuestionId)
        setIsSendingDeleteQuestion(false)
        if (!res.error) {
            setIsDeleteQuestionDialogOpen(false)
        }
        props.refreshQuestions(true)
    }

    // ===== edit answer =====
    const [isEditAnswerDialogOpen, setIsEditAnswerDialogOpen] = useState(false)
    const [answerEdits, setAnswerEdits] = useState<string>(props.question.instructorAnswer?.answer ?? '')
    const [isSendingAnswerEdits, setIsSendingAnswerEdits] = useState(false)
    const handleEditAnswer = async () => {
        if (answerEdits === props.question.instructorAnswer?.answer) {
            return
        }
        setIsSendingAnswerEdits(true)
        const res: ApiRes<void> = await StudentCoursesService.adminUpdateInstructorAnswer(props.question.instructorQuestionId, answerEdits)
        setIsSendingAnswerEdits(false)
        if (!res.error) {
            setIsEditAnswerDialogOpen(false)
        }
        props.refreshQuestions(true)
    }

    // ===== isHovering =====
    const [isHovering, setIsHovering] = useState(false)
    // setIsHover to false on dialog close
    useEffect(() => {
        setIsHovering(false)
    }, [isDeleteQuestionDialogOpen, isEditAnswerDialogOpen])

    return (
        <TableRow hover onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)} style={{width: '100%'}}>

            {/* ID */}
            {props.isTableId &&
            <IdCell padding='none' align='center' style={{padding: '5px', height: '100%'}}>
                <Typography>
                    {props.question && props.question.instructorQuestionId ?
                    props.question.instructorQuestionId : null}
                </Typography>
            </IdCell>
            }

            {/* Course Title */} 
            {props.isTableCourseTitle &&
            <TableCell>
                <Typography style={{overflowWrap: 'anywhere'}}>{props.question.courseTitle}</Typography>
            </TableCell>
            }

            {/* Course Section Num */} 
            {props.isTableCourseSectionNum &&
            <TableCell>
                <Typography>{props.question.courseSectionNum}</Typography>
            </TableCell>
            }

            {/* Student Course Id */} 
            {props.isTableStudentCourseId &&
            <TableCell>
                <Typography>{props.question.studentCourseId}</Typography>
            </TableCell>
            }

            {/* Student Course Section Id */} 
            {props.isTableStudentCourseSectionId &&
            <TableCell>
                <Typography>{props.question.studentCourseSectionId}</Typography>
            </TableCell>
            }

            {/* User Id */} 
            {props.isTableUserId &&
            <TableCell>
                <Typography>{props.question.userId}</Typography>
            </TableCell>
            }

            {/* User Last Name */} 
            {props.isTableUserLastName &&
            <TableCell>
                <Typography>{props.question.userProfile.lastName}</Typography>
            </TableCell>
            }

            {/* User First Name */} 
            {props.isTableUserFirstName &&
            <TableCell>
                <Typography>{props.question.userProfile.firstName}</Typography>
            </TableCell>
            }

            {/* User Email Address */} 
            {props.isTableUserEmailAddress &&
            <TableCell>
                <Typography>{props.question.userProfile.emailAddress}</Typography>
            </TableCell>
            }

            {/* Question */}
            {props.isTableQuestion &&
            <TableCell>
                <Typography style={{width: '300px', overflowWrap: 'break-word'}}>{props.question.question}</Typography>
            </TableCell>
            }

            {/* Answer */}
            {props.isTableAnswer &&
            <TableCell>
                <Typography style={{width: '300px', overflowWrap: 'break-word'}}>{props.question.instructorAnswer?.answer}</Typography>
            </TableCell>
            }

            {/* Actions */}
            <TableCell style={{height: '40px'}}>
                <div style={{width: '80px'}}>
                    {isHovering &&
                    <div style={{display: 'flex'}}>
                        <Tooltip title='Edit Answer'>
                            <IconButton onClick={() => setIsEditAnswerDialogOpen(true)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete Question'>
                            <IconButton onClick={() => setIsDeleteQuestionDialogOpen(true)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                </div>
            </TableCell>

            {/* Delete Question Dialog */}
            <Dialog open={isDeleteQuestionDialogOpen} onClose={() => setIsDeleteQuestionDialogOpen(false)} fullWidth maxWidth='sm'>
                <DialogContent>
                    <div style={{display: 'flex'}}>
                        <Typography>Delete</Typography>
                        <ColorTyp style={{marginLeft: '5px', fontWeight: 'bold'}}>{props.question.userProfile.firstName}</ColorTyp><Typography>'s</Typography>
                        <Typography style={{marginLeft: '5px'}}>question from</Typography>
                        <ColorTyp style={{marginLeft: '5px', fontWeight: 'bold'}}>{props.question.courseTitle}</ColorTyp><Typography>?</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{textTransform: 'none'}} variant='contained' onClick={() => handleDeleteQuestion()}>
                        {isSendingDeleteQuestion ?
                        <CircularProgress size={20} style={{color: 'white'}} /> :
                        ('Delete Question')
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Answer Dialog */}
            <Dialog open={isEditAnswerDialogOpen} onClose={() => setIsEditAnswerDialogOpen(false)} fullWidth maxWidth='md'>
                <DialogContent>
                    <TextField fullWidth multiline label='Answer' value={answerEdits} onChange={(e) => setAnswerEdits(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button style={{textTransform: 'none'}} variant='contained' onClick={() => handleEditAnswer()}>
                        {isSendingAnswerEdits ?
                        <CircularProgress size={20} style={{color: 'white'}} /> :
                        ('Save Answer')
                        }
                    </Button>
                </DialogActions>
            </Dialog>

        </TableRow>
    )
}

export default QAndAAdminTableRow