import axios, { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'

// interfaces
import { ApiRes } from '../models/Api'
import { IInstructorQuestion } from '../models/InstructorQuestion'
import { IStudentCourse, IStudentCourseSection } from '../models/StudentCourse'

let baseURL: string
if (process.env.REACT_APP_API_BASE_URL) {
    baseURL = process.env.REACT_APP_API_BASE_URL + 'StudentCourses/'
} else {
    baseURL = '/Api/StudentCourses/'
}
const API = axios.create({ baseURL: baseURL })
if (localStorage.getItem('authToken') != null) {
    API.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('authToken')
}

// ===== admin routes =====

const adminAssignStudentCourse = async (userId: number, courseId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/AssignStudentCourse', qs.stringify({
            userId: userId,
            courseId: courseId
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminDeleteQuestion = async (instructorQuestionId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.delete('Admin/DeleteQuestion/' + instructorQuestionId, config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminGetAllQuestions = async (): Promise<ApiRes<Array<IInstructorQuestion>>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Admin/GetAllQuestions', config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminGetStudentCourses = async (userId: number): Promise<ApiRes<Array<IStudentCourse>>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Admin/GetStudentCourses/' + userId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminUnassignStudentCourse = async (studentCourseId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.delete('Admin/DeleteStudentCourse/' + studentCourseId, config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminUpdateInstructorAnswer = async (instructorQuestionId: number, answer: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/UpdateInstructorAnswer', qs.stringify({
            instructorQuestionId: instructorQuestionId,
            answer: answer
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

// ===== general routes =====

const getCourseQuestions = async (studentCourseId: number): Promise<ApiRes<Array<IInstructorQuestion>>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetCourseQuestions/' + studentCourseId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const getSectionQuestions = async (studentCourseSectionId: number): Promise<ApiRes<Array<IInstructorQuestion>>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetSectionQuestions/' + studentCourseSectionId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const getStudentCourse = async (courseId: number): Promise<ApiRes<IStudentCourse>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetStudentCourse/' + courseId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const getStudentCourses = async (): Promise<ApiRes<Array<IStudentCourse>>> => {
    // need to set Authorization header manually just for this request, since it is right after login
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetStudentCourses', config)
        return res
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const getStudentSection = async (sectionId: string): Promise<ApiRes<IStudentCourseSection>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetStudentSection/' + sectionId, config)
        return res
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const submitQuestion = async (studentCourseSectionId: number, question: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('SubmitQuestion', qs.stringify({
            studentCourseSectionId: studentCourseSectionId,
            question: question
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const updateUserCurrentSection = async (studentCourseId: number, currentSectionId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('UpdateUserCurrentSection', qs.stringify({
            studentCourseId: studentCourseId,
            currentSectionId: currentSectionId
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const updateVideoPosition = async (studentComponentEmbedId: number, videoPosition: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('UpdateVideoPosition', qs.stringify({
            studentComponentEmbedId: studentComponentEmbedId,
            videoPosition: videoPosition
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const StudentCoursesService = {

    // === admin routes ===
    adminAssignStudentCourse,
    adminDeleteQuestion,
    adminGetAllQuestions,
    adminGetStudentCourses,
    adminUnassignStudentCourse,
    adminUpdateInstructorAnswer,

    // === general routes ===
    getCourseQuestions,
    getSectionQuestions,
    getStudentCourse,
    getStudentCourses,
    getStudentSection,
    submitQuestion,
    updateUserCurrentSection,
    updateVideoPosition,
}

export default StudentCoursesService