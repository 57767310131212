/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Link,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'


import NavBar from './shared/NavBar'
import Footer from './shared/Footer'

import bannerImg from '../assets/imgs/banner-v5.jpg'
import accetImg from '../assets/imgs/accet.png'
import iibaImg from '../assets/imgs/iiba.png'
import { courseCats } from './courseCats'
import {
    Check,
    Phone as PhoneIcon,
    Email as EmailIcon
} from '@mui/icons-material'
import { PropsWithChildren, ReactNode } from 'react'
import { Fragment } from 'react'

const welcomeText = 'Welcome to Inteq.edu learning management and training platform. The Inteq.edu learning management and training platform enables you to track your learning journey (Live training and Anytime eLearning™ courses), applicable continuing education credits (PDUs, CEUs, etc.), digital badges and Specialist Certificates. The Inteq.edu platform also enables you to access your Anytime eLearning™ training courses.'

interface ChartTypProps {
    normalWeight?: boolean
    italic?: Boolean
}

const ChartTyp = ({
    children,
    normalWeight,
    italic
}: PropsWithChildren<ChartTypProps>) =>
    <Typography variant='body2' sx={{
        textAlign: 'center',
        lineHeight: '16px',
        color: 'primary.main',
        fontWeight: normalWeight ? 'normal' : 'bold',
        fontSize: '13px',
        fontStyle: italic ? 'italic' : 'normal',
        width: '100%'
    }}>
        {children}
    </Typography>

interface SideCardProps {
    title: string
    children: ReactNode
}

const SideCard = ({
    title,
    children
}: SideCardProps) =>
    <Paper elevation={3} sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '25px',
        overflow: 'hidden'
    }}>
        <Box sx={{
            backgroundColor: '#e8e8e8',
            padding: '8px 20px',
            boxSizing: 'border-box'
        }}>
            <Typography sx={{
                fontWeight: 'bold'
            }}>
                {title}
            </Typography>
        </Box>
        {children}
    </Paper>

const Home = () => {

    const theme = useTheme()

    return (
        <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <NavBar />
                <img src={bannerImg} alt='' style={{ width: '100%', objectFit: 'cover' }} />
                <Container maxWidth='xl' sx={{ display: 'flex', padding: '30px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={9.5} xl={9.5} sx={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                            <Paper elevation={0} sx={{
                                boxSizing: 'border-box',
                                padding: '20px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '25px'
                            }}>
                                <Typography>{welcomeText}</Typography>
                            </Paper>
                            <Box sx={{ height: '30px' }} />
                            <Box sx={{ overflowX: 'scroll', boxSizing: 'border-box' }}>
                                <Grid container sx={{ boxSizing: 'border-box', maxWidth: '99%', minWidth: '900px' }}>
                                    <Grid item xs={8.2}>
                                        <Typography variant='h5' sx={{
                                            color: theme.palette.primary.main,
                                            width: '100%',
                                            textAlign: 'center',
                                            padding: '30px 0px'
                                        }}>
                                            Inteq Training Courses and Certificate Programs
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3.8}>
                                        <Box sx={{ display: 'flex', width: '100%', height: '100%', border: '1px solid black' }}>
                                            <Box sx={{ width: '25%', height: '100%', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
                                                <Box sx={{ height: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <ChartTyp italic>
                                                        Course
                                                        <br />
                                                        Hours*
                                                    </ChartTyp>
                                                </Box>
                                            </Box>
                                            <Box sx={{ height: '100%', width: '1px', backgroundColor: 'black' }} />
                                            <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '40%', display: 'flex', alignItems: 'center' }}>
                                                    <ChartTyp>
                                                        Individual Options
                                                    </ChartTyp>
                                                </Box>
                                                <Box sx={{ backgroundColor: 'black', width: '100%', height: '1px' }} />
                                                <Box sx={{ height: '60%', display: 'flex', alignItems: 'center' }}>
                                                    <ChartTyp italic>
                                                        Anytime eLearning
                                                    </ChartTyp>
                                                </Box>
                                            </Box>
                                            <Box sx={{ height: '100%', width: '1px', backgroundColor: 'black' }} />
                                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '40%' }}>
                                                    <ChartTyp>Team Options</ChartTyp>
                                                    <ChartTyp normalWeight>
                                                        for Private Groups
                                                    </ChartTyp>
                                                </Box>
                                                <Box sx={{ backgroundColor: 'black', width: '100%', height: '1px' }} />
                                                <Box sx={{ height: '60%', display: 'flex' }}>
                                                    <Box sx={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                                        <ChartTyp italic>
                                                            Live
                                                            <br />
                                                            Virtual
                                                            <br />
                                                            Classroom
                                                        </ChartTyp>
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '1px', backgroundColor: 'black' }} />
                                                    <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <ChartTyp italic>
                                                            Live
                                                            <br />
                                                            Onsite
                                                        </ChartTyp>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {courseCats.map(cat =>
                                        <Fragment key={cat.title}>
                                            <Grid item xs={12}>
                                                <Typography variant='h6'>
                                                    {cat.title}
                                                </Typography>
                                                <Divider sx={{ width: '100%', backgroundColor: 'black' }} />
                                            </Grid>
                                            <Grid item xs={12} sx={{ height: '7px' }} />
                                            {cat.courses.map(c =>
                                                <Fragment key={c.title}>
                                                    <Grid item xs={8.2}>
                                                        <Typography sx={{ lineHeight: '28px' }}>
                                                            <Link href={c.link} target='_blank' style={{
                                                                color: theme.palette.primary.main,
                                                                fontStyle: 'italic',
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {c.title}
                                                            </Link>
                                                            <span style={{
                                                                color: theme.palette.primary.main,
                                                                fontStyle: 'italic',
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {c.description && ': '}
                                                            </span>
                                                            <span style={{ marginLeft: '5px' }}>
                                                                {c.description}
                                                            </span>
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={0.95} sx={{ display: 'flex' }}>
                                                        <Typography textAlign='center' sx={{ width: '100%' }}>
                                                            {c.courseHours}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={0.95}>
                                                        {c.anytimeElearing === true &&
                                                            <Check sx={{
                                                                color: theme.palette.primary.main,
                                                                width: '100%'
                                                            }} />
                                                        }
                                                        {c.anytimeElearing === '**' &&
                                                            <Typography sx={{
                                                                width: '100%',
                                                                textAlign: 'center'
                                                            }}>
                                                                **
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={0.95}>
                                                        <Check sx={{
                                                            color: theme.palette.primary.main,
                                                            width: '100%'
                                                        }} />
                                                    </Grid>
                                                    <Grid item xs={0.95}>
                                                        <Check sx={{
                                                            color: theme.palette.primary.main,
                                                            width: '100%'
                                                        }} />
                                                    </Grid>
                                                </Fragment>
                                            )}
                                            <Grid item xs={12} sx={{ height: '15px' }} />
                                        </Fragment>
                                    )}
                                </Grid>
                            </Box>
                            <Box sx={{ height: '20px' }} />
                            <Typography variant='body2'>
                                * Course hours include instructor presentation, exercises,
                                case studies, Q&A, etc. For example, a 16 hour course via Inteq's
                                Anytime eLearning™ training platform (inteq.edu) is typically
                                completed by participants on their own schedule over 1-2 weeks.
                                A 16 hour live course is typically scheduled over two consecutive
                                days of approximately 8 hours/day. A 64 hour (equivalent of 8 class days)
                                Certificate Program is typically completed by participants on
                                their own scheudle via Inteq's Anytime eLearning™ training platform
                                (inteq.edu) over 2-3 months. Please contact us to discuss team
                                training options.
                            </Typography>
                            <Box sx={{ height: '20px' }} />
                            <Typography variant='body2'>
                                ** The following courses are available for Anytime eLearning™ in 2024
                                starting on the following dates: Business Process Reengineering (May 21);
                                Developing Professional Business Cases (May 28); Business Data Analytics
                                (June 11); Business Relationship Management (June 18).
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5} sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <SideCard title='Certifying Bodies'>
                                <Box sx={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                                    <img src={accetImg} alt='ACCET' style={{ width: '100%', objectFit: 'contain' }} />
                                    <Box sx={{ height: '10px' }} />
                                    <Typography>
                                        Inteq is an
                                        <span> </span>
                                        <Link href='https://accet.org' target='_blank'>
                                            ACCET
                                        </Link>
                                        <span> </span>
                                        accredited continuing education and training
                                        institution. The Accrediting Council for
                                        Continuing Education & Training (ACCET) is
                                        recognized by the U.S. Department of Education
                                        as a reliable authority on educational
                                        quality. ACCET strives to identify, evaluate,
                                        and enhance the delivery of continuing education
                                        and training.
                                    </Typography>
                                    <Box sx={{ height: '20px' }} />
                                    <img src={iibaImg} alt='IIBA' style={{ width: '100%', objectFit: 'contain', marginLeft: '-10px' }} />
                                    <Box sx={{ height: '10px' }} />
                                    <Typography>
                                        Inteq is an
                                        <span> </span>
                                        <Link href='https://www.iiba.org' target='_blank'>
                                            IIBA
                                        </Link>
                                        <span> </span>
                                        (International Institute of Business Analysis
                                        endorsed education provider). The courses that
                                        comprise the Business Systems Analysis Specialist
                                        certificate program are meticulously aligned
                                        with the IIBA Business Analysis Body of
                                        Knowledge (BABOK®) and are eligible of IIBA
                                        Professional Development Units (PDUs).
                                    </Typography>
                                </Box>
                            </SideCard>
                            <Box sx={{
                                height: '20px',
                                width: '100px'
                            }} />
                            <SideCard title='Questions'>
                                <Box sx={{
                                    boxSizing: '20px',
                                    padding: '20px'
                                }}>
                                    <Typography>
                                        Please email us at
                                        <span> </span>
                                        <Link href='mailto:info@inteqgroup.com'>
                                            info@inteqgroup.com
                                        </Link>
                                        <span> </span>
                                        or call at
                                        <span> </span>
                                        <Link href='tel:8007194627'>
                                            800.719.4627
                                        </Link>
                                        . We are happy to discuss your
                                        questions in more detail.
                                    </Typography>
                                    <Box sx={{ height: '20px' }} />
                                    <Button disableElevation variant='outlined' href='tel:8007194627'>
                                        <PhoneIcon />
                                        <Box sx={{ width: '10px' }} />
                                        Call us
                                    </Button>
                                    <Box sx={{ height: '20px' }} />
                                    <Button disableElevation variant='outlined' href='mailto:info@inteqgroup.com'>
                                        <EmailIcon />
                                        <Box sx={{ width: '10px' }} />
                                        Email us
                                    </Button>
                                </Box>
                            </SideCard>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </div>
    )
}

export default Home