/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Menu,
    MenuItem,
    Slider,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material'

// interfaces
import { IRowCountMenuProps } from './shared'

interface IRowCountMenuPropsExtended extends IRowCountMenuProps {
    anchor: HTMLElement | null
    setAnchor: (newAnchor: HTMLElement | null) => void
}
const RowCountMenu = (props: IRowCountMenuPropsExtended) => {

    const marks = [
        {
            value: 0,
            label: 10
        },
        {
            value: 20,
            label: 25
        },
        {
            value: 40,
            label: 50
        },
        {
            value: 60,
            label: 100
        },
        {
            value: 80,
            label: 250
        },
        {
            value: 100,
            label: 500
        },
    ]

    const handleChangeRowCount = (newValue: number) => {
        // find value in marks
        for (let i=0; i<marks.length; ++i) {
            const mark = marks[i]
            if (mark.value === newValue) {
                props.setRowCount(mark.label)
                return
            }
        }
    }

    const labelToValue = (label: number): number => {
        // find label in marks
        for (let i=0; i<marks.length; ++i) {
            const mark = marks[i]
            if (mark.label === label) {
                return mark.value
            }
        }
        return 0
    }

    return (
        <Menu
            open={props.anchor !== null} onClose={() => props.setAnchor(null)}
            transformOrigin={{vertical: 'top', horizontal: 'left'}} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            anchorEl={props.anchor}
        >
            <div style={{display: 'flex', marginLeft: '20px', marginRight: '20px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center'}}>
                <Typography style={{color: 'gray', fontSize: 14}}>Row Count</Typography>
                <div style={{flexGrow: 1}} />
                <Tooltip title='The max number of rows displayed to the table. Higher numbers induce more lag.' placement='right-end'>
                    <QuestionMarkIcon style={{color: 'gray', fontSize: 14}} />
                </Tooltip>
            </div>
            <div style={{width: '200px', paddingLeft: '20px', paddingRight: '20px'}}>
                <Slider
                    style={{width: '100%'}}
                    value={labelToValue(props.rowCount)}
                    marks={marks}
                    step={null}
                    onChange={(e, newValue) => handleChangeRowCount(newValue as number)}
                />
            </div>
        </Menu>
    )
}

export default RowCountMenu