/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, MouseEvent, useState } from 'react'
import {
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper as MuiPaper,
    styled,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    Add as AddIcon,
    FilterAlt as FilterAltIcon,
    FormatLineSpacing as FormatLineSpacingIcon,
    ViewColumn as ViewColumnIcon,
} from '@mui/icons-material'

// components
import AdminTableSearch from './AdminTableSearch'
import SearchFilterMenu from './SearchFilterMenu'
import RowCountMenu from './RowCountMenu'
import SelectColumnsMenu from './SelectColumnsMenu'

// interfaces
import { IRowCountMenuProps, ISearchFilterMenuProps, ISelectColumnsMenuProps } from './shared'

// mui
const Paper = styled(MuiPaper)(({theme}) => ({
}))
const LineTop = styled('div')(({theme}) => ({
    width: '100%',
    paddingTop: '5px',
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
}))
const LineBottom = styled('div')(({theme}) => ({
    width: '100%',
    paddingBottom: '5px',
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex',
    boxSizing: 'border-box',
}))

interface IAdminTableToolbarProps {
    isNewDialogOpen?: boolean
    newDialog?: JSX.Element
    newEntryTooltip?: string
    refreshTable: (setIsLoadingFalseOnRefresh: boolean) => void
    entityName: string
    setIsLoadingTable: (newValue: boolean) => void
    setIsNewDialogOpen?: (newValue: boolean) => void
    setSearchValue: (newValue: string) => void

    // select columns
    selectColumnsMenuProps: ISelectColumnsMenuProps

    // select search filter
    searchFilterMenuProps: ISearchFilterMenuProps

    // row count
    rowCountMenuProps: IRowCountMenuProps

    currentNumFiltered: number
    totalNum: number
}
const AdminTableToolbar = (props: IAdminTableToolbarProps) => {

    // get device width
    const theme = useTheme()
    const isDeviceXs = useMediaQuery(theme.breakpoints.only('xs'))
    const isDeviceSm = useMediaQuery(theme.breakpoints.only('sm'))
    const isDeviceMd = useMediaQuery(theme.breakpoints.only('md'))
    const isDeviceLg = useMediaQuery(theme.breakpoints.only('lg'))
    const isDeviceXl = useMediaQuery(theme.breakpoints.only('xl'))

    // ===== searchFilterMenu =====
    const [searchFilterMenuAnchor, setSearchFilterMenuAnchor] = useState<HTMLElement | null>(null)

    // ===== selectColumnsMenu =====
    const [selectColumnsMenuAnchor, setSelectColumnsMenuAnchor] = useState<HTMLElement | null>(null)

    // ===== rowCountMenu =====
    const [rowCountMenuAnchor, setRowCountMenuAnchor] = useState<HTMLElement | null>(null)

    return (
        <Paper>

            <LineTop>
                {/* Search */}
                {(isDeviceXs || isDeviceSm) &&
                <div style={{width: '100%'}}>
                    <AdminTableSearch setSearchValue={props.setSearchValue} setIsLoadingTable={props.setIsLoadingTable} label={props.entityName + 's...'} />
                </div>
                }
                {isDeviceMd &&
                <div style={{width: '50%'}}>
                    <AdminTableSearch setSearchValue={props.setSearchValue} setIsLoadingTable={props.setIsLoadingTable} label={props.entityName + 's...'} />
                </div>
                }
                {(isDeviceLg || isDeviceXl) &&
                <div style={{width: '40%'}}>
                    <AdminTableSearch setSearchValue={props.setSearchValue} setIsLoadingTable={props.setIsLoadingTable} label={props.entityName + 's...'} />
                </div>
                }
                <Tooltip title='Search Filters'>
                    <IconButton style={{marginLeft: '5px'}} onClick={(e) => setSearchFilterMenuAnchor(e.currentTarget)}>
                        <FilterAltIcon />
                    </IconButton>
                </Tooltip>
                <SearchFilterMenu
                    labels={props.searchFilterMenuProps.labels}
                    states={props.searchFilterMenuProps.states}
                    setStates={props.searchFilterMenuProps.setStates}
                    anchor={searchFilterMenuAnchor}
                    setAnchor={setSearchFilterMenuAnchor}
                />

                <Divider orientation='vertical' style={{height: '40px', marginLeft: '10px', marginRight: '10px'}} />

                {/* Main Tools */}
                <div style={{display: 'flex', height: '100%'}}>

                    {/* Select Columns */}
                    <div>
                        <Tooltip title='Columns'>
                            <IconButton onClick={(e) => setSelectColumnsMenuAnchor!(e.currentTarget)}>
                                <ViewColumnIcon />
                            </IconButton>
                        </Tooltip>
                        <SelectColumnsMenu
                            labels={props.selectColumnsMenuProps.labels}
                            states={props.selectColumnsMenuProps.states}
                            setStates={props.selectColumnsMenuProps.setStates}
                            anchor={selectColumnsMenuAnchor}
                            setAnchor={setSelectColumnsMenuAnchor}
                        />
                    </div>

                    {/* Number of Rows */}
                    <div>
                        <Tooltip title='Row Count'>
                            <IconButton onClick={(e) => setRowCountMenuAnchor(e.currentTarget)}>
                                <FormatLineSpacingIcon />
                            </IconButton>
                        </Tooltip>
                        <RowCountMenu
                            rowCount={props.rowCountMenuProps.rowCount}
                            setRowCount={props.rowCountMenuProps.setRowCount}
                            anchor={rowCountMenuAnchor}
                            setAnchor={setRowCountMenuAnchor}
                        />
                    </div>

                </div>

                <div style={{flexGrow: 1}} />

                {/* New Entry */}
                {props.newDialog &&
                <div style={{marginTop: '5px', marginBottom: '5px'}}>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <Tooltip title={props.newEntryTooltip}>
                            <IconButton onClick={() => props.setIsNewDialogOpen!(true)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {props.newDialog}
                </div>
                }
            </LineTop>

            <LineBottom>
                <Typography fontSize={14} color='gray'>{props.currentNumFiltered + ' / ' + props.totalNum + ' ' + props.entityName + 's'}</Typography>
            </LineBottom>

        </Paper>
    )
}

export default AdminTableToolbar