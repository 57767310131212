/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    InputAdornment,
    OutlinedInput,
    styled,
    TextField,
    Typography,
} from '@mui/material'
import {
    Person as PersonIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

// components
import NavBar from './shared/NavBar'
import Header from './shared/Header'
import Footer from './shared/Footer'

// interfaces
import { ApiRes } from '../models/Api'

// services
import UsersService from '../services/UsersService'

// MUI vars
const ForgotPassword = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const ForgotPasswordLink = styled(Link)(({theme}) => ({
    textDecorationColor: theme.palette.primary.main,
}))
const LoginContainer = styled(Container)(({theme}) => ({
    color: theme.palette.primary.main,
    marginTop: '50px',
}))

const Login = () => {

    // navigate
    const navigate = useNavigate()

    // login data
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [rememberMe, setRememberMe] = React.useState(false)

    // change email
    const handleChangeEmail = (newValue: any) => {
        if (incorrectUsernameOrPassword) {
            setIncorrectUsernameOrPassword(false)
        }
        setEmail(newValue)
    }

    // change email
    const handleChangePassword = (newValue: any) => {
        if (incorrectUsernameOrPassword) {
            setIncorrectUsernameOrPassword(false)
        }
        setPassword(newValue)
    }

    // submit login data
    const [isLoadingLogin, setIsLoadingLogin] = React.useState(false)
    const handleSubmit = async () => {
        // set loading
        setIsLoadingLogin(true)
        setIncorrectUsernameOrPassword(false)
        // login
        const res: ApiRes<void> = await UsersService.login(email, password, rememberMe)
        // if success, navigate to dash
        if (res.status === 200) {
            navigate('/learn')
        }
        if (res.error) {
            setIncorrectUsernameOrPassword(true)
        }
        // set loading false
        setIsLoadingLogin(false)
    }

    // login components state
    const [isPasswordShowing, setIsPasswordShowing] = React.useState(false)
    const [incorrectUsernameOrPassword, setIncorrectUsernameOrPassword] = React.useState(false)

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Login' />

            {/* Login */}
            <LoginContainer maxWidth='xs'>
                <Typography variant='h6'>Login to your account</Typography>
                <form onSubmit={handleSubmit}>
                    <Box style={{display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '20px'}}>
                        <TextField label='Email' value={email} onChange={(e) => handleChangeEmail(e.target.value)} required
                            error={incorrectUsernameOrPassword}
                            helperText={incorrectUsernameOrPassword ? 'Incorrect username or password.' : null}
                        />
                        <div style={{height: '20px'}} />
                        <TextField label='Password' value={password} onChange={(e) => handleChangePassword(e.target.value)}
                            error={incorrectUsernameOrPassword}
                            helperText={incorrectUsernameOrPassword ? 'Incorrect username or password.' : null}
                            required type={isPasswordShowing ? 'text' : 'password'}
                            InputProps={{endAdornment:
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setIsPasswordShowing(!isPasswordShowing)}>
                                        {isPasswordShowing ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                        <div style={{height: '15px'}} />
                        <div style={{display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center'}}>
                            <Typography>Remember me</Typography>
                            <Checkbox checked={rememberMe} onChange={(e, newValue) => setRememberMe(newValue)} />
                        </div>
                        <Button style={{textTransform: 'none'}} variant='outlined' onClick={handleSubmit}>
                            {isLoadingLogin ?
                            <CircularProgress size={24} />
                            : <Typography>Login</Typography>
                            }
                        </Button>
                        <div style={{height: '15px'}} />
                    </Box>
                </form>
                {/* disable forgot password for now */}
                {/* <ForgotPasswordLink to='forgotPassword'>
                    <ForgotPassword>Forgot password?</ForgotPassword>
                </ForgotPasswordLink> */}
            </LoginContainer>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default Login