/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
    Container,
} from '@mui/material'

// components
import NavBar from '../../shared/NavBar'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import CoursesAdminTable from './CoursesAdminTable/CoursesAdminTable'

const CoursesAdmin = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Admin Portal' isAdmin adminLocation='Manage Courses' />

            {/* Admin Portal */}
            <div style={{marginTop: '20px', marginBottom: '40px'}}>
                <CoursesAdminTable />
            </div>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default CoursesAdmin