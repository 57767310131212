/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    styled,
    TextField,
    Typography
} from '@mui/material'
import {
    Clear as ClearIcon,
    Send as SendIcon,
} from '@mui/icons-material'

// interfaces
import { IInstructorQuestion } from '../../models/InstructorQuestion'
import { ApiRes } from '../../models/Api'

// services
import CoursesService from '../../services/CoursesService'
import StudentCoursesService from '../../services/StudentCoursesService'

 // mui
 const ACard = styled(Card)(({theme}) => ({
    borderRadius: 25,
}))
const Credit = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const QCard = styled(Card)(({theme}) => ({
    borderRadius: 25,
    backgroundColor: theme.palette.primary.light,
}))
const QATypography = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}))

interface TabContentQAProps {
    studentCourseSectionId: number | undefined
    studentCourseId: number | undefined
}
const TabContentQA = (props: TabContentQAProps) => {

    // ===== questions =====
    // get questions
    const [questions, setQuestions] = useState<Array<IInstructorQuestion>>()
    const [areQuestionsLoading, setAreQuestionsLoading] = useState(false)
    const refreshQuestions = useCallback(async () => {
        if (props.studentCourseId === undefined) {
            return
        }
        // set areQuestionsLoading
        setAreQuestionsLoading(true)
        const res: ApiRes<Array<IInstructorQuestion>> = await StudentCoursesService.getCourseQuestions(props.studentCourseId)
        if (res.data != null) {
            setQuestions(res.data)
            setAreQuestionsLoading(false)
        }
    }, [props.studentCourseId])
    // refresh on studentCourse refresh
    useEffect(() => {
        refreshQuestions()
    }, [refreshQuestions])

    // ===== newQuestion ======
    const [newQuestion, setNewQuestion] = useState('')
    const [isSubmittingQuestion, setIsSubmittingQuestion] = useState(false)
    const handleSubmitQuestion = async () => {
        if (newQuestion === '' || props.studentCourseSectionId === undefined) {
            return
        }
        setIsSubmittingQuestion(true)
        console.log(props.studentCourseSectionId)
        const res: ApiRes<void> = await StudentCoursesService.submitQuestion(props.studentCourseSectionId, newQuestion)
        setIsSubmittingQuestion(false)
        setNewQuestion('')
        refreshQuestions()
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%', maxHeight: '100%'}}>
            <List 
                style={{width: '100%', minHeight: '100%', padding: 0, overflow: 'scroll', boxSizing: 'border-box', paddingLeft: '15px', paddingRight: '20px', paddingTop: '15px'}}
            >
                {questions && questions.map((question, index) =>
                <ListItem key={index} style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'start', padding: 0, marginBottom: '20px'}}>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'end'}}>
                        <QCard elevation={3} style={{maxWidth: '70%'}}>
                            <CardContent
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, padding: '7.5px', paddingLeft: '15px', paddingRight: '15px'}}
                            >
                                <Typography>{question.question}</Typography>
                            </CardContent>
                        </QCard>
                    </div>
                    <div style={{height: '15px'}} />
                    {question.instructorAnswer &&
                    <div style={{display: 'flex', width: '100%', justifyContent: 'start'}}>
                        <ACard elevation={3} style={{maxWidth: '70%'}}>
                            <CardContent
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, padding: '7.5px', paddingLeft: '15px', paddingRight: '15px'}}
                            >
                                <Typography style={{marginLeft: '5px'}}>{question.instructorAnswer ? question.instructorAnswer.answer : ''}</Typography>
                            </CardContent>
                        </ACard>
                    </div>
                    }
                </ListItem>
                )}
            </List>
            <div style={{flexGrow: 1}} />
            <Divider />
            <div style={{display: 'flex', alignItems: 'center', marginTop: '0px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', paddingTop:' 5px'}}>
                <TextField value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)}
                    variant='standard' label='Ask...' style={{flexGrow: 1}} multiline
                />
                <IconButton style={{marginLeft: '5px', marginTop: '7.5px', height: '40px', width: '40px'}} onClick={handleSubmitQuestion}>
                    {isSubmittingQuestion ?
                    <CircularProgress size={20} /> :
                    <SendIcon />
                    }
                </IconButton>
            </div>
        </div>
    )
}

export default TabContentQA