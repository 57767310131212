/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    styled,
    Typography,
} from '@mui/material'
import {
    Delete as DeleteIcon,
    Description as DescriptionIcon,
    SwapVert as SwapVertIcon,
    Title as TitleIcon,
} from '@mui/icons-material'

// components
import SummedSubEditCard from './SummedSubEditCard'

// interfaces
import { EditType, ISummedEdit } from './shared'
import { ICourseSectionModule } from '../../../../models/Course'

// mui
const AddedTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold',
}))
const ColorBoldTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}))
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const DeletedTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold',
}))
const EditCard = styled(Card)(({theme}) => ({
    width: '100%',
    marginBottom: '10px',
}))
const EditCardBox = styled(Box)(({theme}) => ({
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
}))
const EditCardBoxItems = styled(Box)(({theme}) => ({
    marginLeft: '5px'
}))
const IconHolder = styled(Box)(({theme}) => ({
    marginRight: '10px',
    color: theme.palette.primary.main
}))
const IconHolderDelete = styled(Box)(({theme}) => ({
    marginRight: '10px',
    color: theme.palette.error.main
}))
const SectionChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const SectionChipAdd = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const Sentence = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center'
}))

interface ISummedEditCardProps {
    summedEdit: ISummedEdit
}
const SummedEditCard = (props: ISummedEditCardProps) => {
    return (
        <EditCard>

            {/* CourseTitle */}
            {props.summedEdit.editType === EditType.CourseTitle &&
            <EditCardBox>
                <Typography>Changed course title to:</Typography>
                <ColorTyp style={{marginLeft: '15px'}}>{props.summedEdit.newValue}</ColorTyp>
            </EditCardBox>
            }

            {/* CourseDescription */}
            {props.summedEdit.editType === EditType.CourseDescription &&
            <EditCardBox>
                <Typography>Changed course description to:</Typography>
                <ColorTyp style={{marginLeft: '15px'}}>{props.summedEdit.newValue}</ColorTyp>
            </EditCardBox>
            }

            {/* CourseSection */}
            {props.summedEdit.editType === EditType.CourseSection &&
            <EditCardBox>
                <ColorBoldTyp>{props.summedEdit.oldValue}</ColorBoldTyp>
                {props.summedEdit.subEdits!.map((subEdit, subEditIndex) =>
                <EditCardBoxItems key={subEditIndex}>
                    {/* CourseSectionTitle */}
                    {subEdit.editType === EditType.CourseSectionTitle &&
                    <Sentence>
                        <IconHolder><TitleIcon/></IconHolder>
                        <div>
                            <Typography>Changed title to:</Typography>
                            <ColorTyp style={{marginLeft: '15px'}}>{subEdit.newValue}</ColorTyp>
                        </div>
                    </Sentence>
                    }
                    
                    {/* CourseSectionPosition */}
                    {subEdit.editType === EditType.CourseSectionPosition &&
                    <Sentence>
                        <IconHolder><SwapVertIcon /></IconHolder>
                        <Typography>Moved to position</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{subEdit.newValue}</ColorTyp>
                    </Sentence>
                    }

                    {/* CourseSectionModules */}
                    {(subEdit.editType === EditType.CourseSectionModule ||
                        subEdit.editType === EditType.CourseSectionModuleAdd ||
                        subEdit.editType === EditType.CourseSectionModuleDelete) &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {subEdit.editType === EditType.CourseSectionModule &&
                        <SectionChip size='small' label={(subEdit.editReference as ICourseSectionModule).sequenceId} />
                        }
                        {subEdit.editType === EditType.CourseSectionModuleAdd &&
                        <SectionChipAdd size='small' label={(subEdit.editReference as ICourseSectionModule).sequenceId} />
                        }
                        {subEdit.editType === EditType.CourseSectionModuleDelete &&
                        <IconHolderDelete><DeleteIcon /></IconHolderDelete>
                        }
                        <SummedSubEditCard summedSubEdit={subEdit} />
                    </div>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

            {/* CourseSectionAdd */}
            {props.summedEdit.editType === EditType.CourseSectionAdd &&
            <EditCardBox>
                <Sentence>
                    <AddedTyp>Added</AddedTyp>
                    <ColorBoldTyp style={{marginLeft: '5px'}}>{props.summedEdit.newValue}</ColorBoldTyp>
                </Sentence>
                {props.summedEdit.subEdits!.map((subEdit, subEditIndex) =>
                <EditCardBoxItems key={subEditIndex}>
                    {/* CourseSectionPosition */}
                    {subEdit.editType === EditType.CourseSectionPosition &&
                    <Sentence>
                        <IconHolder><SwapVertIcon /></IconHolder>
                        <Typography>At position</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{subEdit.newValue}</ColorTyp>
                    </Sentence>
                    }

                    {/* CourseSectionModuleAdd */}
                    {subEdit.editType === EditType.CourseSectionModuleAdd &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <SectionChipAdd size='small' label={(subEdit.editReference as ICourseSectionModule).sequenceId} />
                        <SummedSubEditCard summedSubEdit={subEdit} />
                    </div>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

            {/* CourseSectionDelete */}
            {props.summedEdit.editType === EditType.CourseSectionDelete &&
            <EditCardBox>
                <Sentence>
                    <DeletedTyp>Deleted</DeletedTyp>
                    <ColorBoldTyp style={{marginLeft: '5px'}}>{props.summedEdit.oldValue}</ColorBoldTyp>
                </Sentence>
                {props.summedEdit.subEdits!.map((subEdit, subEditIndex) =>
                <EditCardBoxItems key={subEditIndex}>
                    {/* CourseSectionModuleDeletes */}
                    {subEdit.editType === EditType.CourseSectionModuleDelete &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconHolderDelete><DeleteIcon /></IconHolderDelete>
                        <SummedSubEditCard summedSubEdit={subEdit} />
                    </div>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

            {/* CourseSectionTransparent */}
            {props.summedEdit.editType === EditType.CourseSectionTransparent &&
            <EditCardBox>
                <ColorBoldTyp>{props.summedEdit.oldValue}</ColorBoldTyp>
                {props.summedEdit.subEdits!.map((subEdit, subEditIndex) =>
                <EditCardBoxItems key={subEditIndex}>
                    {/* CourseSectionModules */}
                    {(subEdit.editType === EditType.CourseSectionModule ||
                        subEdit.editType === EditType.CourseSectionModuleAdd ||
                        subEdit.editType === EditType.CourseSectionModuleDelete) &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {subEdit.editType === EditType.CourseSectionModule &&
                        <SectionChip size='small' label={(subEdit.editReference as ICourseSectionModule).sequenceId} />
                        }
                        {subEdit.editType === EditType.CourseSectionModuleAdd &&
                        <SectionChipAdd size='small' label={(subEdit.editReference as ICourseSectionModule).sequenceId} />
                        }
                        {subEdit.editType === EditType.CourseSectionModuleDelete &&
                        <IconHolderDelete><DeleteIcon /></IconHolderDelete>
                        }
                        <SummedSubEditCard summedSubEdit={subEdit} />
                    </div>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

            </EditCard>
    )
}

export default SummedEditCard