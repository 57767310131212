import {
    Paper,
    styled,
    Typography,
} from '@mui/material'

// mui
const AdminHeadingPaper = styled(Paper)(({theme}) => ({
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    marginBottom: '15px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main
}))
const Title = styled(Typography)(({theme}) => ({
    color: 'white'
}))

interface IAdminHeadingProps {
    title: string
}
const AdminHeading = (props: IAdminHeadingProps) => {
    return (
        <AdminHeadingPaper>
            <Title variant='h5'>{props.title}</Title>
        </AdminHeadingPaper>
    )
}

export default AdminHeading