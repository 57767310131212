interface CourseDetails {
    title: string
    description?: string
    courseHours: number
    anytimeElearing: boolean | '**'
    liveVirtualClassroom: boolean
    liveOnsite: boolean
    link: string
}

interface CourseDetailsCategory {
    title: string
    courses: CourseDetails[]
}

export const courseCats: CourseDetailsCategory[] = [
    {
        title: 'Business Systems Analysis Courses',
        courses: [
            {
                title: 'Business Systems Analysis',
                description: 'Defining Business & User Requirements',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-systems-analysis-training-course'
            },
            {
                title: 'Agile Business Analysis',
                description: 'Mastering the Art of Agility - Better, Faster, Value Driven Requirements',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/agile-business-analysis-training-course'
            },
            {
                title: 'Logical Data Modeling',
                description: 'Ambiguity to Clarity - Mastering Data-Oriented Business Rules',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/logical-data-modeling-training-course'
            },
            {
                title: 'Advanced Data Modeling',
                description: 'Transformative Data Modeling for Enterprise Solutions',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/advanced-data-modeling-training-course'
            }
        ]
    },
    {
        title: 'Business Process Management Courses',
        courses: [
            {
                title: 'Business Process Modeling & Analysis',
                description: 'The Blueprint - from Process Mapping to Process Mastery',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-process-modeling-training'
            },
            {
                title: 'Business Process Reengineering',
                description: 'Reimagining Business Process Across the Enterprise',
                courseHours: 16,
                anytimeElearing: '**',
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-process-reengineering-training-course'
            },
            {
                title: 'Robotic Process Automation',
                description: 'Automating Processes & Procedures Across the Enterprise',
                courseHours: 16,
                anytimeElearing: false,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/robotic-process-automation-training-course'
            },
            {
                title: 'Business Data Analytics',
                description: 'Unlock Actionable Insights & Drive Smarter Decisions',
                courseHours: 16,
                anytimeElearing: '**',
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-data-analytics-training-course'
            }
        ]
    },
    {
        title: 'Power Skills Courses',
        courses: [
            {
                title: 'Soft Skills for Business Analysts',
                description: 'Enabling Better Faster Analysis',
                courseHours: 16,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/soft-skills-training-course'
            },
            {
                title: 'Business Relationship Management',
                description: 'Mastering BRM - Advancing to a Strategic Business Partner',
                courseHours: 16,
                anytimeElearing: '**',
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-relationship-management-training-course'
            },
            {
                title: 'Developing Professional Business Cases',
                description: 'The Ultimate Guide to Better Faster Business & IT Decisions',
                courseHours: 16,
                anytimeElearing: '**',
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/developing-effective-business-cases-training-course'
            },
            {
                title: 'Organizational Change Management',
                description: 'Mastering the X Factor in Business Transformation',
                courseHours: 16,
                anytimeElearing: false,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/change-management-training-course'
            }
        ],
    },
    {
        title: 'Certificate Programs',
        courses: [
            {
                title: 'Business Systems Analysis Specialist Certificate',
                courseHours: 64,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-systems-analysis-certificate'
            },
            {
                title: 'Business Process Management Specialist Certificate',
                courseHours: 64,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/business-process-management-certificate'
            },
            {
                title: 'Data Modeling and Analytics Specialist Certificate',
                courseHours: 64,
                anytimeElearing: true,
                liveVirtualClassroom: true,
                liveOnsite: true,
                link: 'https://www.inteqgroup.com/data-modeling-and-analytics-certificate'
            }
        ]
    }
]