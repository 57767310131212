/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
    Container,
    Divider,
    IconButton,
    styled,
    TextField as MuiTextField,
} from '@mui/material'
import {
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    Search as SearchIcon,
} from '@mui/icons-material'

// mui
const TextField = styled(MuiTextField)(({theme}) => ({
}))

interface IAdminTableSearchProps {
    setSearchValue: (newValue: string) => void
    setIsLoadingTable: (isLoadingTable: boolean) => void
    label: string
}
const AdminTableSearch = (props: IAdminTableSearchProps) => {

    // ===== search ======
    const [searchValue, setSearchValue] = useState('')
    const [hasChangedSearchValue, setHasChangedSearchValue] = useState(false)
    const handleChangeSearchValue = (newValue: string) => {

        setHasChangedSearchValue(true)

        // if newValue == '', set searchValue to '' and courseIndexesFiltered to default
        if (newValue === '') {
            setSearchValue('')
        }

        // else, set searchValue to newValue and filter
        else {
            setSearchValue(newValue)
        }
    }
    useEffect(() => {
        // if no changes have been made, return (avoids loading on initial render)
        if (!hasChangedSearchValue) {
            return
        }
        // start loading sequence
        props.setIsLoadingTable(true)
        const sendSearchValueToParent = setTimeout(() => {
            setHasChangedSearchValue(false)
            props.setSearchValue(searchValue)
        }, 500)
        return () => clearTimeout(sendSearchValueToParent)
    }, [searchValue, props])

    return (
        <div style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '5px', marginBottom: '5px', boxSizing: 'border-box'}}>
            <TextField variant='outlined' label={props.label} style={{width: '100%'}} size='small'
                value={searchValue} onChange={(newValue) => handleChangeSearchValue(newValue.target.value)}
                InputProps={{
                    endAdornment:
                        (searchValue !== '' ?
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <IconButton onClick={() => handleChangeSearchValue('')}>
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                                <SearchIcon style={{color: 'gray'}} />
                            </div> : <SearchIcon style={{color: 'gray'}} />
                        ),
                }}
            />
            {/* <IconButton style={{marginLeft: '5px'}}>
                <FilterAltIcon />
            </IconButton> */}
        </div>
    )
}

export default AdminTableSearch