/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
    Container,
} from '@mui/material'

// components
import NavBar from '../../shared/NavBar'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import QAndAAdminTable from './QAndAAdminTable'

const QAndAAdmin = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: '#F5F5F5', width: '100%'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Admin Portal' isAdmin adminLocation='Q&A' />

            {/* Admin Portal */}
            <div style={{marginTop: '20px', marginBottom: '40px', width: '100%'}}>
                <QAndAAdminTable />
            </div>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default QAndAAdmin