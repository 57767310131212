import {
    Typography,
} from '@mui/material'
import {
    Troubleshoot as TroubleshootIcon,
} from '@mui/icons-material'

interface INoSearchResultsProps {
    entityNamePlural: string
    searchQuery: string
}
const NoSearchResults = (props: INoSearchResultsProps) => {
    return (
        <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px'}}>
            <TroubleshootIcon style={{color: 'gray', marginRight: '7.5px'}} />
            <Typography color='gray'>{'No ' + props.entityNamePlural + ' matching "' + props.searchQuery + '"'}</Typography>
        </div>
    )
}

export default NoSearchResults