/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Breadcrumbs,
    Chip,
    Container,
    Divider,
    Link,
    Skeleton,
    styled,
    Typography,
} from '@mui/material'
import {
    Home as HomeIcon,
    MenuBook as MenuBookIcon,
    NavigateNext as NavigateNextIcon,
    Segment as SegmentIcon,
} from '@mui/icons-material'

const BreadcrumbChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
        cursor: 'pointer',
    }
}))
const BreadcrumbLink = styled(Link)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const BreadcrumbTypography = styled(Typography)(({theme}) => ({
    marginLeft: '5px'
}))
const BreadcrumbsBox = styled(Box)(({theme}) => ({
    color: theme.palette.primary.main,
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
}))

interface ICourseBreadcrumbsProps {
    courseTitle?: string | undefined
    courseId?: number | undefined
    sectionTitle?: string | undefined
    sectionNumber?: number | undefined
}
const CourseBreadcrumbs = (props: ICourseBreadcrumbsProps) => {

    // navigate
    const navigate = useNavigate()

    return (
        <BreadcrumbsBox>
            <Container maxWidth='lg' style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs separator={<NavigateNextIcon sx={{color: 'primary.main'}} />}>

                    {/* Courses Home */}
                    <BreadcrumbLink onClick={() => navigate('/learn')}>
                        <HomeIcon />
                        <BreadcrumbTypography>My Courses</BreadcrumbTypography>
                    </BreadcrumbLink>

                    {/* Course Title */}
                    {(props.courseId != null) ?
                        <BreadcrumbLink onClick={() => navigate('/learn/' + props.courseId)}>
                            <MenuBookIcon />
                            <BreadcrumbTypography>
                                {props.courseTitle ? props.courseTitle : <Skeleton />}
                            </BreadcrumbTypography>
                        </BreadcrumbLink> :
                        null
                    }

                    {/* Section Title */}
                    {(props.courseId != null && props.sectionTitle != null && props.sectionNumber != null) ?
                        <BreadcrumbLink>
                            <BreadcrumbChip size='small' label={props.sectionNumber} />
                            <BreadcrumbTypography>
                                {props.sectionTitle ? props.sectionTitle : <Skeleton /> }
                            </BreadcrumbTypography>
                        </BreadcrumbLink> :
                        null
                    }

                </Breadcrumbs>
            </Container>
        </BreadcrumbsBox>
    )
}

export default CourseBreadcrumbs