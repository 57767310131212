/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu as MuiMenu,
    MenuItem,
    MenuList,
    Skeleton,
    styled,
    Typography,
    useTheme
} from '@mui/material'
import {
    ContactSupport as ContactSupportIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Menu as MenuIcon,
    MenuBook as MenuBookIcon,
    Person as PersonIcon,
} from '@mui/icons-material'

const MenuButton = styled(Button)(({theme}) => ({
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark
    }
}))
const HeaderBox = styled(Box)(({theme}) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
}))

const Menu = styled(MuiMenu)(({theme}) => ({
    color: theme.palette.primary.main,
}))

interface IHeaderProps {
    title: string | undefined
    isAdmin?: boolean
    adminLocation?: string
}
const Header = (props: IHeaderProps) => {

    // ===== nagivate =====
    const navigate = useNavigate()

    // ==== theme =====
    const theme = useTheme()

    // ===== location =====
    const location = useLocation()
    let currAdminTab: number = -1
    switch (location.pathname) {
        case 'admin/users':
            currAdminTab = 0
            break
        case 'admin/courses':
            currAdminTab = 1
            break
        case 'admin/qAndA':
            currAdminTab = 2
            break
    }

    // ===== menu =====
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget)
        setIsMenuOpen(true)
    }
    const handleMenuClose = () => {
        setIsMenuOpen(false)
        setMenuAnchor(null)
    }

    return (
        <HeaderBox>
            <Container maxWidth='lg' style={{display: 'flex', alignItems: 'center', height: '100%'}}>

                {/* Header Title */}
                <Typography variant='h5'>
                    {props.title ?
                    props.title :
                    <Skeleton />
                    }
                </Typography>

                <div style={{flexGrow: 1}} />

                {/* Admin Menu */}
                {props.isAdmin ?
                <div>
                    <IconButton onClick={(e) => handleMenuOpen(e)}>
                        <MenuIcon style={{color: 'white'}} />
                    </IconButton>
                    <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={handleMenuClose}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                        <MenuList>
                            <MenuItem onClick={() => navigate('/admin/users')} selected={props.adminLocation === 'Manage Users'}>
                                <ListItemIcon>
                                    <PersonIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>
                                    Manage Users
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/admin/courses')} selected={props.adminLocation === 'Manage Courses'}>
                                <ListItemIcon>
                                    <MenuBookIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Manage Courses</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/admin/qAndA')} selected={props.adminLocation === 'Q&A'}>
                                <ListItemIcon>
                                    <ContactSupportIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Q&A</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div> : null
                }

            </Container>
        </HeaderBox>
    )
}

export default Header