/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    styled,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Clear as ClearIcon,
    Warning as WarningIcon,
} from '@mui/icons-material'

// interfaces
import { ApiRes } from '../../../../models/Api'
import { IStudentCourse } from '../../../../models/StudentCourse'
import { IUserProfile } from '../../../../models/User'

// services
import StudentCoursesService from '../../../../services/StudentCoursesService'

// mui
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold'
}))

interface IStudentCourseExtended extends IStudentCourse {
    courseTitle: string
    currentSectionNum: number
    courseTotalSectionsNum: number
}
interface IUsersAdminManageCoursesTableRowProps {
    studentCourse: IStudentCourseExtended
    userProfile: IUserProfile
    refreshStudentCourses: () => void
}
const UsersAdminManageCoursesTableRow = (props: IUsersAdminManageCoursesTableRowProps) => {

    // ===== isHovering =====
    const [isHovering, setIsHovering] = useState(false)

    // ===== isUnassignDialogOpen =====
    const [isUnassignDialogOpen, setIsUnassignDialogOpen] = useState(false)

    // ===== handleUnassignCourse =====
    const [isUnassigningStudentCourse, setIsUnassigningStudentCourse] = useState(false)
    const handleUnassignCourse = async () => {
        setIsUnassigningStudentCourse(true)
        const res: ApiRes<void> = await StudentCoursesService.adminUnassignStudentCourse(props.studentCourse.studentCourseId)
        setIsUnassignDialogOpen(false)
        setIsUnassigningStudentCourse(false)
        props.refreshStudentCourses()
    }

    return (
        <TableRow hover onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
            <TableCell>
                {props.studentCourse.courseTitle &&
                <Typography>{props.studentCourse.courseTitle}</Typography>
                }
            </TableCell>
            <TableCell>
                {props.studentCourse.isActive ?
                <div>
                    {props.studentCourse.courseStatus ?
                    <Typography color='primary.main'>IP</Typography> :
                    <Typography color='success.dark'>New</Typography>
                    }
                </div> :
                <Typography>N/A</Typography>
                }
            </TableCell>
            <TableCell>
                {props.studentCourse.currentSectionNum !== null && props.studentCourse.courseTotalSectionsNum !== null &&
                <Typography>{props.studentCourse.currentSectionNum + ' of ' + props.studentCourse.courseTotalSectionsNum}</Typography>
                }
            </TableCell>
            <TableCell>
                {props.studentCourse.isActive ?
                <Typography color='success.dark'>Yes</Typography> :
                <Typography color='error.dark'>No</Typography>
                }
            </TableCell>

            {/* Actions */}
            <TableCell style={{height: '50px', width: '40px'}}>
                {isHovering &&
                <div style={{display: 'flex'}}>
                    <Tooltip title='Unassign Course'>
                        <IconButton onClick={() => setIsUnassignDialogOpen(true)}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                }
            </TableCell>

            {/* Unassign Course Warning */}
            <Dialog open={isUnassignDialogOpen} onClose={() => setIsUnassignDialogOpen(false)} fullWidth maxWidth='sm'>
                <DialogContent>
                    <div style={{display: 'flex'}}>
                        <Typography>Unassign</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{props.studentCourse.courseTitle}</ColorTyp>
                        <Typography style={{marginLeft: '5px'}}>from</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{props.userProfile.firstName + ' ' + props.userProfile.lastName}</ColorTyp>
                        <Typography>?</Typography>
                    </div>
                    <div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
                        <WarningIcon fontSize='small' style={{color: 'gray'}} />
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '5px'}}>
                            {'This will delete all data associated with ' + props.userProfile.firstName + ' for ' + props.studentCourse.courseTitle + '.'}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <Button variant='contained' style={{textTransform: 'none'}} onClick={() => handleUnassignCourse()}>
                            {isUnassigningStudentCourse ?
                            <CircularProgress style={{color: 'white'}} size={24} /> :
                            ('Unassign')
                            }
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

        </TableRow>
    )
}

export default UsersAdminManageCoursesTableRow