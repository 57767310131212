/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography
} from '@mui/material'

// interfaces
import { ApiRes } from '../../../../models/Api'

// services
import UsersService from '../../../../services/UsersService'

// mui
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))

interface NewUserDialogProps {
    isOpen: boolean
    setIsNewUserDialogOpen: (isOpen: boolean) => void
    refreshUsers: (setIsLoadingUsersFalseOnRefresh: boolean) => void
}
const NewUserDialog = (props: NewUserDialogProps) => {

    const [newUserFirstName, setNewUserFirstName] = useState<string>('')
    const handleNewUserFirstName = (newValue: string) => {
        setNewUserFirstName(newValue)
    }
    const [newUserLastName, setNewUserLastName] = useState<string>('')
    const handleNewUserLastName = (newValue: string) => {
        setNewUserLastName(newValue)
    }
    const [newUserEmailAddress, setNewUserEmailAddress] = useState<string>('')
    const handleNewUserEmailAddress = (newValue: string) => {
        setNewUserEmailAddress(newValue)
    }
    const [newUserPassword, setNewUserPassword] = useState<string>('')
    const handleNewUserPassword = (newValue: string) => {
        setNewUserPassword(newValue)
    }
    const [newUserRole, setNewUserRole] = useState<number>(4)
    const handleNewUserRole = (newValue: number) => {
        if (newValue < 1 && newValue > 4) {
            return
        }
        setNewUserRole(newValue)
    }
    const [isNewUserSubmitting, setIsNewUserSubmitting] = useState(false)
    const [firstNameHelper, setFirstNameHelper] = useState<string | null>(null)
    const [lastNameHelper, setLastNameHelper] = useState<string | null>(null)
    const [emailHelper, setEmailHelper] = useState<string | null>(null)
    const [passwordHelper, setPasswordHelper] = useState<string | null>(null)
    const handleNewUserSubmit = async () => {
        setIsNewUserSubmitting(true)

        // reset validation flags
        setFirstNameHelper(null)
        setLastNameHelper(null)
        setEmailHelper(null)
        setPasswordHelper(null)

        let validationErrorsExist: boolean = false

        // ensure values are not blank
        if (newUserFirstName.length === 0) {
            setFirstNameHelper('First Name is required')
            validationErrorsExist = true
        }
        if (newUserLastName.length === 0) {
            setLastNameHelper('Last Name is required')
            validationErrorsExist = true
        }
        if (newUserEmailAddress.length === 0) {
            setEmailHelper('Email Address is required')
            validationErrorsExist = true
        }
        if (newUserPassword.length === 0) {
            setPasswordHelper('Password is required')
            validationErrorsExist = true
        }

        // validate email
        if (newUserEmailAddress.length > 0 && !newUserEmailAddress.includes('@')) {
            setEmailHelper('Invalid email address')
            validationErrorsExist = true
        }

        // if validation errors exist, return
        if (validationErrorsExist) {
            setIsNewUserSubmitting(false)
            return
        }

        const res: ApiRes<void> = await UsersService.adminInsertUser(newUserFirstName, newUserLastName, newUserEmailAddress, newUserRole, newUserPassword)
        setIsNewUserSubmitting(false)
        props.refreshUsers(true)
        props.setIsNewUserDialogOpen(false)
        setNewUserFirstName('')
        setNewUserLastName('')
        setNewUserEmailAddress('')
        setNewUserRole(4)
        setNewUserPassword('')
    }

    return (
        <Dialog open={props.isOpen} maxWidth='sm' fullWidth onClose={() => props.setIsNewUserDialogOpen(false)}>
            <DialogTitle>
                <ColorTyp style={{fontSize: 20}}>New User</ColorTyp>
            </DialogTitle>
            <DialogContent>
                <Divider />

                    <Grid container alignItems='center' spacing={3} style={{marginTop: '0px', marginBottom: '20px'}}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='First Name' value={newUserFirstName} helperText={firstNameHelper}
                                onChange={(e) => handleNewUserFirstName(e.target.value)} error={firstNameHelper !== null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Last Name' value={newUserLastName} helperText={lastNameHelper}
                            onChange={(e) => handleNewUserLastName(e.target.value)} error={lastNameHelper !== null}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Email Address' value={newUserEmailAddress} helperText={emailHelper}
                                onChange={(e) => handleNewUserEmailAddress(e.target.value)} error={emailHelper !== null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Password' value={newUserPassword} helperText={passwordHelper}
                                onChange={(e) => handleNewUserPassword(e.target.value)} error={passwordHelper !== null}
                            />
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl style={{marginTop: '20px', marginBottom: '20px', width: '100%'}}>
                                <InputLabel id='role-label'>Role</InputLabel>
                                <Select labelId='role-label' label='Role' value={newUserRole} onChange={(e) => handleNewUserRole(e.target.value as number)} fullWidth>
                                    <MenuItem value={1}>Administrator</MenuItem>
                                    <MenuItem value={2}>Operator</MenuItem>
                                    <MenuItem value={3}>Instructor</MenuItem>
                                    <MenuItem value={4}>Student</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider />
            </DialogContent>
            <DialogActions style={{paddingRight: '24px', paddingBottom: '24px', marginTop: '-20px'}}>
                <Button disabled={newUserFirstName==='' || newUserLastName==='' || newUserEmailAddress=='' || newUserPassword===''} 
                    variant='contained' style={{textTransform: 'none', marginTop: '10px'}} onClick={handleNewUserSubmit}
                >
                    {isNewUserSubmitting ?
                    <CircularProgress size={20} style={{color: 'white'}} /> :
                    <Typography style={{fontSize: 14}}>Create User</Typography>
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewUserDialog