/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Container,
    Divider,
    Grid,
    LinearProgress,
    styled,
    Typography,
} from '@mui/material'
import {
    DoubleArrow as DoubleArrowIcon,
} from '@mui/icons-material'

// components
import NavBar from './shared/NavBar'
import Header from './shared/Header'
import Footer from './shared/Footer'
import CourseBreadcrumbs from './Course/CourseBreadcrumbs'
import DashCourseCard from './DashCourseCard'

// imgs
import businessSystemsAnalysisCourseImg from '../assets/imgs/courses/business-systems-analysis.png'
import logicalDataModelingCourseImg from '../assets/imgs/courses/logical-data-modeling.png'

// interfaces
import { IStudentCourse } from '../models/StudentCourse'

// services
import StudentCoursesService from '../services/StudentCoursesService'
const CoursesTitleDiv = styled(Divider)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    marginBottom: '10px',
}))

const Dash = () => {

    // navigate
    const navigate = useNavigate()

    // get student courses
    const [studentCourses, setStudentCourses] = useState<Array<IStudentCourse>>()
    const [isLoadingStudentCourses, setIsLoadingStudentCourses] = useState(true)
    useEffect(() => {
        const getStudentCourses = async () => {
            const res: any = await StudentCoursesService.getStudentCourses()
            if (res.data !== undefined) {
                setStudentCourses(res.data)
                setIsLoadingStudentCourses(false)
            }
        }
        getStudentCourses()
    }, [])


    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='eLearning Home' />

            {/* Courses Breadcrumbs */}
            <CourseBreadcrumbs />

            {/* Courses */}
            <Container maxWidth='lg'>

                <Typography variant='h6' sx={{color: 'primary.main'}}>My Courses</Typography>
                {isLoadingStudentCourses ?
                <LinearProgress /> :
                <CoursesTitleDiv />
                }

                {isLoadingStudentCourses ?
                null :
                <Grid container spacing={3}>
                {studentCourses && studentCourses.map((studentCourse: any, index) =>
                <Grid item key={index} xs={12} sm={12} md={12} lg={6} xl={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <DashCourseCard studentCourse={studentCourse} />
                </Grid>
                )}
            </Grid>
                }

            </Container>

            <div style={{flexGrow: 1}} />

            {/* NavBar */}
            <Footer />

        </div>
    )
}

export default Dash