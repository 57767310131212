/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    IconButton,
    InputLabel,
    LinearProgress,
    List,
    ListItem,
    MenuItem,
    Select,
    Slide,
    styled,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead as MuiTableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ManageAccounts as ManageAccountsIcon,
    MenuBook as MenuBookIcon,
} from '@mui/icons-material'

// components
import UsersAdminManageCoursesTableRow from './UsersAdminManageCoursesTableRow'

// interfaces
import { ICourse } from '../../../../models/Course'
import { IStudentCourse } from '../../../../models/StudentCourse'
import { ApiRes } from '../../../../models/Api'
import { IUser, IUserProfile } from '../../../../models/User'
import { IWebpagesUsersInRoles } from '../../../../models/Webpages'

// services
import CoursesService from '../../../../services/CoursesService'
import StudentCoursesService from '../../../../services/StudentCoursesService'
import UsersService from '../../../../services/UsersService'

// mui
const ColorBoldTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold'
}))
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const IdCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
}))
const Table = styled(MuiTable)(({theme}) => ({
    backgroundColor: 'white',
}))
const TableHead = styled(MuiTableHead)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white'
}))
const TableHeadTyp = styled(Typography)(({theme}) => ({
    color: 'white'
}))

interface IUserExtended extends IUser {
    userRole: IWebpagesUsersInRoles
}
interface UsersAdminTableRowProps {
    user: IUserExtended
    refreshUsersCB: (setIsLoadingUsersFalseOnRefresh: boolean) => void
    isTableId: boolean
    isTableUserName: boolean
    isTableLastName: boolean
    isTableFirstName: boolean
    isTableEmailAddress: boolean
    isTableRole: boolean
}
const UsersAdminTableRow = (props: UsersAdminTableRowProps) => {

    // navigate
    const navigate = useNavigate()

    // ===== manage profile ======
    const [isManageProfileOpen, setIsManageProfileOpen] = useState(false)

    // ===== manage courses ======
    const [isManageCoursesOpen, setIsManageCoursesOpen] = useState(false)

    // ===== delete user =====
    const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false)
    const [isSendingDeleteUser, setIsSendingDeleteUser] = useState(false)
    const handleDeleteUser = async () => {
        setIsSendingDeleteUser(true)
        const res: ApiRes<void> = await UsersService.adminDeleteUser(props.user.userId)
        setIsSendingDeleteUser(false)
        if (res.error == null) {
            setIsDeleteUserOpen(false)
        }
        props.refreshUsersCB(true)
    }

    // ===== isHovering =====
    const [isHovering, setIsHovering] = useState(false)
    // setIsHover to false on dialog close
    useEffect(() => {
        setIsHovering(false)
    }, [isManageProfileOpen, isManageCoursesOpen])

    // valid roles
    const roleNames = ['Administrator', 'Operator', 'Instructor', 'Student']

    // edit user profile data
    const [editFirstName, setEditFirstName] = useState(props.user.userProfile && props.user.userProfile.firstName ? props.user.userProfile.firstName : '')
    const [editLastName, setEditLastName] = useState(props.user.userProfile && props.user.userProfile.lastName ? props.user.userProfile.lastName : '')
    const [editEmailAddress, setEditEmailAddress] = useState(props.user.userProfile && props.user.userProfile.emailAddress ? props.user.userProfile.emailAddress : '')
    const [editIsActive, setEditIsActive] = useState<boolean>(props.user.userProfile && props.user.userProfile.isActive ? true : false)
    const [editRole, setEditRole] = useState(props.user.userRole && props.user.userRole.roleId ? props.user.userRole.roleId : -1)
    const [editPassword, setEditPassword] = useState('')
    const [areProfileEditsPending, setAreProfileEditsPending] = useState(false)
    const handleEditFirstName = (newValue: string) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditFirstName(newValue)
    }
    const handleEditLastName = (newValue: string) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditLastName(newValue)
    }
    const handleEditEmailAddress = (newValue: string) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditEmailAddress(newValue)
    }
    const handleEditIsActive = (newValue: boolean) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditIsActive(newValue)
    }
    const handleEditRole = (newValue: number) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditRole(newValue)
    }
    const handleEditPassword = (newValue: string) => {
        if (!areProfileEditsPending) {
            setAreProfileEditsPending(true)
        }
        setEditPassword(newValue)
    }
    const [areProfileEditsSubmitting, setAreProfileEditsSubmitting] = useState(false)
    const handleEditProfileSubmit = async () => {
        // set areProfileEditsSubmitting
        setAreProfileEditsSubmitting(true)

        // if user profile changed
        if (props.user.userProfile !== undefined && (
            editFirstName !== props.user.userProfile.firstName ||
            editLastName !== props.user.userProfile.lastName ||
            editEmailAddress !== props.user.userProfile.emailAddress ||
            editIsActive !== props.user.userProfile.isActive)) {
            const res: ApiRes<void> = await UsersService.adminSetUserProfile(
                props.user.userId, editFirstName, editLastName, editEmailAddress, editIsActive)
        }

        // if user role changed 
        if ((props.user.userRole === null || editRole !== props.user.userRole.roleId) && editRole !== -1) {
            const res: ApiRes<void> = await UsersService.adminSetUserRole(props.user.userId, editRole)
        }

        // if user password changed
        if (editPassword.length > 0) {
            const res: ApiRes<void> = await UsersService.adminSetUserPassword(props.user.userId, editPassword)
        }

        // set areProfileEditsSubmitting
        setAreProfileEditsSubmitting(false)

        // close manage profile dialog
        setIsManageProfileOpen(false)

        // refresh users
        props.refreshUsersCB(true)
    }

    // edit user courses data
    interface IStudentCourseExtended extends IStudentCourse {
        currentSectionNum: number
        courseTotalSectionsNum: number
        courseTitle: string
    }
    const [studentCourses, setStudentCourses] = useState<Array<IStudentCourseExtended>>()
    const [isLoadingStudentCourses, setIsLoadingStudentCourses] = useState(true)
    const getStudentCourses = useCallback(async () => {
        const res: any = await StudentCoursesService.adminGetStudentCourses(props.user.userId)
        if (res.data !== undefined) {
            setStudentCourses(res.data)
            setIsLoadingStudentCourses(false)
        }
    }, [])
    // fetch studentCourses only on manage courses dialog open
    useEffect(() => {
        // set isLoadingStudentCourses
        setIsLoadingStudentCourses(true)
        // if dialog just closed, return
        if (!isManageCoursesOpen) {
            return
        }
        // get studentCourses
        getStudentCourses()
    }, [props.user.userId, isManageCoursesOpen, getStudentCourses])

    // courses
    const [courses, setCourses] = useState<Array<ICourse>>()
    const [isLoadingCourses, setIsLoadingCourses] = useState(false)

    // assign course
    const [isAssigningCourse, setIsAssigningCourse] = useState(false)
    const getCourses = useCallback(async () => {
        const res: any = await CoursesService.adminGetAllCourses()
        if (res.data !== undefined) {
            // filter out courses that are already assigned to student
            let studentCourseCourseIdsSet = new Set<number>()
            studentCourses?.forEach((studentCourse) => {
                studentCourseCourseIdsSet.add(studentCourse.courseId)
            })
            let coursesFiltered: Array<ICourse> = new Array<ICourse>()
            let coursesUnfiltered = res.data as Array<ICourse>
            coursesUnfiltered.forEach((course) => {
                if (!studentCourseCourseIdsSet.has(course.courseId)) {
                    coursesFiltered.push(course)
                }
            })
            setCourses(coursesFiltered)
            setIsLoadingCourses(false)
        }
    }, [studentCourses])
    // on trigger assign course, fetch courses
    useEffect(() => {
        // set isLoadingCourses
        setIsLoadingCourses(true)
        // if just assigned course, return
        if (!isAssigningCourse) {
            return
        }
        getCourses()
    }, [isAssigningCourse, getCourses])

    // ===== handleAssignCourse =====
    const [currAssignCourse, setCurrAssignCourse] = useState<ICourse | null>(null)
    const [isSendingAssignCourse, setIsSendingAssignCourse] = useState(false)
    const handleAssignCourse = async () => {
        if (currAssignCourse === null || currAssignCourse === undefined) {
            return
        }
        setIsSendingAssignCourse(true)
        const res: ApiRes<void> = await StudentCoursesService.adminAssignStudentCourse(props.user.userId, currAssignCourse.courseId)
        setCurrAssignCourse(null)
        setIsAssigningCourse(false)
        setIsSendingAssignCourse(false)
        // refresh studentCourses
        getStudentCourses()
    }

    return (
        <TableRow hover onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>

            {/* Id */}
            {props.isTableId &&
            <IdCell padding='none' align='center' style={{padding: '5px', height: '100%'}}>
                {props.user.userId &&
                <Typography>{props.user.userId}</Typography>
                }
            </IdCell>
            }

            {/* User Name */}
            {props.isTableUserName &&
            <TableCell>
                {props.user.userName &&
                <Typography>{props.user.userName}</Typography>
                }
            </TableCell>
            }

            {/* Last */}
            {props.isTableLastName &&
            <TableCell>
                {props.user.userProfile && props.user.userProfile.lastName &&
                <Typography>{props.user.userProfile.lastName}</Typography>
                }
            </TableCell>
            }

            {/* First */}
            {props.isTableFirstName &&
            <TableCell>
                {props.user.userProfile && props.user.userProfile.firstName &&
                <Typography>{props.user.userProfile.firstName}</Typography>
                }
            </TableCell>
            }

            {/* Email */}
            {props.isTableEmailAddress &&
            <TableCell>
                {props.user.userProfile && props.user.userProfile.emailAddress &&
                <Typography>{props.user.userProfile.emailAddress}</Typography>
                }
            </TableCell>
            }

            {/* Role */}
            {props.isTableRole &&
            <TableCell>
                {props.user.userRole && props.user.userRole &&
                <Typography>{roleNames[props.user.userRole.roleId-1]}</Typography>
                }
            </TableCell>
            }

            {/* Actions */}
            <TableCell style={{height: '50px', width: '120px'}}>
                {isHovering &&
                <div style={{display: 'flex'}}>
                    <Tooltip title='Manage Courses'>
                        <IconButton onClick={() => setIsManageCoursesOpen(true)}>
                            <MenuBookIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Manage Profile'>
                        <IconButton onClick={() => setIsManageProfileOpen(true)}>
                            <ManageAccountsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete User'>
                        <IconButton onClick={() => setIsDeleteUserOpen(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                }
            </TableCell>

            {/* Manage Profile Dialog */}
            <Dialog open={isManageProfileOpen} onClose={() => setIsManageProfileOpen(false)} maxWidth='md' fullWidth={true}>
                <DialogTitle>Manage Profile</DialogTitle>
                <DialogContent>

                    <Divider />

                    <Grid container alignItems='center' spacing={3} style={{marginTop: '0px', marginBottom: '20px'}}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='First Name' value={editFirstName} onChange={(e) => handleEditFirstName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Last Name' value={editLastName} onChange={(e) => handleEditLastName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Email Address' value={editEmailAddress} onChange={(e) => handleEditEmailAddress(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl style={{marginTop: '20px', marginBottom: '20px', width: '100%'}}>
                                <InputLabel id='role-label'>Role</InputLabel>
                                <Select labelId='role-label' label='Role' value={editRole} onChange={(e) => handleEditRole(e.target.value as number)} fullWidth>
                                    <MenuItem value={1}>Administrator</MenuItem>
                                    <MenuItem value={2}>Operator</MenuItem>
                                    <MenuItem value={3}>Instructor</MenuItem>
                                    <MenuItem value={4}>Student</MenuItem>
                                    <MenuItem value={-1}>None</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container alignItems='center' spacing={3} style={{marginTop: '0px', marginBottom: '20px'}}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label='Password' value={editPassword} onChange={(e) => handleEditPassword(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Divider />
                </DialogContent>
                <DialogActions style={{paddingRight: '24px', paddingBottom: '24px', marginTop: '-10px'}}>
                    <Button variant='contained' style={{textTransform: 'none'}} disabled={!areProfileEditsPending} onClick={handleEditProfileSubmit}>
                        {areProfileEditsSubmitting ?
                        <CircularProgress size={20} style={{color: 'white'}} /> :
                        <Typography style={{fontSize: 14}}>Save</Typography>
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Manage Courses Dialog */}
            <Dialog open={isManageCoursesOpen} onClose={() => setIsManageCoursesOpen(false)} maxWidth='md' fullWidth={true}>
                <DialogTitle style={{paddingBottom: '10px'}}>
                    Manage Courses
                    <ColorTyp>
                        {(props.user.userProfile && props.user.userProfile.firstName ? props.user.userProfile.firstName : null) + ' ' +
                        (props.user.userProfile && props.user.userProfile.lastName ? props.user.userProfile.lastName : null)}
                    </ColorTyp>
                </DialogTitle>
                <Divider />
                <DialogContent>

                    {/* User Courses Table */}
                    <Table size='small'>

                        {/* Table Head */}
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableHeadTyp>Course</TableHeadTyp>
                                </TableCell>
                                <TableCell>
                                    <TableHeadTyp>Status</TableHeadTyp>
                                </TableCell>
                                <TableCell>
                                    <TableHeadTyp>Current Section</TableHeadTyp>
                                </TableCell>
                                <TableCell>
                                    <TableHeadTyp>Available</TableHeadTyp>
                                </TableCell>
                                <TableCell>
                                    <TableHeadTyp></TableHeadTyp>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        {!isLoadingStudentCourses &&
                        <Grow in={!isLoadingStudentCourses}>
                            <TableBody>
                                {studentCourses && studentCourses.map((studentCourse, index) =>
                                <UsersAdminManageCoursesTableRow studentCourse={studentCourse} key={index}
                                    refreshStudentCourses={getStudentCourses} userProfile={props.user.userProfile as IUserProfile}
                                />
                                )}
                            </TableBody>
                        </Grow>
                        }
                    </Table>
                    {isLoadingStudentCourses &&
                    <LinearProgress />
                    }

                    {/* Assign Course */}
                    <div style={{marginTop: '10px', marginBottom: '0px'}}>
                        <Grow in={isAssigningCourse}>
                            <div>
                                {courses &&
                                <div style={{display: 'flex', width: '100%', marginTop: '10px'}}>
                                    <Autocomplete fullWidth value={currAssignCourse} options={courses} getOptionLabel={(course) => course.courseTitle}
                                        renderInput={(params) => <TextField variant='standard' {...params} label="Assign Course..." />}
                                        onChange={(e, newValue) => setCurrAssignCourse(newValue)}
                                    />
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Button disabled={currAssignCourse == null} variant='contained' style={{textTransform: 'none', marginLeft: '20px', height: '80%'}} onClick={() => handleAssignCourse()}>
                                            {isSendingAssignCourse ?
                                            <CircularProgress style={{color: 'white'}} size={24} /> :
                                            ('Assign')
                                            }
                                        </Button>
                                    </div>
                                </div>
                                }
                            </div>
                        </Grow>
                        <Grow in={!isAssigningCourse}>
                            <Tooltip title='Assign New Course' style={{marginTop: 0}}>
                                <IconButton onClick={() => setIsAssigningCourse(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Grow>
                    </div>

                </DialogContent>

            </Dialog>

            {/* Delete User Dialog */}
            <Dialog open={isDeleteUserOpen} onClose={() => setIsDeleteUserOpen(false)} fullWidth maxWidth='sm'>
                <DialogContent>
                    <div style={{display: 'flex'}}>
                        <Typography>Delete user</Typography>
                        {props.user.userProfile && props.user.userProfile.firstName && props.user.userProfile.lastName &&
                        <ColorTyp style={{marginLeft: '5px', fontWeight: 'bold'}}>{props.user.userProfile!.firstName + ' ' + props.user.userProfile!.lastName}</ColorTyp>
                        }
                        <Typography>?</Typography>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <Typography style={{fontStyle: 'italic', color: 'gray'}}>{'This will delete all of ' + (props.user.userProfile?.firstName ?? 'this user\'s') + '\'s:'}</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Course data</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Profile data</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Instructor Questions</Typography>
                    </div>
                </DialogContent>
                <DialogActions style={{padding: 0}}>
                    <div style={{display: 'flex', alignItems: 'end', paddingRight: '20px', paddingBottom: '20px'}}>
                        <Button variant='contained' style={{textTransform: 'none'}} onClick={() => handleDeleteUser()}>
                            {isSendingDeleteUser ?
                            <CircularProgress size={24} style={{color: 'white'}} /> :
                            ('Delete All User Data')
                            }
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

        </TableRow>
    )
}

export default UsersAdminTableRow