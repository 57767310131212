import { MouseEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import {
    AppBar as MuiAppBar,
    Button as MuiButton,
    Container,
    IconButton as MuiIconButton,
    ListItemIcon,
    ListItemText,
    Menu as MuiMenu,
    MenuItem,
    MenuList,
    styled,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    Logout as MuiLogoutIcon,
    ManageAccounts as MuiManageAccountsIcon,
    Menu as MenuIcon,
    Person as MuiPersonIcon,
    School as MuiSchoolIcon,
} from '@mui/icons-material'

import inteqLogoImg from '../../assets/imgs/inteq.edu.png'

const AppBar = styled(MuiAppBar)(({theme}) => ({
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottom: '5px solid ' + theme.palette.primary.main,
}))
const Button = styled(MuiButton)(({theme}) => ({
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: 16,
}))
const IconButton = styled(MuiIconButton)(({theme}) => ({
    color: theme.palette.primary.main
}))
const Menu = styled(MuiMenu)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const NavButtonTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    marginRight: '5px',
}))

const LogoutIcon = styled(MuiLogoutIcon)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const ManageAccountsIcon = styled(MuiManageAccountsIcon)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const PersonIcon = styled(MuiPersonIcon)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const SchoolIcon = styled(MuiSchoolIcon)(({theme}) => ({
    color: theme.palette.primary.main,
}))

const NavBar = () => {

    // ===== navigate ======
    const navigate = useNavigate()

    // ===== isMobile ======
    const theme = useTheme()
    const isDeviceXs = useMediaQuery(theme.breakpoints.down('xs'))
    const isDeviceSm = useMediaQuery(theme.breakpoints.down('sm'))
    const isMobile = isDeviceXs || isDeviceSm

    // ===== menu =====
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget)
        setIsMenuOpen(true)
    }
    const handleMenuClose = () => {
        setIsMenuOpen(false)
        setMenuAnchor(null)
    }

    // ===== mobileMenu =====
    const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const handleMobileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setMobileMenuAnchor(event.currentTarget)
        setIsMobileMenuOpen(true)
    }
    const handleMobileMenuClose = () => {
        setIsMobileMenuOpen(false)
        setMobileMenuAnchor(null)
    }

    // ===== handleLogout =====
    const handleLogout = () => {
        // set login states
        setIsLoggedIn(false)
        setFirstName('')
        setRole(null)
        // remove authToken from local storage
        localStorage.removeItem('authToken')
        // delete axios Authorization header
        delete axios.defaults.headers.common['Authorization']
        // navigate to home page
        navigate('/')
    }

    // check if user is logged in and get data from authToken
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [role, setRole] = useState(null)
    useEffect(() => {
        const token = localStorage.getItem('authToken')
        if (token != null) {
            const decodedToken: any = jwt_decode(token)
            setFirstName(decodedToken.given_name)
            setRole(decodedToken.role)
            // set isLoggedIn
            setIsLoggedIn(true)
        }
    }, [])

    return (
        <AppBar position='static'>
            <Container maxWidth='lg'>
                <Toolbar style={{padding: 0}}>

                    {/* Inteq Logo */}
                    <Link to='/'><img src={inteqLogoImg} alt='' style={{height: '30px', marginTop: '10px'}} /></Link>

                    <div style={{ flexGrow: 1 }} />

                    {/* NavBar Buttons */}
                    {isLoggedIn ?
                        <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                            {/* If logged in */}

                            {isMobile ?
                            <div>
                                {/* Mobile */}
                                <IconButton onClick={handleMobileMenuOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <Menu anchorEl={mobileMenuAnchor} open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
                                    <MenuList dense>
                                        {/* Profile */}
                                        <MenuItem onClick={() => navigate('/profile')}>
                                            <ListItemIcon><PersonIcon /></ListItemIcon>
                                            <ListItemText>Profile</ListItemText>
                                        </MenuItem>
                                        {/* Learn */}
                                        <MenuItem onClick={() => navigate('/learn')}>
                                            <ListItemIcon><SchoolIcon /></ListItemIcon>
                                            <ListItemText>Learn</ListItemText>
                                        </MenuItem>
                                        {/* Admin */}
                                        {(role === '1' || role === '2') &&
                                        <MenuItem onClick={() => navigate('/admin/users')}>
                                            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                                            <ListItemText>Admin</ListItemText>
                                        </MenuItem>
                                        }
                                        {/* Logout */}
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                                            <ListItemText>Logout</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>

                            </div> :
                            <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                {/* Desktop */}
                                {/* Admin */}
                                {(role === '1' || role === '2') ? 
                                <Button onClick={() => navigate('/admin/users')}>
                                    <NavButtonTyp>Admin</NavButtonTyp>
                                    <ManageAccountsIcon />
                                </Button> : null
                                }

                                <div style={{width: '10px'}} />

                                {/* Learn */}
                                <Button onClick={() => navigate('/learn')}>
                                    <NavButtonTyp>Learn</NavButtonTyp>
                                    <SchoolIcon />
                                </Button>

                                <div style={{width: '10px'}} />

                                {/* Profile */}
                                <Button aria-label='Profile' onClick={handleMenuOpen}>
                                    <NavButtonTyp>{firstName}</NavButtonTyp>
                                    <PersonIcon />
                                </Button>
                                <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={handleMenuClose}>
                                    <MenuItem onClick={() => navigate('/profile')}>
                                        <ListItemIcon><PersonIcon /></ListItemIcon>
                                        <ListItemText>Profile</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                                        <ListItemText>Logout</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </div>
                            }

                        </div>:
                        <div>
                            <Button variant='outlined' onClick={() => navigate('/login')}>
                                {!isMobile &&
                                    <img src={inteqLogoImg} alt='Inteq.edu' style={{ height: '14px', paddingTop: '3px', paddingRight: '8px' }} />
                                }
                                Login
                            </Button>
                        </div>
                    }

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default NavBar