import React from 'react'
import {
    Container,
    styled,
    Tabs as MuiTabs,
    Tab as MuiTab,
} from '@mui/material'
import {
    ContactSupport as ContactSupportIcon,
    MenuBook as MenuBookIcon,
    Person as PersonIcon,
} from '@mui/icons-material'

// components
import NavBar from '../shared/NavBar'
import Header from '../shared/Header'
import Footer from '../shared/Footer'

import ManageUsers from './Users/UsersAdminTable/UsersAdminTable'
import ManageCourses from './Courses/CoursesAdminTable/CoursesAdminTable'
import QAndA from './QAndA/QAndAAdminTable'

// Mui
const Tab = styled(MuiTab)(({theme}) => ({
    textTransform: 'none',
}))
const Tabs = styled(MuiTabs)(({theme}) => ({
    backgroundColor: 'white',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: 15,
}))

const Admin = () => {

    // const tabs
    const [currTab, setCurrTab] = React.useState(0)
    const handleChangeTab = (newTab: number) => {
        setCurrTab(newTab)
    }

    var currTabContent = <ManageUsers />
    switch (currTab) {
        case 0:
            currTabContent = <ManageUsers />
            break
        case 1:
            currTabContent = <ManageCourses />
            break
        case 2:
            currTabContent = <QAndA />
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Admin Portal' />

            {/* Admin Portal */}
            <Container maxWidth='lg'>

                {/* Tabs */}
                <Tabs variant='fullWidth' value={currTab} onChange={(e, newTab) => handleChangeTab(newTab)}>
                    <Tab icon={<PersonIcon />} iconPosition='start' label='Manage Users' />
                    <Tab icon={<MenuBookIcon />} iconPosition='start' label='Manage Courses' />
                    <Tab icon={<ContactSupportIcon />} iconPosition='start' label='Q&A' />
                </Tabs>

                {/* Tab Content */}
                {currTabContent}

            </Container>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default Admin