/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material'

// interfaces
import { ICourse } from '../../../../models/Course'

// services
import CoursesService from '../../../../services/CoursesService'

// mui
const ColorBoldTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold'
}))
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main
}))
const IdCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
}))
const Typ = styled(Typography)(({theme}) => ({
}))

interface ICoursesAdminTableRowProps {
    course: ICourse
    refreshCourses: () => void
    isId: boolean
    isTitle: boolean
    isDescription: boolean
}
const CoursesAdminTableRow = (props: ICoursesAdminTableRowProps) => {

    // ===== navigate =====
    const navigate = useNavigate()

    // ===== isHovering =====
    const [isHovering, setIsHovering] = useState(false)

    // ===== handleDeleteCourse =====
    const [isSendingDeleteCourse, setIsSendingDeleteCourse] = useState(false)
    const handleDeleteCourse = async () => {
        if (!props.course.courseId) {
            return
        }
        setIsSendingDeleteCourse(true)
        await CoursesService.adminDeleteCourse(props.course.courseId)
        setIsSendingDeleteCourse(false)
        setIsDeleteCourseDialogOpen(false)
        props.refreshCourses()
    }

    // ===== delete course dialog =====
    const [isDeleteCourseDialogOpen, setIsDeleteCourseDialogOpen] = useState(false)

    return (
        <TableRow hover onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>

            {/* <TableCell>
                <Checkbox />
            </TableCell> */}

            {/* ID */}
            {props.isId &&
            <IdCell padding='none' align='center' style={{padding: '5px', height: '100%'}}>
                <Typography>
                    {props.course && props.course.courseId ?
                    props.course.courseId : null}
                </Typography>
            </IdCell>
            }

            {/* Course */}
            {props.isTitle &&
            <TableCell>
                <Typography>
                    {props.course && props.course.courseTitle ?
                    props.course.courseTitle : null}
                </Typography>
            </TableCell>
            }

            {/* Description */}
            {props.isDescription &&
            <TableCell>
                <Typography>
                    {props.course && props.course.courseDescription ?
                    props.course.courseDescription : null}
                </Typography>
            </TableCell>
            }

            {/* Actions */}
            <TableCell style={{height: '50px', width: '50px'}}>
                {isHovering &&
                <div style={{display: 'flex', width: '70px'}}>
                    <Tooltip title='Edit Course'>
                    <IconButton onClick={() => navigate(props.course.courseId.toString() ?? '')}>
                        <EditIcon />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Course'>
                        <IconButton onClick={() => setIsDeleteCourseDialogOpen(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                }
            </TableCell>

            {/* Delete Course Dialog */}
            <Dialog open={isDeleteCourseDialogOpen} onClose={() => setIsDeleteCourseDialogOpen(false)} maxWidth='sm' fullWidth>
                <DialogTitle>
                    <div style={{display: 'flex', fontSize: 20}}>
                        <Typ>Delete</Typ>
                        {props.course && props.course.courseTitle &&
                        <ColorTyp style={{marginLeft: '5px'}}>{props.course.courseTitle}</ColorTyp>
                        }
                        <Typ>?</Typ>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {props.course && props.course.courseTitle &&
                    <div style={{marginTop: '10px'}}>
                        <Typography style={{fontStyle: 'italic', color: 'gray'}}>{'This will delete all of ' + props.course.courseTitle + '\'s:'}</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Sections</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Modules</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• User data</Typography>
                        <Typography style={{fontStyle: 'italic', color: 'gray', marginLeft: '20px'}}>• Instructor Questions</Typography>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <div style={{display: 'flex', alignItems: 'end'}}>
                        <Button variant='contained' style={{textTransform: 'none'}} onClick={() => handleDeleteCourse()}>
                            {isSendingDeleteCourse ?
                            <CircularProgress size={24} style={{color: 'white'}} /> :
                            ('Delete All Course Data')
                            }
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

        </TableRow>
    )
}

export default CoursesAdminTableRow