/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import {
    Box,
    Button,
    CircularProgress,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Divider,
    Card,
    CardActions,
    CardContent,
    Chip,
    List,
    ListItem,
    Paper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Delete as DeleteIcon,
    Description as DescriptionIcon,
    Title as TitleIcon,
} from '@mui/icons-material'

// components
import SummedEditCard from './SummedEditCard'

// interfaces
import {
    ICourse,
    ICourseSection,
    ICourseSectionModule,
} from '../../../../models/Course'
import {
    EditType,
    ISummedEdit
} from './shared'
import { ApiRes } from '../../../../models/Api'

// services
import CoursesService from '../../../../services/CoursesService'

// mui
const Dialog = styled(MuiDialog)(({theme}) => ({
}))
const DialogActions = styled(MuiDialogActions)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    padding: '10px'
}))
const DialogContent = styled(MuiDialogContent)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    padding: '15px',
    boxSizing: 'border-box'
}))
const DialogTitle = styled(MuiDialogTitle)(({theme}) => ({
    backgroundColor: theme.palette.primary.light
}))
const DialogTitleTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 20
}))
const IconHolder = styled(Box)(({theme}) => ({
    marginRight: '10px',
    color: theme.palette.primary.main
}))
const IconHolderDelete = styled(Box)(({theme}) => ({
    marginRight: '10px',
    color: theme.palette.error.main
}))
const SectionChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const SectionChipAdd = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const SummedEditLeading = styled(Typography)(({theme}) => ({
    color: '#505050',
}))
const SummedEditList = styled(List)(({theme}) => ({
}))
const SummedEditListItem = styled(ListItem)(({theme}) => ({
}))
const SummedSubEditList = styled(List)(({theme}) => ({
}))
const SummedSubEditListItem = styled(ListItem)(({theme}) => ({
    marginLeft: '20px'
}))
const SummedSubSubEditList = styled(List)(({theme}) => ({
}))
const SummedSubSubEditListItem = styled(ListItem)(({theme}) => ({
    marginLeft: '20px'
}))
const SummedEditTitle = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}))
const SummedEditTrailing = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    marginLeft: '5px',
    fontStyle: 'italic'
}))
const ToolbarPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingTop: '7.5px',
    paddingRight: '15px',
    paddingBottom: '7.5px',
    width: '100%',
    color: theme.palette.primary.main,
}))

interface ISaveEditsDialogProps {
    readonly isDialogOpen: boolean
    readonly setIsDialogOpen: (isDialogOpen: boolean) => void
    readonly setNumSummedEdits: (numSummedEdits: number) => void
    readonly course: ICourse | null
    readonly courseTitleEditsToToolbar: string | null
    readonly courseDescriptionEditsToToolbar: string | null
    readonly courseSectionsEdits: Map<number, ICourseSection>
    readonly courseSectionModulesEdits: Map<number, ICourseSectionModule>
    readonly courseSectionsMap: Map<number, ICourseSection> | undefined
    readonly courseSectionModulesMap: Map<number, ICourseSectionModule> | undefined
    readonly courseSectionsDeletes: Set<number>
    readonly courseSectionModulesDeletes: Set<number>
    readonly refreshCourse: () => void
}
const SaveEditsDialog = (props: ISaveEditsDialogProps) => {

    // ============================================================================
    // All edits are parsed into and accounted for by an Array<SummedEdit>.
    // Every SummedEdit needs an EditType.
    // ============================================================================
    const [summedEdits, setSummedEdits] = useState<Array<ISummedEdit> | null>(null)
    // populate summedEdits
    useEffect(() => {
        if (props.courseSectionsMap === undefined || props.courseSectionModulesMap === undefined) {
            return
        }
        let newSummedEdits = new Array<ISummedEdit>()
        let newNumSummedEdits: number = 0
        // === course ===
        // courseTitle
        if (props.courseTitleEditsToToolbar !== null && props.courseTitleEditsToToolbar !== props.course?.courseTitle) {
            newSummedEdits.push({
                oldValue: props.course?.courseTitle,
                newValue: props.courseTitleEditsToToolbar!,
                editType: EditType.CourseTitle
            })
            newNumSummedEdits++
        }
        // courseDescription
        if (props.courseDescriptionEditsToToolbar !== null && props.courseDescriptionEditsToToolbar !== props.course?.courseDescription) {
            newSummedEdits.push({
                oldValue: props.course?.courseDescription,
                newValue: props.courseDescriptionEditsToToolbar!,
                editType: EditType.CourseDescription
            })
            newNumSummedEdits++
        }

        // === courseSections ===
        props.courseSectionsEdits.forEach((sectionEdit) => {
            let newSummedEdit: ISummedEdit = {
                courseSectionId: sectionEdit.courseSectionId,
                newValue: sectionEdit.courseSectionTitle,
                editType: EditType.CourseSection,
                editReference: sectionEdit
            }
            let newSummedEditSubs = new Array<ISummedEdit>()
            // if this is a new section, should not check in courseSectionsMap
            if (!props.courseSectionsMap?.has(sectionEdit.courseSectionId)) {
                newSummedEdit.oldValue = undefined
                newSummedEdit.editType = EditType.CourseSectionAdd
                newSummedEditSubs.push({
                    oldValue: undefined,
                    newValue: sectionEdit.courseSectionTitle,
                    editType: EditType.CourseSectionTitle
                })
                newNumSummedEdits++
                newSummedEditSubs.push({
                    oldValue: undefined,
                    newValue: sectionEdit.sequenceId.toString(),
                    editType: EditType.CourseSectionPosition
                })
                newNumSummedEdits++
                // only push if newSummedEditSubs.length > 0
                if (newSummedEditSubs.length > 0) {
                    newSummedEdit.subEdits = newSummedEditSubs
                    newSummedEdits.push(newSummedEdit)
                }
            } else {
                newSummedEdit.oldValue = props.courseSectionsMap!.get(sectionEdit.courseSectionId)!.courseSectionTitle
                // courseSectionTitle
                if (sectionEdit.courseSectionTitle !== props.courseSectionsMap!.get(sectionEdit.courseSectionId)!.courseSectionTitle) {
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionsMap!.get(sectionEdit.courseSectionId)!.courseSectionTitle,
                        newValue: sectionEdit.courseSectionTitle,
                        editType: EditType.CourseSectionTitle
                    })
                    newNumSummedEdits++
                }
                // courseSection position
                if (sectionEdit.sequenceId !== props.courseSectionsMap!.get(sectionEdit.courseSectionId)!.sequenceId) {
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionsMap!.get(sectionEdit.courseSectionId)!.sequenceId.toString(),
                        newValue: sectionEdit.sequenceId.toString(),
                        editType: EditType.CourseSectionPosition
                    })
                    newNumSummedEdits++
                }
                // only push if newSummedEditSubs.length > 0
                if (newSummedEditSubs.length > 0) {
                    newSummedEdit.subEdits = newSummedEditSubs
                    newSummedEdits.push(newSummedEdit)
                }
            }
        })
        // === courseSectionDeletes ===
        props.courseSectionsDeletes.forEach((sectionIdToDelete) => {
            // get courseSectionTitle
            let thisSection: ICourseSection = cloneDeep(props.courseSectionsMap?.get(sectionIdToDelete))
            if (thisSection === null || thisSection === undefined) {
                thisSection = cloneDeep(props.courseSectionsEdits?.get(sectionIdToDelete))
            }
            let newSummedEdit: ISummedEdit = {
                oldValue: thisSection.courseSectionTitle,
                editType: EditType.CourseSectionDelete,
                editReference: thisSection,
                subEdits: new Array<ISummedEdit>()
            }
            // append all of this section's modules as deletes
            thisSection.courseSectionModules?.forEach((module) => {
                newSummedEdit.subEdits!.push({
                    oldValue: module.courseSectionModuleTitle,
                    editType: EditType.CourseSectionModuleDelete,
                    editReference: module,
                })
                newNumSummedEdits++
            })
            newSummedEdits.push(newSummedEdit)
            newNumSummedEdits++
        })

        // === courseSectionModules ===
        props.courseSectionModulesEdits.forEach((moduleEdit) => {
            // if module's section was deleted, do not include
            if (props.courseSectionsDeletes.has(moduleEdit.courseSection_CourseSectionId)) {
                // go to next
                return
            }
            let newSummedEdit: ISummedEdit = {
                newValue: moduleEdit.courseSectionModuleTitle,
                editType: EditType.CourseSectionModule,
                editReference: moduleEdit
            }
            let newSummedEditSubs = new Array<ISummedEdit>()
            // if this is a new module, should not check in courseSectionModulesMap
            if (!props.courseSectionModulesMap?.has(moduleEdit.courseSectionModuleId)) {
                newSummedEdit.oldValue = undefined
                newSummedEdit.editType = EditType.CourseSectionModuleAdd
                if (moduleEdit.courseSectionModuleTitle !== '') {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: moduleEdit.courseSectionModuleTitle,
                        editType: EditType.CourseSectionModuleTitle
                    })
                    newNumSummedEdits++
                } else {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: undefined,
                        editType: EditType.CourseSectionModuleTitle
                    })
                }
                newNumSummedEdits++
                if (moduleEdit.courseSectionModuleDescription !== '') {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: moduleEdit.courseSectionModuleDescription,
                        editType: EditType.CourseSectionModuleDescription
                    })
                    newNumSummedEdits++
                } else {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: undefined,
                        editType: EditType.CourseSectionModuleDescription
                    })
                }
                newSummedEditSubs.push({
                    oldValue: undefined,
                    newValue: moduleEdit.sequenceId.toString(),
                    editType: EditType.CourseSectionModulePosition
                })
                newNumSummedEdits++
                if (moduleEdit.embedUrl !== '') {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: moduleEdit.embedUrl,
                        editType: EditType.CourseSectionModuleEmbedUrl
                    })
                    newNumSummedEdits++
                } else {
                    newSummedEditSubs.push({
                        oldValue: undefined,
                        newValue: '',
                        editType: EditType.CourseSectionModuleEmbedUrl
                    })
                }
            } else {
                newSummedEdit.oldValue = props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleTitle
                // courseSectionModuleTitle
                if (moduleEdit.courseSectionModuleTitle !== props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleTitle) {
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleTitle,
                        newValue: moduleEdit.courseSectionModuleTitle,
                        editType: EditType.CourseSectionModuleTitle
                    })
                    newNumSummedEdits++
                }
                // courseSectionModuleDescription
                if (moduleEdit.courseSectionModuleDescription !== props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleDescription) {
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleDescription,
                        newValue: moduleEdit.courseSectionModuleDescription,
                        editType: EditType.CourseSectionModuleDescription
                    })
                    newNumSummedEdits++
                }
                // courseSectionModule position
                if (moduleEdit.sequenceId !== props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.sequenceId) {
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.sequenceId.toString(),
                        newValue: moduleEdit.sequenceId.toString(),
                        editType: EditType.CourseSectionModulePosition
                    })
                    newNumSummedEdits++
                }
                // courseSectionModule embedUrl
                if (moduleEdit.embedUrl !== props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleComponents?.[0].courseSectionModuleComponentEmbed.embedUrl) {
                    console.log(moduleEdit)
                    newSummedEditSubs.push({
                        oldValue: props.courseSectionModulesMap!.get(moduleEdit.courseSectionModuleId)!.courseSectionModuleComponents?.[0].courseSectionModuleComponentEmbed.embedUrl,
                        newValue: moduleEdit.embedUrl,
                        editType: EditType.CourseSectionModuleEmbedUrl
                    })
                    newNumSummedEdits++
                }
            }
            // append module edits to module edit title
            newSummedEdit.subEdits = newSummedEditSubs
            // try to find section in edits that corresponds to this module's section
            let foundSummedEditIndex: number | null = null
            for (let i=0; i<newSummedEdits.length; ++i) {
                if (newSummedEdits[i].courseSectionId === moduleEdit.courseSection_CourseSectionId) {
                    foundSummedEditIndex = i
                    break
                }
            }
            if (foundSummedEditIndex === null) {
                // no section edit, must insert one and append to it
                let tempSub = new Array<ISummedEdit>()
                tempSub.push(newSummedEdit)
                newSummedEdits.push({
                    courseSectionId: moduleEdit.courseSection_CourseSectionId,
                    oldValue: props.courseSectionsMap?.get(moduleEdit.courseSection_CourseSectionId)?.courseSectionTitle,
                    editReference: props.courseSectionsMap?.get(moduleEdit.courseSection_CourseSectionId),
                    newValue: undefined,
                    subEdits: tempSub,
                    editType: EditType.CourseSectionTransparent // this section has no edits itself, so it is marked as transparent
                })
            } else {
                // section edit found, so append to it
                if (newSummedEdits[foundSummedEditIndex!].subEdits !== undefined) {
                    newSummedEdits[foundSummedEditIndex!].subEdits!.push(newSummedEdit)
                } else {
                    let tempSub = new Array<ISummedEdit>()
                    tempSub.push(newSummedEdit)
                    newSummedEdits[foundSummedEditIndex!].subEdits = tempSub
                }
            }
        })
        // === courseSectionModulesDeletes ===
        props.courseSectionModulesDeletes.forEach((moduleIdToDelete) => {
            // get courseSectionModuleTitle
            let thisModule = props.courseSectionModulesMap?.get(moduleIdToDelete)
            if (thisModule === null || thisModule === undefined) {
                thisModule = props.courseSectionModulesEdits?.get(moduleIdToDelete)
            }
            // if module's section was deleted, do not include
            if (props.courseSectionsDeletes.has(thisModule!.courseSection_CourseSectionId)) {
                // go to next
                return
            }
            let newSummedEdit = ({
                oldValue: thisModule?.courseSectionModuleTitle,
                editType: EditType.CourseSectionModuleDelete,
                editReference: cloneDeep(thisModule)
            })
            // try to find section in edits that corresponds to this module's section
            let courseSectionId: number | undefined = props.courseSectionModulesMap?.get(moduleIdToDelete)?.courseSection_CourseSectionId
            // only append if courseSectionId is not undefined (if so, then section was created and deleted in one session)
            if (courseSectionId !== undefined) {
                let foundSummedEditIndex: number | null = null
                for (let i=0; i<newSummedEdits.length; ++i) {
                    if (newSummedEdits[i].courseSectionId === courseSectionId) {
                        foundSummedEditIndex = i
                        break
                    }
                }
                if (foundSummedEditIndex === null) {
                    // no section edit, must insert one and append to it
                    let tempSub = new Array<ISummedEdit>()
                    tempSub.push(newSummedEdit)
                    newSummedEdits.push({
                        courseSectionId: courseSectionId,
                        oldValue: props.courseSectionsMap?.get(courseSectionId)?.courseSectionTitle,
                        editReference: props.courseSectionsMap?.get(courseSectionId),
                        subEdits: tempSub,
                        editType: EditType.CourseSectionTransparent,
                    })
                } else {
                    // section edit found, so append to it
                    if (newSummedEdits[foundSummedEditIndex!].subEdits !== undefined) {
                        newSummedEdits[foundSummedEditIndex!].subEdits!.push(newSummedEdit)
                    } else {
                        let tempSub = new Array<ISummedEdit>()
                        tempSub.push(newSummedEdit)
                        newSummedEdits[foundSummedEditIndex!].subEdits = tempSub
                    }
                }
                newNumSummedEdits++
            }
        })

        // === sort ===
        // sort sections and inner course
        let sortedSummedEdits: Array<ISummedEdit> = newSummedEdits.sort((a, b) => {
            switch (a.editType) {
                case EditType.CourseSection:
                case EditType.CourseSectionAdd:
                case EditType.CourseSectionDelete:
                case EditType.CourseSectionTransparent:
                    switch (b.editType) {
                        case EditType.CourseSection:
                        case EditType.CourseSectionAdd:
                        case EditType.CourseSectionDelete:
                        case EditType.CourseSectionTransparent:
                            if ((a.editReference as ICourseSection).sequenceId! < (b.editReference as ICourseSection).sequenceId) {
                                return -1
                            } else {
                                return 1
                            }
                        case EditType.CourseTitle:
                        case EditType.CourseDescription:
                            return 1
                    }
                    return 0
                case EditType.CourseTitle:
                    return -1
                case EditType.CourseDescription:
                    if (b.editType === EditType.CourseTitle) {
                        return 1
                    } else {
                        return -1
                    }
            }
            return 0
        })
        // sort modules and inner sections
        for (let i=0; i<sortedSummedEdits.length; ++i) {
            if (sortedSummedEdits[i].editType === EditType.CourseTitle ||
                    sortedSummedEdits[i].editType === EditType.CourseDescription ||
                    sortedSummedEdits[i].editType === EditType.CourseSectionDelete) {
                // no inner to sort
                continue
            } else {
                // need to sort inner
                sortedSummedEdits[i].subEdits = sortedSummedEdits[i].subEdits?.sort((a, b) => {
                    switch (a.editType) {
                        case EditType.CourseSectionModule:
                        case EditType.CourseSectionModuleAdd:
                        case EditType.CourseSectionModuleDelete:
                        case EditType.CourseSectionTransparent:
                            switch (b.editType) {
                                case EditType.CourseSectionModule:
                                case EditType.CourseSectionModuleAdd:
                                case EditType.CourseSectionModuleDelete:
                                case EditType.CourseSectionTransparent:
                                    if ((a.editReference as ICourseSectionModule).sequenceId < (b.editReference as ICourseSectionModule).sequenceId) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                case EditType.CourseSectionTitle:
                                case EditType.CourseSectionPosition:
                                    return 1
                            }
                            return 0
                        case EditType.CourseSectionTitle:
                            return -1
                        case EditType.CourseSectionPosition:
                            if (b.editType === EditType.CourseSectionTitle) {
                                return 1
                            } else {
                                return -1
                            }
                    }
                    return 0
                })
            }
        }
        // sort inner modules
        for (let i=0; i<sortedSummedEdits.length; ++i) {
            if (sortedSummedEdits[i].editType === EditType.CourseTitle ||
                sortedSummedEdits[i].editType === EditType.CourseDescription ||
                sortedSummedEdits[i].editType === EditType.CourseSectionDelete) {
                // nothing to sort
                continue
            } else {
                for (let j=0; j<sortedSummedEdits[i].subEdits!.length; ++j) {
                    if ((sortedSummedEdits[i].subEdits![j]).editType === EditType.CourseSectionTitle ||
                        (sortedSummedEdits[i].subEdits![j]).editType === EditType.CourseSectionPosition ||
                        (sortedSummedEdits[i].subEdits![j]).editType === EditType.CourseSectionModuleDelete) {
                        // nothing to sort
                        continue
                    } else {
                        sortedSummedEdits[i].subEdits![j].subEdits = sortedSummedEdits[i].subEdits![j].subEdits?.sort((a, b) => {
                            switch (a.editType) {
                                case EditType.CourseSectionModuleTitle:
                                    return -1
                                case EditType.CourseSectionModuleDescription:
                                    if (b.editType === EditType.CourseSectionModuleTitle) {
                                        return 1
                                    } else {
                                        return -1
                                    }
                                case EditType.CourseSectionModulePosition:
                                    if (b.editType === EditType.CourseSectionModuleEmbedUrl) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                case EditType.CourseSectionModuleEmbedUrl:
                                    return 1
                            }
                            return 0
                        })
                        // sorted edits
                        // course title
                        // course section
                            // sorted edits subedits
                            // course section title
                            // course module
                                // sored edits subedits subedits
                                // course module title
                                // course module description
                    }
                }
            }
        }

        // === save ===
        setSummedEdits(sortedSummedEdits)
        props.setNumSummedEdits(newNumSummedEdits)
    }, [props])

    // ===== handleSaveEdits =====
    const [isSendingEdits, setIsSendingEdits] = useState(false)
    const handleSaveEdits = async () => {
        if (!props.course) {
            return
        }
        setIsSendingEdits(true)
        // split edits into categories
        let courseTitle: string
        if (props.courseTitleEditsToToolbar === undefined || props.courseTitleEditsToToolbar === null) {
            courseTitle = props.course.courseTitle
        } else {
            courseTitle = props.courseTitleEditsToToolbar
        }
        let courseDescription: string
        if (props.courseDescriptionEditsToToolbar === undefined || props.courseDescriptionEditsToToolbar === null) {
            courseDescription = props.course.courseDescription
        } else {
            courseDescription = props.courseDescriptionEditsToToolbar
        }
        let sectionsToUpdate = Array<ICourseSection>()
        let sectionsToInsert = Array<ICourseSection>()
        let sectionsToDelete = Array<ICourseSection>()
        let modulesToUpdate = Array<ICourseSectionModule>()
        let modulesToInsert = Array<ICourseSectionModule>()
        let modulesToDelete = Array<ICourseSectionModule>()
        summedEdits?.forEach((edit) => {
            switch (edit.editType) {
                case EditType.CourseSection:
                    sectionsToUpdate.push(edit.editReference as ICourseSection)
                    edit.subEdits?.forEach((subEdit) => {
                        switch (subEdit.editType) {
                            case EditType.CourseSectionModule:
                                modulesToUpdate.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleAdd:
                                modulesToInsert.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleDelete:
                                modulesToDelete.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionTitle:
                            case EditType.CourseDescription:
                            case EditType.CourseSectionPosition:
                                // no need to save these edits separately
                        }
                    })
                    break
                case EditType.CourseSectionAdd:
                    sectionsToInsert.push(edit.editReference as ICourseSection)
                    edit.subEdits?.forEach((subEdit) => {
                        switch (subEdit.editType) {
                            case EditType.CourseSectionModule:
                                modulesToUpdate.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleAdd:
                                modulesToInsert.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleDelete:
                                modulesToDelete.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionTitle:
                            case EditType.CourseDescription:
                            case EditType.CourseSectionPosition:
                                // no need to save these edits separately
                        }
                    })
                    break
                case EditType.CourseSectionDelete:
                    sectionsToDelete.push(edit.editReference as ICourseSection)
                    edit.subEdits?.forEach((subEdit) => {
                        switch (subEdit.editType) {
                            case EditType.CourseSectionModule:
                                modulesToUpdate.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleAdd:
                                modulesToInsert.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleDelete:
                                modulesToDelete.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionTitle:
                            case EditType.CourseDescription:
                            case EditType.CourseSectionPosition:
                                // no need to save these edits separately
                        }
                    })
                    break
                case EditType.CourseSectionTransparent:
                    edit.subEdits?.forEach((subEdit) => {
                        switch (subEdit.editType) {
                            case EditType.CourseSectionModule:
                                modulesToUpdate.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleAdd:
                                modulesToInsert.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionModuleDelete:
                                modulesToDelete.push(subEdit.editReference as ICourseSectionModule)
                                break
                            case EditType.CourseSectionTitle:
                            case EditType.CourseDescription:
                            case EditType.CourseSectionPosition:
                                // no need to save these edits separately
                        }
                    })
                    break
                case EditType.CourseTitle:
                case EditType.CourseDescription:
                    // no need to save these edits separately
            }
        })
        const res: ApiRes<void> = await CoursesService.adminUpdateCourse(
            props.course.courseId,
            courseTitle,
            courseDescription,
            sectionsToUpdate, sectionsToInsert, sectionsToDelete,
            modulesToUpdate, modulesToInsert, modulesToDelete
        )
        setIsSendingEdits(false)
        props.setIsDialogOpen(false)
        if (!res.error) {
            // refresh only if no errors occurred 
            props.refreshCourse()
        }
    }

    return (
        <Dialog open={props.isDialogOpen} onClose={() => props.setIsDialogOpen(false)} maxWidth='sm' fullWidth>
            <DialogTitle>
                <DialogTitleTyp>Save Current Edits?</DialogTitleTyp>
            </DialogTitle>
            <DialogContent dividers>

                    {/* Summed Edits */}
                    {summedEdits && (summedEdits as Array<ISummedEdit>).map((summedEdit, summedEditIndex) =>
                    <div style={{display: 'flex', alignItems: 'center'}} key={summedEditIndex}>
                        {summedEdit.editType === EditType.CourseTitle &&
                        <IconHolder><TitleIcon /></IconHolder>
                        }
                        {summedEdit.editType === EditType.CourseDescription &&
                        <IconHolder><DescriptionIcon /></IconHolder>
                        }
                        {(summedEdit.editType === EditType.CourseSection || summedEdit.editType === EditType.CourseSectionTransparent) &&
                        <SectionChip size='small' label={(summedEdit.editReference as ICourseSection).sequenceId} />
                        }
                        {summedEdit.editType === EditType.CourseSectionAdd &&
                        <SectionChipAdd size='small' label={(summedEdit.editReference as ICourseSection).sequenceId} />
                        }
                        {summedEdit.editType === EditType.CourseSectionDelete &&
                        <IconHolderDelete><DeleteIcon /></IconHolderDelete>
                        }
                        <SummedEditCard summedEdit={summedEdit} />
                    </div>
                    )}

            </DialogContent>
            <DialogActions>
                <Button variant='contained' style={{textTransform: 'none'}} onClick={handleSaveEdits}>
                    {isSendingEdits ?
                    <CircularProgress size={24} style={{color: 'white'}} /> :
                    ('Save')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveEditsDialog