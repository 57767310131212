import axios, { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'

// interfaces
import { ApiRes } from '../models/Api'
import { IUser, IUserProfile } from '../models/User'

let baseURL: string
if (process.env.REACT_APP_API_BASE_URL) {
    baseURL = process.env.REACT_APP_API_BASE_URL + 'Users/'
} else {
    baseURL = '/Api/Users/'
}
const API = axios.create({ baseURL: baseURL })
if (localStorage.getItem('authToken') != null) {
    API.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('authToken')
}

// ===== admin routes =====

const adminDeleteUser = async (userId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.delete('Admin/DeleteUser/' + userId, config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminGetAllUsers = async (): Promise<ApiRes<Array<IUser>>> => {
    // need to set Authorization header manually for this request, since it is right after login
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Admin/GetAllUsers', config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminInsertUser = async (firstName: string, lastName: string, emailAddress: string, role: number, password: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/InsertUser', qs.stringify({
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            role: role,
            password: password
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminSetUserProfile = async (userId: number, firstName: string, lastName: string, emailAddress: string, isActive: boolean): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res = await API.post('Admin/SetUserProfile', qs.stringify({
            'userId': userId,
            'firstName': firstName,
            'lastName': lastName,
            'emailAddress': emailAddress,
            'isActive': isActive
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminSetUserRole = async (userId: number, roleId: number): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/SetUserRole', qs.stringify({
            'userId': userId,
            'roleId': roleId
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminSetUserPassword = async (userId: number, password: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/SetUserPassword', qs.stringify({
            'userId': userId,
            'password': password
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

// ===== general routes =====

const authenticate = async (): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Authenticate', config)
        if (res.status === 401) {
            // authToken is bad
            localStorage.removeItem('authToken')
        }
        return { status: res.status }
    } catch (e) {
        // authToken is bad
        localStorage.removeItem('authToken')
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const getUserProfile = async (): Promise<ApiRes<IUserProfile>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetUserProfile', config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const login = async (email: string, password: string, rememberMe: boolean): Promise<ApiRes<void>> => {
    try {
        const res: AxiosResponse = await API.post('Login', qs.stringify({
            'email': email,
            'password': password,
            'rememberMe': rememberMe
        }))
        // if Ok, save token
        if (res.status === 200) {
            // save authToken
            localStorage.setItem('authToken', res.data)
        }
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const setUserPassword = async (oldPassword: string, newPassword: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('SetUserPassword', qs.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const setUserProfile = async (firstName: string, lastName: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('SetUserProfile', qs.stringify({
            firstName: firstName,
            lastName: lastName
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const UsersService = {

    // === admin routes ===
    adminDeleteUser,
    adminGetAllUsers,
    adminInsertUser,
    adminSetUserProfile,
    adminSetUserRole,
    adminSetUserPassword,

    // === general routes ===
    authenticate,
    getUserProfile,
    login,
    setUserPassword,
    setUserProfile,
}

export default UsersService