/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
    Box,
    Button as MuiButton,
    CircularProgress,
    Container,
    Divider as MuiDivider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    styled,
    Tabs,
    Tab as MuiTab,
    TextField as MuiTextField,
    Typography,
} from '@mui/material'
import {
    Person as PersonIcon,
    Lock as LockIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

// interfaces
import { ApiRes } from '../models/Api'
import { IUserProfile } from '../models/User'

// services
import UsersService from '../services/UsersService'

// components
import NavBar from './shared/NavBar'
import Header from './shared/Header'
import Footer from './shared/Footer'

// Mui
const Button = styled(MuiButton)(({theme}) => ({
    textTransform: 'none',
}))
const ContentBox = styled(Box)(({theme}) => ({
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    width: '100%',
}))
const Divider = styled(MuiDivider)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
}))
const SettingsPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
}))
const SettingsPaperTitle = styled(Typography)(({theme}) => ({
    fontSize: 18,
}))
const Tab = styled(MuiTab)(({theme}) => ({
    textTransform: 'none',
    fontSize: 15,
}))
const TextField = styled(MuiTextField)(({theme}) => ({
    marginTop: '10px',
    marginBottom: '10px',
}))

const Profile = () => {

    // ===== tabs =====
    const [currTab, setCurrTab] = useState(0)
    const handleChangeTab = (newTab: number) => {
        setCurrTab(newTab)
    }

    // ===== profile info =====
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userProfile, setUserProfile] = useState<IUserProfile | null>()
    const refreshProfileInfo = async () => {
        const res: ApiRes<IUserProfile> = await UsersService.getUserProfile()
        if (res.data !== null) {
            setUserProfile(res.data)
            setFirstName(res.data?.firstName!)
            setLastName(res.data?.lastName!)
        }
    }
    useEffect(() => {
        refreshProfileInfo()
    }, [])
    const [areProfileEditsSubmitting, setAreProfileEditsSubmitting] = useState(false)
    const handleSubmitProfileChange = async () => {
        setAreProfileEditsSubmitting(true)
        const res: ApiRes<void> = await UsersService.setUserProfile(firstName, lastName)
        setAreProfileEditsSubmitting(false)
        if (!res.error) {
            refreshProfileInfo()
        }
    }

    // ===== security info =====
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [reenterNewPassword, setReenterNewPassword] = useState('')
    const [isPassowrdUpdateSubmitting, setIsPasswordUpdateSubmitting] = useState(false)
    const handleSubmitPasswordChange = async () => {
        setIsPasswordUpdateSubmitting(true)
        if (newPassword !== reenterNewPassword) {
            setIsPasswordUpdateSubmitting(false)
            return
        }
        const res: ApiRes<void> = await UsersService.setUserPassword(oldPassword, newPassword)
        if (!res.error) {
            setOldPassword('')
            setNewPassword('')
            setReenterNewPassword('')
        }
        setIsPasswordUpdateSubmitting(false)
    }
    const [isPasswordShowing, setIsPasswordShowing] = useState(false)

    // tab content
    const personalInfoContent = (
        <SettingsPaper elevation={4}>
            <SettingsPaperTitle variant='h6'>Profile Info</SettingsPaperTitle>
            <Divider />
            <div style={{height: '20px'}} />
            <form>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} label='First Name' />
                    <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} label='Last Name' />
                    {/* <TextField label='Email Address' /> */}
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow: 1}} />
                    <Button disabled={userProfile !== undefined && (firstName === userProfile?.firstName && lastName === userProfile.lastName)}
                        variant='contained' style={{marginTop: '10px'}} onClick={handleSubmitProfileChange}
                    >
                        {areProfileEditsSubmitting ?
                        <CircularProgress size={24} style={{color: 'white'}} /> :
                        ('Save')
                        }
                    </Button>
                </div>
            </form>
        </SettingsPaper>
    )
    const securityContent = (
        <SettingsPaper elevation={4}>
            <SettingsPaperTitle variant='h6'>Reset Password</SettingsPaperTitle>
            <Divider />
            <div style={{height: '20px'}} />
            <form>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextField label='Old Password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}
                        // error={incorrectUsernameOrPassword}
                        // helperText={incorrectUsernameOrPassword ? 'Incorrect username or password.' : null}
                        required type={isPasswordShowing ? 'text' : 'password'}
                        InputProps={{endAdornment:
                            <InputAdornment position='end'>
                                <IconButton onClick={() => setIsPasswordShowing(!isPasswordShowing)}>
                                    {isPasswordShowing ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField label='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                        // error={incorrectUsernameOrPassword}
                        // helperText={incorrectUsernameOrPassword ? 'Incorrect username or password.' : null}
                        required type={isPasswordShowing ? 'text' : 'password'}
                        InputProps={{endAdornment:
                            <InputAdornment position='end'>
                                <IconButton onClick={() => setIsPasswordShowing(!isPasswordShowing)}>
                                    {isPasswordShowing ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField label='Reenter New Password' value={reenterNewPassword} onChange={(e) => setReenterNewPassword(e.target.value)}
                        // error={incorrectUsernameOrPassword}
                        // helperText={incorrectUsernameOrPassword ? 'Incorrect username or password.' : null}
                        required type={isPasswordShowing ? 'text' : 'password'}
                        InputProps={{endAdornment:
                            <InputAdornment position='end'>
                                <IconButton onClick={() => setIsPasswordShowing(!isPasswordShowing)}>
                                    {isPasswordShowing ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow: 1}} />
                    <Button disabled={oldPassword === '' || newPassword === '' || reenterNewPassword === ''} variant='contained' style={{marginTop: '10px'}}
                        onClick={handleSubmitPasswordChange}
                    >
                        {isPassowrdUpdateSubmitting ?
                        <CircularProgress size={24} style={{color: 'white'}} /> :
                        ('UpdatePassword')
                        }
                    </Button>
                </div>
            </form>
        </SettingsPaper>
    )
    var currContent: JSX.Element = personalInfoContent
    switch (currTab) {
        case 0:
            currContent = personalInfoContent
            break
        case 1:
            currContent = securityContent
            break
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#F5F5F5'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title='Profile' />

            {/* Profile */}
            <Container maxWidth='md' style={{marginTop: '20px', marginBottom: '20px', height: '100%'}}>
                <Grid container style={{height: '100%'}}>

                    {/* Tabs */}
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        <div style={{height: '20px'}} />
                        <Tabs orientation='vertical' value={currTab} onChange={(e, newTab) => handleChangeTab(newTab)} >
                            <Tab icon={<PersonIcon fontSize='medium' />} iconPosition='start' label='Profile Info' />
                            <Tab icon={<LockIcon fontSize='medium' />} iconPosition='start' label='Security' />
                        </Tabs>
                    </Grid>

                    {/* Content */}
                    <Grid item xs={12} sm={12} md={9} lg={10} xl={10} style={{padding: '20px'}}>
                        {currContent}
                    </Grid>

                </Grid>
            </Container>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default Profile