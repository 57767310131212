import {
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Check as CheckIcon,
    QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material'

// interfaces
import { ISearchFilterMenuProps } from './shared'

interface ISearchFilterMenuPropsExtended extends ISearchFilterMenuProps {
    anchor: HTMLElement | null
    setAnchor: (newAnchor: HTMLElement | null) => void
}
const searchFilterMenu = (props: ISearchFilterMenuPropsExtended) => {
    return (
        <Menu open={props.anchor !== null} onClose={() => props.setAnchor(null)}
            transformOrigin={{vertical: 'top', horizontal: 'left'}} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            anchorEl={props.anchor}
        >
            <MenuList dense disablePadding>
                <div style={{display: 'flex', marginLeft: '20px', marginRight: '10px', marginBottom: '5px', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography style={{color: 'gray', fontSize: 14}}>Search Filter</Typography>
                    <div style={{flexGrow: 1}} />
                    <Tooltip title='Selected columns will be included in the search.' placement='right-end'>
                        <QuestionMarkIcon style={{color: 'gray', fontSize: 14}} />
                    </Tooltip>
                </div>
                <Divider />
                {props.states.map((state, i) =>
                <MenuItem onClick={() => props.setStates[i](!props.states[i])} key={i}>
                    <ListItemIcon>
                        {props.states[i] && <CheckIcon />}
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>{props.labels[i]}</Typography>
                    </ListItemText>
                </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}

export default searchFilterMenu