import { ICourseSection, ICourseSectionModule } from "../../../../models/Course"

// ===== courseSectionsArr =====
export enum CourseSectionsArrActionType {
    populate,
    refreshOrder,
    swapWithBelow,
    swapWithAbove,
    add,
    delete
}
export interface ICourseSectionsArrAction {
    type: CourseSectionsArrActionType
    /* populate */ populateWith?: Array<number>
    /* swapWithBelow, swapWithAbove */ currPosition?: number
    /* add */ newCourseSectionId?: number
    /* delete */ courseSectionId?: number
}

// ===== courseSectionsMap =====
export enum CourseSectionsMapActionType {
    populate
}
export interface ICourseSectionsMapAction {
    type: CourseSectionsMapActionType
    /* populate */ populateWith?: Map<number, ICourseSection>
}

// ===== courseSectionsEdits =====
export enum CourseSectionsEditsActionType {
    swapWithBelow,
    swapWithAbove,
    changeCourseSectionTitle,
    add,
    delete,
    reset
}
export interface ICourseSectionsEditsAction {
    type: CourseSectionsEditsActionType
    courseSectionId?: number
    /* changeCourseSectionTitle */ newCourseSectionTitle?: string
    /* add */
    newCourseSection?: ICourseSection
}

// ===== courseSectionModulesArr =====
export enum CourseSectionModulesArrActionType {
    populate,
    swapWithBelow,
    swapWithAbove,
    add,
    delete
}
export interface ICourseSectionModulesArrAction {
    type: CourseSectionModulesArrActionType
    /* populate */ populateWith?: Array<ICourseSectionModule>
    /* swapWithBelow, swapWithAbove */ currPosition?: number
    /* add */ newCourseSectionModule?: ICourseSectionModule
    /* delete */ courseSectionModuleId?: number
}

// ===== courseSectionModulesEdits =====
export enum CourseSectionModulesEditsActionType {
    swapWithBelow,
    swapWithAbove,
    changeCourseSectionModuleTitle,
    changeCourseSectionModuleDescription,
    changeCourseSectionModuleEmbedUrl,
    add,
    delete,
    reset
}
export interface ICourseSectionModulesEditsAction {
    type: CourseSectionModulesEditsActionType
    courseSectionModuleId?: number
    /* swapWithBelow, swapWithAbove */ courseSectionId?: number
    /* changeCourseSectionModuleTitle */ newCourseSectionModuleTitle?: string
    /* changeCourseSectionModuleDescription */ newCourseSectionModuleDescription?: string
    /* changeCourseSectionModuleEmbedUrl */ newCourseSectionModuleEmbedUrl?: string
    /* add */ newCourseSectionModule?: ICourseSectionModule
    /* delete */ modulesToShiftFromDelete?: Array<ICourseSectionModule>
}

// ===== summedEdits =====
// a summedEdit's 'editType' property determines what data type is pointed to in its 'editReference' property
export enum EditType {
    CourseTitle, // changes to courseTitle
    CourseDescription, // changes to courseDescription
    CourseSection, // any changes to a section (including new section) that is not a delete => must reference an ICourseSection
    CourseSectionModule, // any changes to a module (including new module) that is not a delete => must reference an ICourseSectionModule
    CourseSectionDelete, // delete a section => must reference courseSectionId to be deleted
    CourseSectionModuleDelete, // delete a module => must reference a courseSectionModule to be deleted
    CourseSectionAdd, // added a new section => must reference an ICourseSection
    CourseSectionModuleAdd, // added a new module => must reference an ICourseSectionModule
    CourseSectionTransparent, // when a module edit is made, but its parent section is not edited
    CourseSectionTitle, // section title edit => no reference
    CourseSectionPosition, // section position edit => no reference
    CourseSectionModuleTitle, // module title edit => no reference
    CourseSectionModuleDescription, // module description edit => no reference
    CourseSectionModulePosition, // module position edit => no reference
    CourseSectionModuleEmbedUrl, // module embedUrl edit => no reference
}

export interface ISummedEdit {
    courseSectionId?: number
    oldValue?: string
    newValue?: string
    subEdits?: Array<ISummedEdit>
    editType: EditType
    editReference?: ICourseSection | ICourseSectionModule
}