/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
    Fade,
    Skeleton,
    TextField,
} from '@mui/material'

interface IEditCourseCourseProps {
    courseTitle: string | undefined
    courseDescription: string | undefined
    sendCourseTitleEditsToParent: (courseTitle: string) => void
    sendCourseDescriptionEditsToParent: (courseDescription: string) => void
}
const EditCourseCourse = (props: IEditCourseCourseProps) => {

    // ===== courseEdits =====
    const [courseTitleEdits, setCourseTitleEdits] = useState<string>('')
    const [areCourseTitleEditsPending, setAreCourseTitleEditsPending] = useState(false)
    const handleCourseTitleEdits = (newCourseTitle: string) => {
        setCourseTitleEdits(newCourseTitle)
        setAreCourseTitleEditsPending(true)
    }
    const [courseDescriptionEdits, setCourseDescriptionEdits] = useState<string>('')
    const [areCourseDescriptionEditsPending, setAreCourseDescriptionEditsPending] = useState(false)
    const handleCourseDescriptionEdits = (newCourseDescription: string) => {
        setAreCourseDescriptionEditsPending(true)
        setCourseDescriptionEdits(newCourseDescription)
    }

    // ===== send edits to parent =====
    // sends edits to EditCourseToolbar - automatically called after user stops typing
    // for 500 ms
    useEffect(() => {
        // if no edits pending, return
        if (!areCourseTitleEditsPending || courseTitleEdits === undefined) {
            return
        }
        const sendEditsToToolbar = setTimeout(() => {
            props.sendCourseTitleEditsToParent(courseTitleEdits)
        }, 500)
        return () => clearTimeout(sendEditsToToolbar)
    }, [courseTitleEdits, areCourseTitleEditsPending, props])
    useEffect(() => {
        // if no edits pending, return
        if (!areCourseDescriptionEditsPending || courseDescriptionEdits === undefined) {
            return
        }
        const sendEditsToToolbar = setTimeout(() => {
            props.sendCourseDescriptionEditsToParent(courseDescriptionEdits)
        }, 500)
        return () => clearTimeout(sendEditsToToolbar)
    }, [courseDescriptionEdits, areCourseDescriptionEditsPending, props])

    // ===== refresh courseTitle and courseDescription =====
    useEffect(() => {
        if (props.courseTitle === undefined) {
            return
        }
        setCourseTitleEdits(props.courseTitle)
    }, [props.courseTitle])
    useEffect(() => {
        if (props.courseDescription === undefined) {
            return
        }
        setCourseDescriptionEdits(props.courseDescription)
    }, [props.courseDescription])

    return (
        <div>
            {/* Course Title */}
            <TextField variant='standard' label='Course Title' fullWidth
                value={courseTitleEdits} onChange={(e) => handleCourseTitleEdits(e.target.value)}
            />

            <div style={{height: '20px'}} />

            {/* Course Description */}
            <TextField variant='standard' multiline label='Course Description' fullWidth
                value={courseDescriptionEdits} onChange={(e) => handleCourseDescriptionEdits(e.target.value)}
            />
        </div>
    )
}

export default EditCourseCourse