/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Checkbox,
    Container,
    Divider,
    IconButton,
    LinearProgress,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    styled,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead as MuiTableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Check as CheckIcon,
} from '@mui/icons-material'

// components
import UsersAdminTableRow from './UsersAdminTableRow'
import NewUserDialog from './NewUserDialog'
import AdminTableToolbar from '../../shared/AdminTableToolbar'
import AdminHeading from '../../shared/AdminHeading'
import NoSearchResults from '../../shared/NoSearchResults'

// interfaces
import { IUser, IUserProfile } from '../../../../models/User'
import { IWebpagesUsersInRoles } from '../../../../models/Webpages'
import { ApiRes } from '../../../../models/Api'
import { IRowCountMenuProps, ISearchFilterMenuProps, ISelectColumnsMenuProps } from '../../shared/shared'

// services
import UsersService from '../../../../services/UsersService'

const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main
}))
const Table = styled(MuiTable)(({theme}) => ({
    backgroundColor: 'white',
}))
const TableHead = styled(MuiTableHead)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white'
}))
const TableHeadTyp = styled(Typography)(({theme}) => ({
    color: 'white'
}))

const UsersAdminTable = () => {

    // ===== IUserExtended =====
    interface IUserExtended extends IUser {
        userRole: IWebpagesUsersInRoles
        userProfile: IUserProfile
    }

    // ===== navigate ======
    const navigate = useNavigate()

    // ===== users =====
    const [users, setUsers] = useState(Array<IUserExtended>)
    const [isLoadingUsers, setIsLoadingUsers] = useState(true)
    const [userIndexesFilteredDefault, setUserIndexesFilteredDefault] = useState([0, 1, 2])

    // ===== selectSearchFilterMenu =====
    let selectSearchFilterMenuLabels = Array<string>()
    let selectSearchFilterMenuStates = Array<boolean>()
    let selectSearchFilterMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isSearchId, setIsSearchId] = useState(false)
    selectSearchFilterMenuLabels.push('ID')
    selectSearchFilterMenuStates.push(isSearchId)
    selectSearchFilterMenuSetStates.push(setIsSearchId)
    const [isSearchUserName, setIsSearchUserName] = useState(false)
    // selectSearchFilterMenuLabels.push('Username')
    // selectSearchFilterMenuStates.push(isSearchUserName)
    // selectSearchFilterMenuSetStates.push(setIsSearchUserName)
    const [isSearchFirstName, setIsSearchFirstName] = useState(true)
    selectSearchFilterMenuLabels.push('First Name')
    selectSearchFilterMenuStates.push(isSearchFirstName)
    selectSearchFilterMenuSetStates.push(setIsSearchFirstName)
    const [isSearchLastName, setIsSearchLastName] = useState(true)
    selectSearchFilterMenuLabels.push('Last Name')
    selectSearchFilterMenuStates.push(isSearchLastName)
    selectSearchFilterMenuSetStates.push(setIsSearchLastName)
    const [isSearchEmailAddress, setIsSearchEmailAddress] = useState(true)
    selectSearchFilterMenuLabels.push('Email Address')
    selectSearchFilterMenuStates.push(isSearchEmailAddress)
    selectSearchFilterMenuSetStates.push(setIsSearchEmailAddress)
    // populate props
    const searchFilterMenuProps: ISearchFilterMenuProps = {
        labels: selectSearchFilterMenuLabels,
        states: selectSearchFilterMenuStates,
        setStates: selectSearchFilterMenuSetStates
    }

    // ===== rowCountMenuProps =====
    const [rowCount, setRowCount] = useState(25)
    const rowCountMenuProps: IRowCountMenuProps = {
        rowCount: rowCount,
        setRowCount: setRowCount
    }

    // === search filter ===
    const [userIndexesFiltered, setUserIndexesFiltered] = useState(userIndexesFilteredDefault)
    const [searchValue, setSearchValue] = useState('')
    const [currentNumFiltered, setCurrentNumFiltered] = useState(0)
    const [totalNum, setTotalNum] = useState(0)
    const filterUsers = useCallback(() => {
        // if nothing to filter, return slice
        if (searchValue.length === 0) {
            setUserIndexesFiltered(userIndexesFilteredDefault.slice(0, rowCount))
            setCurrentNumFiltered(totalNum > rowCount ? rowCount : totalNum)
            setIsLoadingUsers(false)
            return
        }
        setIsLoadingUsers(true)
        const usersToFilter = users
        // lowercase search value
        const currSearchValueLower = searchValue.toLowerCase()
        // filter
        var newUserIndexesFiltered: Array<number> = []
        // keep track of how many rows are currently being rendered
        let currRowCount = 0
        for (let i=0; i<usersToFilter.length; ++i) {
            // get user
            const user = usersToFilter[i]
            // if user has no profile, continue
            if (!user.userProfile) {
                // continue
            }
            // match search values
            else if ((isSearchId && user.userId.toString().includes(currSearchValueLower)) ||
                (isSearchUserName && user.userName.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchLastName && user.userProfile.lastName.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchFirstName && user.userProfile.firstName.toLowerCase().includes(currSearchValueLower)) ||
                (isSearchEmailAddress && user.userProfile.emailAddress.toLowerCase().includes(currSearchValueLower))) {
                newUserIndexesFiltered.push(i)
                currRowCount++
            }
            // if max row count met, break
            if (currRowCount >= rowCount) {
                break
            }
        }
        // set courseIndexesFiltered
        setUserIndexesFiltered(newUserIndexesFiltered)
        setCurrentNumFiltered(currRowCount)
        setIsLoadingUsers(false)
    }, [totalNum, userIndexesFilteredDefault, rowCount, users, searchValue, isSearchLastName, isSearchFirstName, isSearchEmailAddress, isSearchId, isSearchUserName])
    // filter users whenever searchValue changes
    useEffect(() => {
        filterUsers()
    }, [searchValue, filterUsers])

    // ===== refreshUsers =====
    const refreshUsers = useCallback(async (setIsLoadingUsersFalseOnRefresh: boolean = false) => {
        setIsLoadingUsers(true)
        const res: ApiRes<Array<IUser>> = await UsersService.adminGetAllUsers()
        if (res.data !== undefined) {
            // populate courseIndexesFilteredDefault
            var newUserIndexesFilteredDefault: Array<number> = []
            for (let i=0; i<Object.keys(res.data).length; ++i) {
                newUserIndexesFilteredDefault.push(i)
            }
            setUserIndexesFilteredDefault(newUserIndexesFilteredDefault)
            setUserIndexesFiltered(newUserIndexesFilteredDefault)
            filterUsers()
            setUsers(res.data as Array<IUserExtended>)
            setTotalNum(res.data.length)
            if (setIsLoadingUsersFalseOnRefresh) {
                setIsLoadingUsers(false)
            }
        }
    }, [filterUsers])
    // get all users on initial load
    useEffect(() => {
        refreshUsers(true)
    }, [])

    // ===== new user =====
    const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false)

    // ===== selectColumnsMenu =====
    let selectColumnsMenuLabels = Array<string>()
    let selectColumnsMenuStates = Array<boolean>()
    let selectColumnsMenuSetStates = Array<(newValue: boolean) => void>()
    // === column options ===
    const [isTableId, setIsTableId] = useState(false)
    selectColumnsMenuLabels.push('ID')
    selectColumnsMenuStates.push(isTableId)
    selectColumnsMenuSetStates.push(setIsTableId)
    const [isTableUserName, setIsTableUserName] = useState(false)
    // selectColumnsMenuLabels.push('Username')
    // selectColumnsMenuStates.push(isTableUserName)
    // selectColumnsMenuSetStates.push(setIsTableUserName)
    const [isTableFirstName, setIsTableFirstName] = useState(true)
    selectColumnsMenuLabels.push('First Name')
    selectColumnsMenuStates.push(isTableFirstName)
    selectColumnsMenuSetStates.push(setIsTableFirstName)
    const [isTableLastName, setIsTableLastName] = useState(true)
    selectColumnsMenuLabels.push('Last Name')
    selectColumnsMenuStates.push(isTableLastName)
    selectColumnsMenuSetStates.push(setIsTableLastName)
    const [isTableEmailAddress, setIsTableEmailAddress] = useState(true)
    selectColumnsMenuLabels.push('Email Address')
    selectColumnsMenuStates.push(isTableEmailAddress)
    selectColumnsMenuSetStates.push(setIsTableEmailAddress)
    const [isTableRole, setIsTableRole] = useState(true)
    selectColumnsMenuLabels.push('Role')
    selectColumnsMenuStates.push(isTableRole)
    selectColumnsMenuSetStates.push(setIsTableRole)
    // populate props
    const selectColumnsMenuProps: ISelectColumnsMenuProps = {
        labels: selectColumnsMenuLabels,
        states: selectColumnsMenuStates,
        setStates: selectColumnsMenuSetStates
    }

    return (
        <div>
            <Container maxWidth='lg'>

                {/* Admin Heading */}
                <AdminHeading
                    title='Users'
                />

                {/* Toolbar */}
                <AdminTableToolbar
                    isNewDialogOpen={isNewUserDialogOpen} refreshTable={refreshUsers} entityName='User' newEntryTooltip='New User'
                    setSearchValue={setSearchValue} setIsLoadingTable={setIsLoadingUsers} setIsNewDialogOpen={setIsNewUserDialogOpen}
                    newDialog={<NewUserDialog isOpen={isNewUserDialogOpen} setIsNewUserDialogOpen={setIsNewUserDialogOpen} refreshUsers={refreshUsers} />}
                    currentNumFiltered={currentNumFiltered} totalNum={totalNum}
                    selectColumnsMenuProps={selectColumnsMenuProps}
                    searchFilterMenuProps={searchFilterMenuProps}
                    rowCountMenuProps={rowCountMenuProps}
                />

                <TableContainer style={{marginTop: '20px', boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', borderRadius: 5}}>
                    <Table size='small' onLoad={() => setIsLoadingUsers(false)}>

                        {/* Table Head */}
                        <TableHead>
                            <TableRow>

                                {isTableId &&
                                <TableCell padding='none' align='center' style={{padding: '5px'}}>
                                    <TableHeadTyp>ID</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableUserName &&
                                <TableCell>
                                    <TableHeadTyp>Username</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableLastName &&
                                <TableCell>
                                    <TableHeadTyp>Last</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableFirstName &&
                                <TableCell>
                                    <TableHeadTyp>First</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableEmailAddress &&
                                <TableCell>
                                    <TableHeadTyp>Email</TableHeadTyp>
                                </TableCell>
                                }
                                {isTableRole &&
                                <TableCell>
                                    <TableHeadTyp>Role</TableHeadTyp>
                                </TableCell>
                                }

                                {/* actions */}
                                <TableCell>
                                    <TableHeadTyp></TableHeadTyp>
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        {useMemo(() =>
                        <TableBody>
                            {!isLoadingUsers && userIndexesFiltered.map((index) =>
                            <UsersAdminTableRow user={users[index]} refreshUsersCB={refreshUsers} key={index}
                                isTableId={isTableId} isTableUserName={isTableUserName}
                                isTableLastName={isTableLastName} isTableFirstName={isTableFirstName} isTableEmailAddress={isTableEmailAddress}
                                isTableRole={isTableRole}
                            />
                            )}
                        </TableBody>
                    , [isLoadingUsers, users, refreshUsers, isTableId, isTableUserName, isTableLastName, isTableFirstName, isTableEmailAddress, isTableRole, userIndexesFiltered])}

                    </Table>
                    {isLoadingUsers &&
                    <LinearProgress />
                    }
                </TableContainer>

                {currentNumFiltered === 0 && searchValue !== '' &&
                <NoSearchResults entityNamePlural='Users' searchQuery={searchValue} />
                }

            </Container>

        </div>
    )
}

export default UsersAdminTable