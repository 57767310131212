import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// interfaces
import { ApiRes } from './models/Api'

// services
import UsersService from './services/UsersService'

// Home
import Home from './components/Home'

// Profile / Login
import Profile from './components/Profile'
import Login from './components/Login'

// Course
import Dash from './components/Dash'
import Course from './components/Course/Course'
import CourseSection from './components/Course/CourseSection'

// Admin
import Admin from './components/Admin/Admin'
import UsersAdmin from './components/Admin/Users/UsersAdmin'
import CoursesAdmin from './components/Admin/Courses/CoursesAdmin'
import QAndAAdmin from './components/Admin/QAndA/QAndAAdmin'
import EditCourse from './components/Admin/Courses/EditCourse/EditCourse'

const theme = createTheme({
    palette: {
        primary: {
            main: '#19446e',
            light: '#e4f0f5',
        },
        secondary: {
            dark: '#431c00',
            main: '#6e4419',
            light: '#a97f58'
        },
        success: {
            dark: '#388e3c',
            main: '#66bb6a',
            light: '#81c784',
        }
    }
})

const App = () => {

    // ===== navigate ======
    const navigate = useNavigate()

    // ===== authenticate ======
    // on app reload, check if authToken is valid
    useEffect(() => {
        const authenticate = async () => {
            const res: ApiRes<void> = await UsersService.authenticate()
            // if bad authToken, route to home page
            if (res.status === 401) {
                navigate('/login')
            }
        }
        if (localStorage.getItem('authToken')) {
            authenticate()
        }
    }, [navigate])

    return (
        <div style={{ width: '100%', height: '100%', position: 'absolute', padding: 0, top: 0, left: 0 }}>
            <ThemeProvider theme={theme}>
                <Routes>

                    {/* Home Route */}
                    <Route path='/' element={<Home />} />

                    {/* Profile / Login Routes */}
                    <Route path='/login' element={<Login />} />
                    <Route path='/profile' element={<Profile />} />

                    {/* Course Routes */}
                    <Route path='/learn' element={<Dash />} />
                    <Route path='/learn/:courseId' element={<Course />} />
                    <Route path='/learn/:courseId/:sectionSequenceId/:moduleSequenceId' element={<CourseSection />} />

                    {/* Admin Routes */}
                    <Route path='/admin' element={<Admin />} />
                    <Route path='/admin/users' element={<UsersAdmin />} />
                    <Route path='/admin/courses' element={<CoursesAdmin />} />
                    <Route path='/admin/courses/:courseId' element={<EditCourse />} />
                    <Route path='/admin/qAndA' element={<QAndAAdmin />} />

                </Routes>
            </ThemeProvider>
        </div>
  )
}

export default App