import React from 'react'
import {
    Box as MuiBox,
    Container,
    styled,
    Typography as MuiTypography,
    useMediaQuery,
    useTheme,
    Link,
} from '@mui/material'

// layout constants
const FONT_SIZE = 14

const Box = styled(MuiBox)(({theme}) => ({
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: theme.palette.primary.main,
}))
const Copyright = styled(MuiTypography)(({theme}) => ({
    color: 'white',
    fontSize: FONT_SIZE,
}))
const SiteLinkA = styled(Link)(({ theme }) => ({
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    textDecorationColor: 'white',
    fontSize: '14px',
    '&:hover': {
        color: theme.palette.primary.light,
        textDecorationColor: theme.palette.primary.light,
    }
}))

const Footer = () => {

    // ===== isMobile ======
    const theme = useTheme()
    const isDeviceXs = useMediaQuery(theme.breakpoints.down('xs'))
    const isDeviceSm = useMediaQuery(theme.breakpoints.down('sm'))
    const isMobile = isDeviceXs || isDeviceSm

    return (
        <Box>
            {isMobile ?

            <Container>
                {/* Mobile */}
                <Copyright>Copyright © 2024 The Inteq Group, Inc. | All Rights Reserved |&nbsp;</Copyright>
                <div style={{height: '5px'}} />
                <SiteLinkA href='https://inteqgroup.com' target='_blank' rel="noreferrer">
                    www.inteqgroup.com
                </SiteLinkA>
            </Container> :

            <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                {/* Desktop */}
                <Copyright>Copyright © 2024 The Inteq Group, Inc. | All Rights Reserved |&nbsp;</Copyright>
                <SiteLinkA href='https://inteqgroup.com' target='_blank' rel="noreferrer">
                    www.inteqgroup.com
                </SiteLinkA>
            </Container>
            }
        </Box>
    )
}

export default Footer