/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Box,
    ButtonBase,
    Card,
    Chip,
    Divider,
    Container,
    Grid,
    Grow,
    Link,
    Paper,
    Skeleton,
    StepButton as MuiStepButton,
    Stepper as MuiStepper,
    Step as MuiStep,
    styled,
    Tab as MuiTab,
    Tabs as MuiTabs,
    Typography,
} from '@mui/material'
import {
    DoubleArrow as DoubleArrowIcon,
} from '@mui/icons-material'
import ReactPlayer from 'react-player'

// interfaces
import {
    IStudentCourse,
    IStudentCourseSection,
    IStudentCourseSectionModule,
    IStudentCourseSectionModuleComponent,
    IStudentCourseSectionModuleComponentEmbed,
} from '../../models/StudentCourse'
import { ICourse } from '../../models/Course'
import { ApiRes } from '../../models/Api'

// services
import StudentCoursesService from '../../services/StudentCoursesService'
import CoursesService from '../../services/CoursesService'

// components
import NavBar from '../shared/NavBar'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import CourseBreadcrumbs from './CourseBreadcrumbs'
import TabContentQA from './TabContentQA'
import { ICourseSectionModule } from '../../models/Course'

const ACard = styled(Card)(({theme}) => ({
    borderRadius: 25,
}))
const Credit = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const QCard = styled(Card)(({theme}) => ({
    borderRadius: 25,
    backgroundColor: theme.palette.primary.light,
}))
const QATypography = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}))
const NextSectionButton = styled(ButtonBase)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '100%',
    color: 'white',
    padding: '10px',
    transition: '0.125s',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
    },
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.dark,
        // color: theme.palette.primary.main,
        transition: '0.125s',
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
        opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
        opacity: 0,
        },
        '& .MuiTypography-root': {
        },
    },
}))
const SectionTitlePaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '100%',
    margin: 0,
    marginBottom: '20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: 0,
    height: '75px'
}))
const Step = styled(MuiStep)(({theme}) => ({
    '& .MuiStepLabel-root .Mui-active': {
        color: theme.palette.primary.light,
    },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
        fill: theme.palette.primary.main,
        fontSize: 15
    },
    '& .MuiStepLabel-root .Mui-completed': {
        color: theme.palette.primary.light,
        opacity: 0.75,
    },
    '& .MuiStepLabel-label .Mui-completed .MuiStepIcon-text': {
        color: 'white',
    },
}))
const StepButton = styled(MuiStepButton)(({theme}) => ({

}))
const Stepper = styled(MuiStepper)(({theme}) => ({
}))
const Tabs = styled(MuiTabs)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
}))
const Tab = styled(MuiTab)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    transition: '0.25s',
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.main,
    },
}))
const TitleChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight: '5px',
}))

const CourseSection = () => {

    // ===== params =====
    const { courseId, sectionSequenceId, moduleSequenceId } = useParams()
    const sectionSequenceIdNum = parseInt(sectionSequenceId as string)
    const moduleSequenceIdNum = parseInt(moduleSequenceId as string)

    // ===== playerRef =====
    const playerRef = useRef<ReactPlayer | null>(null)

    // ===== navigate ======
    const navigate = useNavigate()

    // ===== studentCourse =====
    const [studentCourse, setStudentCourse] = useState<IStudentCourse>()
    useEffect(() => {
        if (courseId === undefined ) {
            return
        }
        const getStudentCourse = async () => {
            const res: ApiRes<IStudentCourse> = await StudentCoursesService.getStudentCourse(parseInt(courseId as string))
            if (!res.error) {
                setStudentCourse(res.data)
            }
        }
        getStudentCourse()
    }, [courseId, sectionSequenceId, moduleSequenceId])

    // ===== course =====
    const [course, setCourse] = useState<ICourse>()
    useEffect(() => {
        if (courseId === undefined) {
            return
        }
        const getCourse = async () => {
            const res: ApiRes<ICourse> = await CoursesService.getCourse(parseInt(courseId as string))
            if (!res.error) {
                setCourse(res.data)
            }
        }
        getCourse()
    }, [courseId])

    // ===== activeTav =====
    const [activeTab, setActiveTab] = useState(0)
    const changeTab = (e: React.SyntheticEvent, newTab: number) => {
        setActiveTab(newTab)
    }

    // ===== updateCurrentCourseSection =====
    const updateCurrentCourseSection = async () => {
        if (studentCourse === undefined) {
            return
        }
        // check if currentSectionId needs to be updated
        if (sectionSequenceIdNum-1 === studentCourse.currentSectionNum!) {
            await StudentCoursesService.updateUserCurrentSection(
                studentCourse.studentCourseId!,
                studentCourse.studentCourseSections[sectionSequenceIdNum].courseSectionId!
            )
        }
    }

    // ===== updateVideoPosition =====
    // called on pause, end, and every 15 minutes
    const updateVideoPosition = async () => {
        if (studentCourse === undefined || !playerRef.current) {
            return
        }
        let scs: IStudentCourseSection = studentCourse?.studentCourseSections[sectionSequenceIdNum]
        let scsm: IStudentCourseSectionModule = scs.studentCourseSectionModules[moduleSequenceIdNum]
        let scsmc: IStudentCourseSectionModuleComponent = scsm.studentCourseSectionModuleComponents![0]
        let scsmce: IStudentCourseSectionModuleComponentEmbed = scsmc!.studentCourseSectionModuleComponentEmbed!
        await StudentCoursesService.updateVideoPosition(scsmce.studentCourseSectionModuleComponentEmbedId, Math.round(playerRef.current.getCurrentTime()))
    }

    // ===== onVideoLoad =====
    // seek to correct position, setIsVideoLoading
    const [isVideoLoading, setIsVideoLoading] = useState(true)
    const onVideoLoad = () => {
        // updateCurrentCourseSection
        updateCurrentCourseSection()
        if (studentCourse === undefined || !playerRef.current) {
            return
        }
        let scs: IStudentCourseSection = studentCourse?.studentCourseSections[sectionSequenceIdNum]
        let scsm: IStudentCourseSectionModule = scs.studentCourseSectionModules[moduleSequenceIdNum]
        let scsmc: IStudentCourseSectionModuleComponent = scsm.studentCourseSectionModuleComponents![0]
        let scsmce: IStudentCourseSectionModuleComponentEmbed = scsmc!.studentCourseSectionModuleComponentEmbed!
        // if out of bounds, return
        if (scsmce.videoPosition <= 0 || scsmce.videoPosition > playerRef.current.getDuration()) {
            return
        }
        // if already at current, return
        if (scsmce.videoPosition === playerRef.current.getCurrentTime()) {
            return
        }
        // seek
        playerRef.current.seekTo(scsmce.videoPosition)
        // setIsVideoLoading
        setIsVideoLoading(false)
    }

    // ===== isHoveringNextSection =====
    const [isHoveringNextSection, setIsHoveringNextSection] = useState(false)

    // ===== videoContainerHeight =====
    const [videoContainerHeight, setVideoContainerHeight] = useState<number>(0)
    const videoContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const handleResize = () => {
            if (videoContainerRef.current) {
                setVideoContainerHeight(videoContainerRef.current.clientHeight);
            }
        }
        handleResize()
        // add event listener for resize event
        window.addEventListener('resize', handleResize);
        // remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', minHeight: '100%'}}>

            {/* NavBar */}
            <NavBar />

            {/* Header */}
            <Header title={course?.courseTitle} />

            {/* Courses Breadcrumbs */}
            <CourseBreadcrumbs
                courseTitle={course?.courseTitle}
                courseId={course?.courseId}
                sectionTitle={course?.courseSections![sectionSequenceIdNum].courseSectionTitle}
                sectionNumber={sectionSequenceIdNum}
            />

            {/* Course Section */}
            <Container maxWidth='xl' style={{display: 'flex', marginBottom: '100px', flexDirection: 'column', height: '100%'}}>

                <SectionTitlePaper>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', flexGrow: 1, padding: '10px', paddingBottom: '12.5px'}}>
                        <div style={{display: 'flex', marginBottom: '5px'}}>
                            {/* <TitleChip size='small' label={1} /> */}
                            <Typography>
                                {course ? course.courseSections![sectionSequenceIdNum].courseSectionTitle : <Skeleton />}
                            </Typography>
                        </div>
                        {/* <Typography variant='h5'>Part 1</Typography> */}
                        <div style={{display: 'flex', marginTop: '5px'}}>
                            <Stepper nonLinear activeStep={moduleSequenceIdNum} style={{flexGrow: 1}}>
                                {course && course.courseSections![sectionSequenceIdNum].courseSectionModules!.map((module: ICourseSectionModule, index: number) =>
                                    (module && module.courseSectionModuleComponents![0].courseSectionModuleComponentEmbed.embedUrl ?
                                    <Step key={index} completed={false}>
                                        <StepButton disableRipple onClick={() => navigate('/learn/' + courseId + '/' + sectionSequenceIdNum + '/' + index)}>
                                            {module.courseSectionModuleTitle}
                                        </StepButton>
                                    </Step> : null
                                    )
                                )}
                            </Stepper>
                            <div style={{flexGrow: 2}} />
                        </div>
                    </div>
                    <Divider orientation='vertical' style={{height: '80%'}} />
                    {course && course.courseSections && sectionSequenceIdNum+1 < course.courseSections.length &&
                    <NextSectionButton onClick={() => navigate('/learn/' + courseId + '/' + (sectionSequenceIdNum+1) + '/0')}
                        onMouseEnter={() => setIsHoveringNextSection(true)} onMouseLeave={() => setIsHoveringNextSection(false)}
                    >
                        <Typography style={{fontSize: 15, wordBreak: 'break-word'}}>
                            {course!.courseSections![sectionSequenceIdNum+1].courseSectionTitle}
                        </Typography>
                        <Grow in={isHoveringNextSection}><DoubleArrowIcon fontSize='small' /></Grow>
                    </NextSectionButton>
                    }
                </SectionTitlePaper>

                <Grid container spacing={5} style={{marginBottom: '10px', height: '100%', boxSizing: 'border-box'}}>

                    {/* Video */}
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} alignItems='center' justifyContent='center' style={{width: '100%'}}>
                        <div ref={videoContainerRef} style={{position: 'relative', paddingTop: '56.25%'}}>
                            {isVideoLoading &&
                            <Skeleton variant='rectangular' 
                                style={{width: '100%', height: '100%', margin: 0, padding: 0, position: 'absolute', top: 0, left: 0}}
                            />
                            }
                            {course &&
                            <ReactPlayer width='100%' height='100%' style={{position: 'absolute', top: 0, left: 0}} controls ref={playerRef}
                                url={'//fast.wistia.net/embed/iframe/' + course!.courseSections![sectionSequenceIdNum].courseSectionModules![moduleSequenceIdNum].courseSectionModuleComponents![0].courseSectionModuleComponentEmbed.embedUrl}
                                progressInterval={900000} onEnded={updateVideoPosition}
                                onProgress={updateVideoPosition} onPause={updateVideoPosition} onReady={onVideoLoad}
                            />
                            }
                        </div>
                    </Grid>

                    {/* Side Tabs */}
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} alignItems='center' justifyContent='center'
                        style={{height: '100%'}}
                    >
                        <Box style={{display: 'flex', flexDirection: 'column', height: videoContainerHeight, boxSizing: 'border-box'}}>
                            <Tabs value={activeTab} onChange={changeTab}>
                                {/* <Tab label='Notes'></Tab> */}
                                <Tab label='Info'></Tab>
                                <Tab label='Q&A'></Tab>
                            </Tabs>
                            <Paper style={{flexGrow: 1, height: '100%', overflow: 'auto'}}>

                                {/* Notes */}

                                {/* Info */}
                                {activeTab === 0 && course &&
                                <div style={{padding: '10px'}}>
                                    <Typography>
                                        {course.courseSections![sectionSequenceIdNum].courseSectionModules![moduleSequenceIdNum].courseSectionModuleDescription ?? ''}
                                    </Typography>
                                </div>
                                }

                                {/* Q&A */}
                                {activeTab === 1 &&
                                <TabContentQA
                                    studentCourseId={studentCourse?.studentCourseId}
                                    studentCourseSectionId={studentCourse?.studentCourseSections[parseInt(sectionSequenceId as string)].studentCourseSectionId}
                                />
                                }

                            </Paper>
                        </Box>
                    </Grid>
                </Grid>

                <Credit>Instructor: James Proctor</Credit>

            </Container>

            <div style={{flexGrow: 1}} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default CourseSection