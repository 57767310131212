/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography
} from '@mui/material'

// interfaces
import { ApiRes } from '../../../../models/Api'

// services
import CoursesService from '../../../../services/CoursesService'

// mui
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))

interface NewCourseDialogProps {
    isOpen: boolean
    setIsNewCourseDialogOpen: (isOpen: boolean) => void
    refreshCourses: (setIsLoadingCoursesFalseOnRefresh: boolean) => void
}
const NewCourseDialog = (props: NewCourseDialogProps) => {

    const [newCourseTitle, setNewCourseTitle] = useState<string>('')
    const handleNewCourseTitle = (newValue: string) => {
        setNewCourseTitle(newValue)
    }
    const [isNewCourseSubmitting, setIsNewCourseSubmitting] = useState(false)
    const handleNewCourseSubmit = async () => {
        setIsNewCourseSubmitting(true)
        const res: ApiRes<void> = await CoursesService.adminInsertCourse(newCourseTitle)
        setIsNewCourseSubmitting(false)
        props.refreshCourses(true)
        props.setIsNewCourseDialogOpen(false)
        setNewCourseTitle('')
    }

    return (
        <Dialog open={props.isOpen} maxWidth='sm' fullWidth onClose={() => props.setIsNewCourseDialogOpen(false)}>
            <DialogTitle>
                <ColorTyp style={{fontSize: 20}}>New Course</ColorTyp>
            </DialogTitle>
            <DialogContent>
                <TextField fullWidth label='Course Title' value={newCourseTitle}
                    onChange={(e) => handleNewCourseTitle(e.target.value)} style={{marginTop: '5px', marginBottom: '5px'}}
                />

                <DialogActions style={{margin: 0, padding: 0, marginTop: '10px'}}>
                    <div style={{flexGrow: 1}} />
                    <Button variant='contained' style={{textTransform: 'none', marginTop: '15px'}} onClick={handleNewCourseSubmit}>
                        {isNewCourseSubmitting ?
                        <CircularProgress size={20} style={{color: 'white'}} /> :
                        <Typography style={{fontSize: 14}}>Create Course</Typography>
                        }
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default NewCourseDialog