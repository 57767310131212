import axios, { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'

// interfaces
import { ApiRes } from '../models/Api'
import { ICourse, ICourseSection, ICourseSectionModule, } from '../models/Course'

let baseURL: string
if (process.env.REACT_APP_API_BASE_URL) {
    baseURL = process.env.REACT_APP_API_BASE_URL + 'Courses/'
} else {
    baseURL = '/Api/Courses/'
}
const API = axios.create({ baseURL: baseURL })
if (localStorage.getItem('authToken') != null) {
    API.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('authToken')
}

// ===== admin routes =====

const adminDeleteCourse = async (courseId: number): Promise<ApiRes<Array<ICourse>>> => {
    // need to set Authorization header manually for this request, since it is right after login
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.delete('Admin/DeleteCourse/' + courseId, config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminGetAllCourses = async (): Promise<ApiRes<Array<ICourse>>> => {
    // need to set Authorization header manually for this request, since it is right after login
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Admin/GetAllCourses', config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminGetCourse = async (courseId: number): Promise<ApiRes<ICourse>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('Admin/GetCourse/' + courseId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminInsertCourse = async (courseTitle: string): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/InsertCourse', qs.stringify({
            courseTitle: courseTitle
        }), config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const adminUpdateCourse = async (courseId: number, courseTitle: string, courseDescription: string,
    sectionsToUpdate: Array<ICourseSection>, sectionsToInsert: Array<ICourseSection>, sectionsToDelete: Array<ICourseSection>,
    modulesToUpdate: Array<ICourseSectionModule>, modulesToInsert: Array<ICourseSectionModule>, modulesToDelete: Array<ICourseSectionModule>): Promise<ApiRes<void>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.post('Admin/UpdateCourse', {
            // course
            courseId: courseId,
            courseTitle: courseTitle,
            courseDescription: courseDescription,
            // sections
            sectionsToUpdate: sectionsToUpdate,
            sectionsToInsert: sectionsToInsert,
            sectionsToDelete: sectionsToDelete,
            // modules
            modulesToUpdate: modulesToUpdate,
            modulesToInsert: modulesToInsert,
            modulesToDelete: modulesToDelete
        }, config)
        return { status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

// ===== general routes =====

const getCourse = async (courseId: number): Promise<ApiRes<ICourse>> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
    }
    try {
        const res: AxiosResponse = await API.get('GetCourse/' + courseId, config)
        return { data: res.data, status: res.status }
    } catch (e) {
        console.log(e)
        return {
            error: e,
            status: e instanceof AxiosError && e.response ? e.response.status : 500
        }
    }
}

const CoursesService = {

    // === admin routes ===
    adminDeleteCourse,
    adminGetAllCourses,
    adminGetCourse,
    adminInsertCourse,
    adminUpdateCourse,

    // === general routes ===
    getCourse,
}

export default CoursesService