/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Divider,
    IconButton,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography as MuiTypography,
} from '@mui/material'
import {
    Add as AddIcon,
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material'

// components
import EditCourseSectionModule from './EditCourseSectionModule'

// interfaces
import { ICourseSection, ICourseSectionModule } from '../../../../models/Course'
import {
    CourseSectionsEditsActionType, ICourseSectionsEditsAction,
    CourseSectionModulesEditsActionType, ICourseSectionModulesEditsAction,
    CourseSectionModulesArrActionType, ICourseSectionModulesArrAction,
} from './shared'
import { cloneDeep } from 'lodash'

// mui
const SectionChip = styled(Chip)(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginRight: '10px',
    '&:hover': {
        cursor: 'pointer',
    }
}))
const Typography = styled(MuiTypography)(({theme}) => ({
    color: theme.palette.primary.main,
}))

interface IEditCourseSectionProps {
    isFirst: boolean
    isLast: boolean
    setCourseSectionsEdits: (action: ICourseSectionsEditsAction) => void
    setCourseSectionModulesEdits: (action: ICourseSectionModulesEditsAction) => void
    section: ICourseSection
    swapSectionWithBelow: (e: React.MouseEvent, courseSectionId: number) => void
    swapSectionWithAbove: (e: React.MouseEvent, courseSectionId: number) => void
    courseSectionModulesEdits: Map<number, ICourseSectionModule> | null
    handleAddCourseSectionModule: (courseSectionId: number, newModulePosition: number) => ICourseSectionModule | null
    handleCourseSectionDelete: (e: React.MouseEvent, courseSectionId: number) => void
    handleCourseSectionModuleDelete: (courseSectionModuleId: number, modulesToShiftFromDelete: Array<ICourseSectionModule>) => void
    index: number
}
const EditCourseSection = (props: IEditCourseSectionProps) => {

    // ===== section edits =====
    const [sectionTitleEdits, setSectionTitleEdits] = useState((props.section && props.section.courseSectionTitle) ?? '')
    const [areSectionEditsPending, setAreSectionEditsPending] = useState(false)
    const handleSectionTitleEdits = (newValue: string) => {
        setSectionTitleEdits(newValue)
        setAreSectionEditsPending(true)
    }

    // ===== courseSectionModulesArr =====
    // Array<ICourseSectionModule> - unlike courseSectionsArr, this array holds not just the
    // courseSectionModuleId, but the entire module object. This adjustment was made to simplify
    // the data structure in this component, since there will likely be only a few modules per section
    const courseSectionModulesArrReducer = (state: Array<ICourseSectionModule> | undefined, action: ICourseSectionModulesArrAction): Array<ICourseSectionModule> | undefined => {
        if (action.type === CourseSectionModulesArrActionType.populate) {
            return action.populateWith!
        }
        if (state === undefined) {
            return undefined
        }
        let currState: Array<ICourseSectionModule> = [...state as Array<ICourseSectionModule>]
        switch (action.type) {
            // === swapWithBelow ===
            case CourseSectionModulesArrActionType.swapWithBelow:
                if (action.currPosition === undefined) {
                    return currState as Array<ICourseSectionModule>
                }
                [currState![action.currPosition!], currState![action.currPosition!+1]] = [currState![action.currPosition!+1], currState![action.currPosition!]]
                return currState as Array<ICourseSectionModule>
            // === swapWithAbove ===
            case CourseSectionModulesArrActionType.swapWithAbove:
                if (action.currPosition === null || action.currPosition === undefined) {
                    return currState as Array<ICourseSectionModule>
                }
                [currState![action.currPosition!-1], currState![action.currPosition!]] = [currState![action.currPosition!], currState![action.currPosition!-1]]
                return currState as Array<ICourseSectionModule>
            // === add ===
            case CourseSectionModulesArrActionType.add:
                if (action.newCourseSectionModule === undefined || action.newCourseSectionModule === null) {
                    return currState
                }
                currState.push(action.newCourseSectionModule)
                return currState
            // === delete ===
            case CourseSectionModulesArrActionType.delete:
                if (action.courseSectionModuleId === null || action.courseSectionModuleId === undefined) {
                    return currState
                }
                // find currPosition
                let currPosition: number | null = null
                for (let i=0; i<currState.length; ++i) {
                    if (currState[i].courseSectionModuleId === action.courseSectionModuleId) {
                        // poition found
                        currPosition = i
                        break
                    }
                }
                // if position not found, return
                if (currPosition === null) {
                    return currState
                }
                // splice
                currState.splice(currPosition, 1)
                return currState
        }
    }
    const [courseSectionModulesArr, setCourseSectionModulesArr] = useReducer(courseSectionModulesArrReducer, undefined)

    // ===== swap module handlers =====
    // (click event: React.MouseEvent, courseSectionModuleId: number)
    const swapModuleWithBelow = useCallback((e: React.MouseEvent, courseSectionModuleId: number): void => {
        // if courseSectionModulesArr is undefined, error
        if (!courseSectionModulesArr) {
            return
        }
        // get modulePositions
        let thisModulePosition: number | undefined = props.courseSectionModulesEdits?.get(courseSectionModuleId)?.sequenceId
        if (thisModulePosition === null || thisModulePosition === undefined) {
            for (let i=0; i<courseSectionModulesArr.length; ++i) {
                if (courseSectionModulesArr[i].courseSectionModuleId === courseSectionModuleId) {
                    thisModulePosition = courseSectionModulesArr[i].sequenceId
                    break
                }
            }
        }
        // if can't find module position or module is already last, return
        if (thisModulePosition === undefined || thisModulePosition >= courseSectionModulesArr.length-1) {
            return
        }
        // swap
        props.setCourseSectionModulesEdits({
            type: CourseSectionModulesEditsActionType.swapWithBelow,
            courseSectionModuleId: courseSectionModuleId,
            courseSectionId: props.section.courseSectionId
        })
        setCourseSectionModulesArr({
            type: CourseSectionModulesArrActionType.swapWithBelow,
            currPosition: thisModulePosition
        })
    }, [courseSectionModulesArr])
    const swapModuleWithAbove = useCallback((e: React.MouseEvent, courseSectionModuleId: number): void => {
        // if courseSectionModulesArr is undefined, error
        if (!courseSectionModulesArr) {
            return
        }
        // get modulePositions
        let thisModulePosition: number | undefined = props.courseSectionModulesEdits?.get(courseSectionModuleId)?.sequenceId
        if (thisModulePosition === null || thisModulePosition === undefined) {
            for (let i=0; i<courseSectionModulesArr.length; ++i) {
                if (courseSectionModulesArr[i].courseSectionModuleId === courseSectionModuleId) {
                    thisModulePosition = courseSectionModulesArr[i].sequenceId
                    break
                }
            }
        }
        // if can't find module position or module is already first, return
        if (thisModulePosition === undefined || thisModulePosition <= 0) {
            return
        }
        // swap
        props.setCourseSectionModulesEdits({
            type: CourseSectionModulesEditsActionType.swapWithAbove,
            courseSectionModuleId: courseSectionModuleId,
            courseSectionId: props.section.courseSectionId
        })
        setCourseSectionModulesArr({
            type: CourseSectionModulesArrActionType.swapWithAbove,
            currPosition: thisModulePosition
        })
    }, [courseSectionModulesArr])

    // ===== handleCourseSectionModuleDelete =====
    const handleCourseSectionModuleDelete = useCallback((courseSectionModuleId: number) => {
        if (courseSectionModulesArr === undefined) {
            return
        }
        // find position in courseSectionModulesArr
        let currModulePosition: number | null = null
        for (let i=0; i<courseSectionModulesArr?.length; ++i) {
            if (courseSectionModulesArr[i].courseSectionModuleId === courseSectionModuleId) {
                currModulePosition = courseSectionModulesArr[i].sequenceId
                break
            }
        }
        // if position not found, return
        if (currModulePosition === null) {
            return
        }
        // slice array to send to parent
        let arrayToAdjust = cloneDeep(courseSectionModulesArr.slice(currModulePosition))
        // send to parent
        props.handleCourseSectionModuleDelete(courseSectionModuleId, arrayToAdjust)
        // send to courseSectionModulesArr
        setCourseSectionModulesArr({
            type: CourseSectionModulesArrActionType.delete,
            courseSectionModuleId: courseSectionModuleId
        })
    }, [courseSectionModulesArr])

    // ===== send edits to parent =====
    // sends edits to parent - automatically called after user stops typing
    // for 500 ms
    useEffect(() => {
        // if no edits pending, return
        if (!areSectionEditsPending) {
            return
        }
        const sendEditsToParent = setTimeout(() => {
          props.setCourseSectionsEdits({
            type: CourseSectionsEditsActionType.changeCourseSectionTitle,
            courseSectionId: props.section.courseSectionId,
            newCourseSectionTitle: sectionTitleEdits
          })
        }, 500)
        return () => clearTimeout(sendEditsToParent)
    }, [sectionTitleEdits, areSectionEditsPending])

    // ===== isHovering (on the accordion summary only) =====
    const [isHoveringSummary, setIsHoveringSummary] = useState(false)

    // ===== handleAddCourseSectionModule =====
    const handleAddCourseSectionModule = () => {
        if (courseSectionModulesArr === undefined) {
            return
        }
        let newCourseSectionModule: ICourseSectionModule | null = props.handleAddCourseSectionModule(props.section.courseSectionId, courseSectionModulesArr.length)
        // if success, add to courseSectionModulesArr
        if (newCourseSectionModule !== null) {
            setCourseSectionModulesArr({
                type: CourseSectionModulesArrActionType.add,
                newCourseSectionModule: newCourseSectionModule
            })
        }
    }

    // ===== populate data =====
    useEffect(() => {
        if (props.section === null || props.section === undefined) {
            // error / section not loaded yet
            return
        }
        // courseSectionModulesArr
        let newCourseSectionModulesArr = new Array<ICourseSectionModule>()
        props.section.courseSectionModules?.forEach(module => {
            newCourseSectionModulesArr.push(module)
        })
        setCourseSectionModulesArr({
            type: CourseSectionModulesArrActionType.populate,
            populateWith: newCourseSectionModulesArr
        })
    }, [props.section])

    // ===== memoizedEditCourseSectionModules =====
    const memoizedEditCourseSectionModules = useMemo(() => 
        <Stack style={{marginLeft: '20px'}}>
            {courseSectionModulesArr !== undefined && courseSectionModulesArr.map((module: any, moduleIndex: number) =>
            <EditCourseSectionModule setCourseSectionModulesEdits={props.setCourseSectionModulesEdits} module={module}
                swapModuleWithAbove={swapModuleWithAbove} swapModuleWithBelow={swapModuleWithBelow}
                isFirst={moduleIndex===0} isLast={moduleIndex===courseSectionModulesArr.length-1} key={module.courseSectionModuleId}
                handleCourseSectionModuleDelete={handleCourseSectionModuleDelete}
            />
            )}
        </Stack>
    , [courseSectionModulesArr, handleCourseSectionModuleDelete, swapModuleWithAbove, swapModuleWithBelow, props.setCourseSectionModulesEdits])

    return (
        <Accordion>
            <AccordionSummary style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'}}
                onMouseOver={() => setIsHoveringSummary(true)} onMouseOut={() => setIsHoveringSummary(false)}
            >
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <SectionChip size='small' label={props.index} />
                    <Typography>
                        {props.section ? props.section.courseSectionTitle : ''}
                    </Typography>
                </div>
                <div style={{flexGrow: 1}} />
                {isHoveringSummary &&
                <div style={{display: 'flex'}}>
                    {!props.isFirst &&
                    <Tooltip title='Move Up' style={{margin: 0}}>
                        <IconButton onClick={(e) => props.swapSectionWithAbove(e, props.section.courseSectionId)}>
                            <ArrowUpwardIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    }
                    {props.isLast ?
                    <div style={{width: '35px'}} /> :
                    <Tooltip title='Move Down' style={{margin: 0}}>
                        <IconButton onClick={(e) => props.swapSectionWithBelow(e, props.section.courseSectionId)}>
                            <ArrowDownwardIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title='Delete' style={{margin: 0}}>
                        <IconButton onClick={(e) => props.handleCourseSectionDelete(e, props.section.courseSectionId)}>
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
                }
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                <TextField variant='standard' label='Section Title' style={{marginBottom: '20px'}} fullWidth
                    value={sectionTitleEdits}
                    onChange={(e) => handleSectionTitleEdits(e.target.value)}
                />

                {/* Course Modules */}
                <Typography style={{marginLeft: '20px'}}>Modules</Typography>
                {/* <Stack style={{marginLeft: '20px'}}>
                    {courseSectionModulesArr !== undefined && courseSectionModulesArr.map((module: any, moduleIndex: number) =>
                    <EditCourseSectionModule setCourseSectionModulesEdits={props.setCourseSectionModulesEdits} module={module}
                        swapModuleWithAbove={swapModuleWithAbove} swapModuleWithBelow={swapModuleWithBelow}
                        isFirst={moduleIndex===0} isLast={moduleIndex===courseSectionModulesArr.length-1} key={module.courseSectionModuleId}
                        handleCourseSectionModuleDelete={handleCourseSectionModuleDelete}
                    />
                    )}
                </Stack> */}
                {memoizedEditCourseSectionModules}
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px', marginTop: '0px'}}>
                    <div style={{width: '100%'}}>
                        <Tooltip title='Add Module'>
                            <IconButton onClick={handleAddCourseSectionModule}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

            </AccordionDetails>
        </Accordion>
    )
}

export default EditCourseSection