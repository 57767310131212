import { useMemo, useState } from 'react'
import {
    Button,
    IconButton as MuiIconButton,
    Paper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Replay as ReplayIcon,
} from '@mui/icons-material'

// components
import SaveEditsDialog from './SaveEditsDialog'

// interfaces
import { ICourse, ICourseSection, ICourseSectionModule } from '../../../../models/Course'

// mui
const IconButton = styled(MuiIconButton)(({theme}) => ({
    color: theme.palette.primary.main
}))
const ToolbarPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingTop: '7.5px',
    paddingRight: '15px',
    paddingBottom: '7.5px',
    width: '100%',
    color: theme.palette.primary.main,
}))

interface EditCourseToolbarProps {
    readonly course: ICourse | null
    readonly courseTitleEditsToToolbar: string | null
    readonly courseDescriptionEditsToToolbar: string | null
    readonly courseSectionsEdits: Map<number, ICourseSection>
    readonly courseSectionModulesEdits: Map<number, ICourseSectionModule>
    readonly courseSectionsMap: Map<number, ICourseSection> | undefined
    readonly courseSectionModulesMap: Map<number, ICourseSectionModule> | undefined
    readonly courseSectionsDeletes: Set<number>
    readonly courseSectionModulesDeletes: Set<number>
    readonly refreshCourse: () => void
}
const EditCourseToolbar = (props: EditCourseToolbarProps) => {

    // ===== isDialogOpen =====
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    // ===== numSummedEdits =====
    const [numSummedEdits, setNumSummedEdits] = useState<number>(0)

    // ===== memoizedSaveEditsDialog =====
    const memoizedSaveEditsDialog = useMemo(() =>
        <SaveEditsDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}
            setNumSummedEdits={setNumSummedEdits} course={props.course} refreshCourse={props.refreshCourse}
            courseTitleEditsToToolbar={props.courseTitleEditsToToolbar} courseDescriptionEditsToToolbar={props.courseDescriptionEditsToToolbar}
            courseSectionsEdits={props.courseSectionsEdits} courseSectionModulesEdits={props.courseSectionModulesEdits}
            courseSectionsMap={props.courseSectionsMap} courseSectionModulesMap={props.courseSectionModulesMap}
            courseSectionsDeletes={props.courseSectionsDeletes} courseSectionModulesDeletes={props.courseSectionModulesDeletes}
        />
    , [isDialogOpen, setIsDialogOpen, setNumSummedEdits, props])

    return (
        <ToolbarPaper>

            <Typography style={{marginRight: '15px'}} variant='h6'>{(props.course && props.course.courseTitle) ?? ''}</Typography>
            <div style={{flexGrow: 1}} />
            <div style={{marginLeft: '5px', marginRight: '2.5px'}}>
                {numSummedEdits ?
                <Tooltip title='Reset Edits'>
                    <div>
                        <IconButton style={{textTransform: 'none'}} onClick={() => props.refreshCourse()}>
                            <ReplayIcon />
                        </IconButton>
                    </div>
                </Tooltip> :
                <Tooltip title='No edits to reset'>
                    <div>
                        <IconButton disabled style={{textTransform: 'none'}}>
                            <ReplayIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                }
            </div>
            <div style={{marginLeft: '2.5px', marginRight: '5px'}}>
                {numSummedEdits ?
                <Button style={{textTransform: 'none'}} onClick={() => setIsDialogOpen(true)}>
                    {'Save Edits (' + numSummedEdits + ')'}
                </Button> :
                <Tooltip title='No edits to save'>
                    <div>
                        <Button disabled style={{textTransform: 'none'}} onClick={() => setIsDialogOpen(true)}>
                            Save Edits
                        </Button>
                    </div>
                </Tooltip>
                }
            </div>

            {/* Save Edits Dialog */}
            {memoizedSaveEditsDialog}

        </ToolbarPaper>
    )
}

export default EditCourseToolbar