/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Grow,
    styled,
    Typography,
} from '@mui/material'
import {
    DoubleArrow as DoubleArrowIcon,
} from '@mui/icons-material'
import StudentCoursesService from '../services/StudentCoursesService'

// interfaces
import { IStudentCourse } from '../models/StudentCourse'
interface IStudentCourseExtended extends IStudentCourse {
    currentSectionNum: number
    courseTotalSectionsNum: number
    courseTitle: string
}

// mui
const CourseCard = styled(Card)(({theme}) => ({
    width: '100%',
    color: theme.palette.primary.main,
    display: 'flex',
}))

interface IDashCourseCardProps {
    studentCourse: IStudentCourseExtended
}
const DashCourseCard = (props: IDashCourseCardProps) => {

    // ===== navigate =====
    const navigate = useNavigate()

    // ===== isHoveringContinue =====
    const [isHoveringContinue, setIsHoveringContinue] = useState(false)

    const getCompletionPercentage = useCallback((): number => {
        if (props.studentCourse === undefined) {
            return 0
        }
        // if on last section
        if (props.studentCourse.currentSectionNum === props.studentCourse.courseTotalSectionsNum-1) {
            // if video position greater than 0, return 100%
            const sections = props.studentCourse?.studentCourseSections
            const lastSectionModules = sections?.[sections.length-1]?.studentCourseSectionModules
            const lastModuleVideoPosition = lastSectionModules?.[lastSectionModules.length-1]?.studentCourseSectionModuleComponents?.[0]?.studentCourseSectionModuleComponentEmbed?.videoPosition
            if (lastModuleVideoPosition) {
                return 100
            } else {
                return Math.min(Math.round(((props.studentCourse.currentSectionNum)/props.studentCourse.courseTotalSectionsNum)*100), 100)
            }
        } else {
            return Math.min(Math.round(((props.studentCourse.currentSectionNum)/props.studentCourse.courseTotalSectionsNum)*100), 100)
        }
    }, [props.studentCourse])

    // ===== completionPercentage =====
    const [completionPercentage, setCompletionPercentage] = useState<string>('0')
    useEffect(() => {
        if (props.studentCourse === undefined) {
            return
        }
        const completeNum = getCompletionPercentage()
        let completeStr: string = completeNum.toString()
        // if last character === '.', slice that too
        if (completeStr[completeStr.length-1] === '.') {
            completeStr = completeStr.slice(0, 1)
        }
        setCompletionPercentage(completeStr)
    }, [props.studentCourse, getCompletionPercentage])

    return (
        <CourseCard>
            <CardActionArea onClick={() => navigate(String(props.studentCourse.courseId))} style={{flexGrow: 1}}>
                <CardContent>
                    <Typography variant='h6' style={{marginBottom: '10px'}}>{props.studentCourse.courseTitle}</Typography>
                    {/* <Typography style={{marginBottom: '10px'}}>{'Continue with section ' + studentCourse.currentSectionNum + ' >>'}</Typography> */}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <CircularProgress variant='determinate'
                            value={getCompletionPercentage()}
                        />
                        <Typography style={{marginLeft: '10px'}}>
                            {completionPercentage + '% Complete'}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActionArea onClick={() => navigate(String(props.studentCourse.courseId) + '/' + props.studentCourse.currentSectionNum + '/0' )} style={{width: '40%'}}
                onMouseEnter={() => setIsHoveringContinue(true)} onMouseLeave={() => setIsHoveringContinue(false)}
            >
                <CardContent style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography style={{marginRight: '5px'}}>
                        {props.studentCourse.currentSectionNum ?
                        ('Continue with section ' + props.studentCourse.currentSectionNum) :
                        ('Start')
                        }
                        </Typography>
                    <Grow in={isHoveringContinue}><DoubleArrowIcon /></Grow>
                </CardContent>
            </CardActionArea>
        </CourseCard>
    )
}

export default DashCourseCard