/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import {
    Box,
    Card,
    CardActions,
    CardContent,
    styled,
    Typography,
} from '@mui/material'
import {
    Delete as DeleteIcon,
    Description as DescriptionIcon,
    Link as LinkIcon,
    SwapVert as SwapVertIcon,
    Title as TitleIcon,
} from '@mui/icons-material'

// interfaces
import { EditType, ISummedEdit } from './shared'

// mui
const AddedTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold',
}))
const ColorBoldTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}))
const ColorTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.primary.main,
}))
const DeletedTyp = styled(Typography)(({theme}) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold',
}))
const EditCardBox = styled(Box)(({theme}) => ({
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box'
}))
const EditCardBoxItems = styled(Box)(({theme}) => ({
    marginLeft: '5px'
}))
const IconHolder = styled(Box)(({theme}) => ({
    marginRight: '10px',
    color: theme.palette.primary.main
}))
const Sentence = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center'
}))
const SubEditCard = styled(Card)(({theme}) => ({
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: '#F5F5F5',
    width: '100%'
}))

interface ISummedSubEditCardProps {
    summedSubEdit: ISummedEdit
}
const SummedSubEditCard = (props: ISummedSubEditCardProps) => {
    return (
        <SubEditCard>

            {/* CourseSectionModule */}
            {props.summedSubEdit.editType === EditType.CourseSectionModule &&
            <EditCardBox>
                <ColorBoldTyp>{props.summedSubEdit.oldValue}</ColorBoldTyp>
                {props.summedSubEdit.subEdits && props.summedSubEdit.subEdits.map((subSubEdit, subSubEditIndex) =>
                <EditCardBoxItems key={subSubEditIndex}>
                    {/* CourseSectionModuleTitle */}
                    {subSubEdit.editType === EditType.CourseSectionModuleTitle &&
                    <Sentence>
                        <IconHolder><TitleIcon/></IconHolder>
                        <div>
                            <Typography>Changed title to:</Typography>
                            <ColorTyp style={{marginLeft: '15px'}}>{subSubEdit.newValue}</ColorTyp>
                        </div>
                    </Sentence>
                    }

                    {/* CourseSectionModuleDescription */}
                    {subSubEdit.editType === EditType.CourseSectionModuleDescription &&
                    <Sentence>
                        <IconHolder><DescriptionIcon /></IconHolder>
                        {!subSubEdit.newValue || subSubEdit.newValue === '' ?
                        <Typography>No description</Typography> :
                        <div>
                            <Typography>Changed description to:</Typography>
                            <ColorTyp style={{marginLeft: '15px'}}>{subSubEdit.newValue}</ColorTyp>
                        </div>
                        }
                    </Sentence>
                    }

                    {/* CourseSectionModulePosition */}
                    {subSubEdit.editType === EditType.CourseSectionModulePosition &&
                    <Sentence>
                        <IconHolder><SwapVertIcon /></IconHolder>
                        <Typography>Moved to position</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{subSubEdit.newValue}</ColorTyp>
                    </Sentence>
                    }

                    {/* CourseSectionModuleEmbedUrl */}
                    {subSubEdit.editType === EditType.CourseSectionModuleEmbedUrl &&
                    <Sentence>
                        <IconHolder><LinkIcon /></IconHolder>
                        <div>
                            <Typography>Changed embed url to:</Typography>
                            <ColorTyp style={{marginLeft: '5px'}}>{subSubEdit.newValue}</ColorTyp>
                        </div>
                    </Sentence>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

            {/* CourseSectionModuleDelete */}
            {props.summedSubEdit.editType === EditType.CourseSectionModuleDelete &&
            <EditCardBox>
                <Sentence>
                    <DeletedTyp>Deleted</DeletedTyp>
                    <ColorBoldTyp style={{marginLeft: '5px'}}>{props.summedSubEdit.oldValue}</ColorBoldTyp>
                </Sentence>
            </EditCardBox>
            }

            {/* CourseSectionModuleAdd */}
            {props.summedSubEdit.editType === EditType.CourseSectionModuleAdd &&
            <EditCardBox>
                <Sentence>
                    <AddedTyp>Added</AddedTyp>
                    <ColorBoldTyp style={{marginLeft: '5px'}}>{props.summedSubEdit.newValue}</ColorBoldTyp>
                </Sentence>
                {props.summedSubEdit.subEdits && props.summedSubEdit.subEdits.map((subSubEdit, subSubEditIndex) =>
                <EditCardBoxItems key={subSubEditIndex}>
                    {/* CourseSectionModuleDescription */}
                    {subSubEdit.editType === EditType.CourseSectionModuleDescription &&
                    <Sentence>
                        <IconHolder><DescriptionIcon /></IconHolder>
                        {!subSubEdit.newValue || subSubEdit.newValue === '' ?
                        <Typography>No description</Typography> :
                        <div>
                            <Typography>With description:</Typography>
                            <ColorTyp style={{marginLeft: '5px'}}>{subSubEdit.newValue}</ColorTyp>
                        </div>
                        }
                    </Sentence>
                    }

                    {/* CourseSectionModulePosition */}
                    {subSubEdit.editType === EditType.CourseSectionModulePosition &&
                    <Sentence>
                        <IconHolder><SwapVertIcon /></IconHolder>
                        <Typography>At position</Typography>
                        <ColorTyp style={{marginLeft: '5px'}}>{subSubEdit.newValue}</ColorTyp>
                    </Sentence>
                    }

                    {/* CourseSectionModuleEmbedUrl */}
                    {subSubEdit.editType === EditType.CourseSectionModuleEmbedUrl &&
                    <Sentence>
                        <IconHolder><LinkIcon /></IconHolder>
                        <div>
                            <Typography>With embed url:</Typography>
                            <ColorTyp style={{marginLeft: '5px'}}>{subSubEdit.newValue}</ColorTyp>
                        </div>
                    </Sentence>
                    }
                </EditCardBoxItems>
                )}
            </EditCardBox>
            }

        </SubEditCard>
    )
}

export default SummedSubEditCard